import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { goodOnlineApiSlice } from '../api/apiSlice'
import { QueryProps } from '../../types/types'
import { FetchBankListResponse, ResolveBankAccountResponse } from './misc.types'

export const bankApiSlice = goodOnlineApiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    fetchBanks: builder.query<FetchBankListResponse, void>({
      query: () => ({
        url: `misc/banks`,
        method: 'GET',
        params: {},
      }),
    }),
    resolveBankDetails: builder.query<
      ResolveBankAccountResponse,
      {
        accountNumber?: string
        bankCode?: string
      }
    >({
      query: (params) => ({
        url: `misc/banks/resolve`,
        method: 'GET',
        params: params,
      }),
    }),
  }),
})

export const { useFetchBanksQuery, useResolveBankDetailsQuery } = bankApiSlice
