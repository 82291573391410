import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../../layout'
import { TabNav } from '../../../components/nav/tabNav'
import CreateList from './CreateList'
import { ManageListProducts } from './ManageListProducts'

const pageTabs = [
  {
    name: 'Modify list details',
    id: 'List details',
  },
  {
    name: 'Products in this list',
    id: 'List products',
  },
]

export const ConfigureLists = () => {
  const [activeTab, setActiveTab] = useState(pageTabs[0].id)

  const { id } = useParams<{ id: string }>()

  return (
    <Layout>
      {id && (
        <TabNav
          tabs={pageTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className="mb-10"
        />
      )}

      {activeTab === 'List details' && <CreateList />}
      {activeTab === 'List products' && <ManageListProducts />}
    </Layout>
  )
}
