import React, { useState } from 'react'
import Layout from '../../layout'
import { TabNav } from '../../components/nav/tabNav'
import ProductsPage from './ProductsPage'
import Categories from './Categories'
import Tags from './Tags'
import Variants from './Variants'

const pageTabs = [
  { name: 'Products', id: 'Products' },
  { name: 'Categories', id: 'Categories' },
  { name: 'Tags', id: 'Tags' },
  { name: 'Variants', id: 'Variants' },
]
export const Products = () => {
  const queryParams = new URLSearchParams(window.location.search)

  const [activeTab, setActiveTab] = useState<string>(
    queryParams.get('tab') ?? pageTabs[0].id,
  )

  return (
    <Layout>
      <TabNav
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab)
          const params = new URLSearchParams()
          params.set('tab', tab)

          // Update the URL without reloading the page
          window.history.pushState(null, '', '?' + params.toString())
        }}
        tabs={pageTabs}
        className="mb-10"
      />

      {activeTab === 'Products' && <ProductsPage />}
      {activeTab === 'Categories' && <Categories />}
      {activeTab === 'Tags' && <Tags />}
      {activeTab === 'Variants' && <Variants />}
    </Layout>
  )
}
