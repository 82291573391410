import React from 'react'

export const DetailsCard = ({
  details = [],
  className = '',
}: {
  details: { title: string; value: string | number }[]
  className?: string
}) => {
  return (
    <div
      className={`
      flex flex-col
      w-full max-w-[600px]
      ${className}
    `}
    >
      {details.map((detail, idx) => {
        const key = `${detail.title}${idx}`
        return (
          <div
            className="
                border-b last:border-b-0
                pt-4 pb-4 first:pt-0 last:pb-0
                flex items-center gap-4
                justify-between
                border-divider-gray
                "
            key={key}
          >
            <span className="text-sec-black">{detail.title}:</span>
            <span className="text-black font-medium">{detail.value}</span>
          </div>
        )
      })}
    </div>
  )
}
