import React, { useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { useUploadImageMutation } from '../../../redux-toolkits/misc/misc.slice'
import { useGetHarmonizedOrdersBreakdownQuery } from '../../../redux-toolkits/orders/orders.slice'
import { SingleHarmonizedOrder } from '../../../redux-toolkits/orders/orders.type'
import OrderTable from '../../../features/orders/OrderTable'
import Spinner from '../../spinner/Spinner'

export const HarmonizedOrdersModal = ({
  order,
  open,
  setOpen,
  onCloseCallback,
}: {
  order?: SingleHarmonizedOrder
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
}) => {
  const { data, isFetching } = useGetHarmonizedOrdersBreakdownQuery({
    harmonizedReference: order?.harmonizedReference ?? '',
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={onCloseCallback}
      title=""
      childrenClassName="!w-full !max-w-[90%]"
    >
      {isFetching ? (
        <Spinner fullScreen={false} className="h-28" />
      ) : (
        <OrderTable
          orders={
            data?.data?.orders?.map((order) => {
              return {
                order: order,
                orderItems: order?.orderItems ?? [],
              }
            }) ?? []
          }
        />
      )}
    </ModalComponent>
  )
}
