import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ImageBanner } from '../../../components/images/imageBanner'
import {
  useFetchListCategoriesQuery,
  useCreateListMutation,
  useEditListMutation,
  useFetchListMutation,
} from '../../../redux-toolkits/lists/list.slice'
import { ImageUploadPreviewModal } from '../../../components/modal/app_modals/ImagePreviewModal'
import { SingleListDoc } from '../../../redux-toolkits/lists/list.types'
import Layout from '../../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../../../components/typography/PageHeader'
import { Button } from '../../../components/button'
import { ImageUploadButton } from '../../../components/button/imageUploadButton'
import { TextInput } from '../../../components/inputs/textInput'
import Dropdown from '../../../components/inputs/dropdown'
import Icon from '../../../components/icon'
import { HeaderSubheader } from '../../../components/typography/HeaderSubheader'
import Spinner from '../../../components/spinner/Spinner'
import { useModalControl } from '../../../hooks/useModalControl'
import { SelectCategoriesModal } from '../../../components/modal/app_modals/selectCategoriesModal'

const CreateList = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { isLoading, data: categories } = useFetchListCategoriesQuery({
    skip: 0,
    limit: 100,
  })
  const [fetchList, { isLoading: fetchingList, data }] = useFetchListMutation()

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  const [uploadedImage, setUploadedImage] = useState<File>()
  const [uploadedIcon, setUploadedIcon] = useState<File>()
  const [list, setList] = useState<SingleListDoc | null>()
  const [createList, { isLoading: creatingList }] = useCreateListMutation()
  const [editList, { isLoading: updatingList }] = useEditListMutation()
  const imageRef = useRef<HTMLInputElement | null>(null)
  const iconRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (id) {
      fetchList(id)
    }
  }, [id])

  useEffect(() => {
    if (data?.data) {
      setList(data.data)
    }
  }, [data?.data])

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    bannerOrIcon = 'banner',
  ) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    bannerOrIcon === 'banner'
      ? setUploadedImage(selectedFile)
      : setUploadedIcon(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const onCreate = () => {
    navigate('/lists/curated')
  }

  const formik = useFormik({
    initialValues: {
      title: list?.title ?? '',
      summary: list?.summary ?? '',
      category: list?.category ?? [],
      banner: list?.banner ?? '',
      icon: list?.icon ?? '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Required'),
      summary: Yup.string().required('Required'),
      category: Yup.array()
        .of(Yup.object().required('Required'))
        .min(1, 'Required'),
      banner: Yup.string().required('Required'),
      icon: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      if (id) {
        await editList({
          body: { ...values, category: values.category.map((cat) => cat._id) },
          id,
        })?.then((resp) => {
          if (resp.data?.success) {
            onCreate()
          }
        })
      } else {
        await createList({
          ...values,
          category: values.category.map((cat) => cat._id),
        })?.then((resp) => {
          if (resp.data?.success) {
            onCreate()
          }
        })
      }
    },
    enableReinitialize: true,
  })

  return (
    <div>
      {id && fetchingList ? (
        <Spinner fullScreen={false} />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="flex items-center justify-between gap-4">
            <PageHeader
              title={`${id ? 'Edit' : 'Create New'} List`}
              className="!mb-0"
            />

            <div className="flex items-center gap-4 justify-end">
              <Button
                type="button"
                onClick={onCreate}
                label="Cancel"
                size="medium"
                className="!px-10"
              />

              <Button
                type="submit"
                label="Save"
                primary
                disabled={creatingList || updatingList || !formik.isValid}
                loading={creatingList || updatingList}
                size="medium"
                className="!px-10"
              />
            </div>
          </div>

          <div className="pb-10 mt-10 border-b border-divider-gray">
            <HeaderSubheader
              title="List Icon"
              text="Upload list icon."
              className="mb-6"
            />

            <div className="flex items-center gap-6">
              <div className="relative max-w-fit ">
                <ImageBanner
                  imageUrl={formik.values.icon}
                  loading={false}
                  className="
                  !rounded-full
                  !w-[150px] !max-h-[150px]
                  "
                  errors={formik.errors.icon ? formik.errors.icon : ''}
                />
                <ImageUploadButton
                  handleChange={(e) => {
                    handleFileChange(e, 'icon')
                  }}
                  showText={false}
                  className="
                  !left-0 !right-0 !top-[calc(50%-20px)]
                  ml-auto mr-auto !max-w-fit
                  !px-2
                  "
                />
              </div>
            </div>
          </div>

          <div className="pb-10 mt-10 border-b border-divider-gray">
            <HeaderSubheader
              title="List Information"
              text="Configure list title, summary, and category."
              className="mb-6"
            />

            <div className="flex-1 grid grid-cols-1 gap-6">
              <TextInput
                type="text"
                name="title"
                placeholder="List title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errors={
                  formik.errors.title && formik.touched.title
                    ? formik.errors.title
                    : ''
                }
                hasIcon={false}
                className="!border-divider-gray"
              />

              <TextInput
                type="textarea"
                name="summary"
                placeholder="List summary"
                value={formik.values.summary}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errors={
                  formik.errors.summary && formik.touched.summary
                    ? formik.errors.summary
                    : ''
                }
                hasIcon={false}
                className="!w-full !border-divider-gray"
              />

              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center gap-4 justify-between">
                  <p>
                    <span className="font-bold">Categories: </span>
                    <span className="text-danger">
                      {formik.errors.category
                        ? `(${formik.errors.category})`
                        : ''}
                    </span>
                  </p>

                  <Button
                    primary
                    icon="plus"
                    size="medium"
                    label="Manage categories"
                    onClick={() => {
                      handleModalOpen('selectCategoriesModal')
                    }}
                    className="!max-w-fit"
                  />
                </div>

                <div className="flex flex-row gap-4 items-center flex-wrap">
                  {formik.values.category.map((category, idx) => {
                    const key = `tag-${idx}`
                    return (
                      <div
                        key={key}
                        className="
                                              text-sm rounded-md
                                              py-2 px-4
                                              bg-gray-bg
                                              "
                      >
                        {category.name}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <HeaderSubheader
              title="List Banner"
              text="Upload a banner image for the list."
              className="mb-6"
            />

            <div className="relative ">
              <ImageBanner
                imageUrl={formik.values.banner}
                loading={false}
                errors={formik.errors.banner ? formik.errors.banner : ''}
              />
              <ImageUploadButton
                handleChange={(e) => {
                  handleFileChange(e, 'banner')
                }}
              />
            </div>
          </div>

          {uploadedImage || uploadedIcon ? (
            <ImageUploadPreviewModal
              image={uploadedImage || (uploadedIcon as File)}
              open={modal === 'imageUploadPreviewModal' && modalOpen}
              setOpen={setModalOpen}
              onCloseCallback={() => {
                uploadedImage
                  ? setUploadedImage(undefined)
                  : setUploadedIcon(undefined)
              }}
              onSuccessCallback={(url) => {
                formik.setFieldValue(uploadedImage ? 'banner' : 'icon', url)
              }}
            />
          ) : null}

          <SelectCategoriesModal
            open={modalOpen && modal === 'selectCategoriesModal'}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              handleModalClose()
            }}
            onSuccessCallback={(categories) => {
              formik.setFieldValue('category', categories)
              handleModalClose()
            }}
            selectedCategories={formik.values.category}
          />
        </form>
      )}
    </div>
  )
}

export default CreateList
