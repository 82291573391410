import React, { useEffect, useState } from 'react'
import { formatTime, parseTimeToDate } from '../../helpers'
import {
  AvailableHours,
  BusinessAvailableHours,
  DaysOfTheWeek,
} from '../../redux-toolkits/vendors/vendors.types'
import Icon from '../icon'
import CustSwitch from '../switch'
import Dropdown from '../inputs/dropdown'
import { TextInput } from '../inputs/textInput'
import { Button } from '../button'
import { SettlementAccount, Vendor } from '../../types/types'
import { SearchInput } from '../inputs/searchInput'
import {
  useFetchBanksQuery,
  useResolveBankDetailsQuery,
} from '../../redux-toolkits/misc/bank.slice'

export const SettlementAccountDetails = ({
  vendor,
  className,
  loading = false,
  onModify = () => {},
}: {
  vendor?: Vendor
  className?: string
  loading?: boolean
  onModify?: (data: SettlementAccount) => void
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const [payload, setPayload] = useState<SettlementAccount | undefined>(
    vendor
      ? {
          accountNumber: vendor?.settlementAccount?.accountNumber ?? '',
          accountName: vendor?.settlementAccount?.accountName ?? '',
          bankName: vendor?.settlementAccount?.bankName ?? '',
          bankCode: vendor?.settlementAccount?.bankCode ?? '',
        }
      : undefined,
  )

  const { data: banks, isFetching: fetchingBanks } =
    useFetchBanksQuery(undefined)

  const {
    data: accountDetails,
    isFetching: resolvingAccount,
    refetch: resolveAccount,
    isError: resolveAccountError,
  } = useResolveBankDetailsQuery(
    {
      accountNumber: payload?.accountNumber,
      bankCode: payload?.bankCode,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const resetPayload = () => {
    setPayload({
      accountNumber: vendor?.settlementAccount?.accountNumber ?? '',
      accountName: vendor?.settlementAccount?.accountName ?? '',
      bankName: vendor?.settlementAccount?.bankName ?? '',
      bankCode: vendor?.settlementAccount?.bankCode ?? '',
    })
  }

  useEffect(() => {
    if (accountDetails?.data && payload) {
      setPayload({
        ...payload,
        accountName: accountDetails.data.account_name,
      })
    }
  }, [accountDetails])

  return payload ? (
    <div className={`${className} flex flex-col gap-4`}>
      <div className="flex items-center justify-between">
        <span className="font-medium text-base">Settlement Account</span>
        <button
          type="button"
          onClick={() => {
            if (isEdit) {
              setIsEdit(false)
              resetPayload()
            } else {
              setIsEdit(true)
            }
          }}
          className="flex items-center gap-2"
        >
          {isEdit ? (
            'Cancel'
          ) : (
            <>
              <span>Edit</span> <Icon id="edit" width={20} height={20} />
            </>
          )}
        </button>
      </div>

      <div className="text-sec-black flex gap-2 items-center">
        <p>{payload.accountNumber}</p>
        <p>•</p>
        <p>{payload.bankName}</p>
        <p>•</p>
        <p>{payload.accountName}</p>
      </div>

      {isEdit && (
        <div className="flex flex-col gap-4 max-w-[800px]">
          <SearchInput
            hasIcon={false}
            useDefaultTextAppearance
            placeholder="Account Number"
            searchFunction={(search) => {
              setPayload({
                ...payload,
                accountNumber: search,
              })
            }}
            value={payload.accountNumber}
          />

          <div className="w-full flex flex-col gap-2">
            <Dropdown
              loading={fetchingBanks}
              menuClassName="!max-h-[300px]"
              widthClass="!w-full"
              menuButton={
                <TextInput
                  hasIcon={false}
                  value={payload.bankName}
                  readOnly
                  placeholder="Choose Bank"
                  className="!min-w-full"
                />
              }
              menuItems={banks?.data.map((bank) => {
                return {
                  name: bank.name,
                  value: bank.code,
                }
              })}
              onClickMenuItem={(selected) => {
                setPayload({
                  ...payload,
                  bankName: selected.name as string,
                  bankCode: selected.value as string,
                })
              }}
              className="w-full"
            />

            <p
              className={`${
                resolveAccountError ? 'text-danger' : 'text-sec-black'
              }`}
            >
              {resolvingAccount && 'Resolving bank details...'}
              {!resolvingAccount && resolveAccountError
                ? 'Could not resolve bank details...'
                : undefined}
              {!resolvingAccount && !resolveAccountError
                ? payload?.accountName
                : undefined}
            </p>
          </div>
        </div>
      )}

      {isEdit && (
        <Button
          type="button"
          primary
          loading={loading || resolvingAccount}
          disabled={loading || resolvingAccount || resolveAccountError}
          label="Save"
          className="!w-full !max-w-[160px]"
          onClick={async () => {
            onModify(payload)
          }}
        />
      )}
    </div>
  ) : undefined
}
