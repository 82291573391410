import React, { useState } from 'react'
import Layout from '../../layout'
import Pagination from '../../components/pagination'
import TransactionsTable from './TransactionsTable'
import Spinner from '../../components/spinner/Spinner'
import { useGetAllTransactionsQuery } from '../../redux-toolkits/transactions/transactions.slice'
import { PageHeader } from '../../components/typography/PageHeader'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { QueryProps } from '../../types/types'
import { SearchInput } from '../../components/inputs/searchInput'

const TransactionsPage: React.FC = () => {
  const [query, setQuery] = useState<QueryProps>({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: undefined,
  })

  const { data: transactions, isFetching: loadingTransactions } =
    useGetAllTransactionsQuery({
      page: query.page,
      limit: query.limit,
      search: query.search !== '' ? query.search : undefined,
    })

  const handlePageChange = (page: number): void => {
    setQuery({ ...query, page: page })
  }

  return (
    <Layout>
      <PageHeader title="Transactions" />

      <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setQuery({
                ...query,
                search: search === '' ? undefined : search,
                skip: 0,
              })
            }}
            placeholder="Search reference"
          />
        </div>
      </div>

      <div>
        <TransactionsTable
          transactionList={transactions?.data?.transactions || []}
          currentPage={query.page as number}
          totalDataCount={transactions?.data.totalCount ?? 0}
          pageLimit={query.limit as number}
          onPageChange={handlePageChange}
          loading={loadingTransactions}
        />
      </div>
    </Layout>
  )
}

export default TransactionsPage
