import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetSubVariantsQuery,
  useLazyGetSubVariantsQuery,
} from '../../redux-toolkits/products/products.slice'
import { Search } from 'lucide-react'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import Icon from '../../components/icon'
import { SearchInput } from '../../components/inputs/searchInput'
import { Button } from '../../components/button'
import { TableComponent } from '../../components/table'
import Dropdown from '../../components/inputs/dropdown'

export const SingleVariantsPage = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 20,
    vendorId: undefined,
    search: undefined,
  })

  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const [getSubVariants, { data, isFetching: fetchingSubVariants }] =
    useLazyGetSubVariantsQuery()

  useEffect(() => {
    getSubVariants({
      ...query,
      variantId: id as string,
    })
  }, [])

  return (
    <Layout>
      <PageHeader
        title={
          id ? (
            <>
              <button
                type="button"
                onClick={() => {
                  navigate(`/products?tab=Variants`)
                }}
                className="soft-shrink"
              >
                <Icon id={'left-caret'} width={16} height={16} />
              </button>
              Back
            </>
          ) : (
            ''
          )
        }
        className="flex items-center gap-4"
      />

      <div
        className="
        w-full gap-4 flex flex-wrap 
        items-center justify-between
        mb-10
        "
      >
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFieldName="search"
            searchFunction={getSubVariants}
            placeholder="Search sub-variants"
            setSearchValue={() => {}}
            otherFilterparams={query}
          />
        </div>

        <Button
          icon="plus"
          size="medium"
          label="Add new sub-variant"
          primary
          onClick={() => {
            navigate(`/products/variants/${id}/subvariant/new`)
          }}
        />
      </div>

      <TableComponent
        headers={['Name', 'Vendor', 'Number of products', ' ']}
        rows={
          data?.data.subvariants.length
            ? data.data.subvariants.map((singleSubVariant) => {
                return {
                  id: singleSubVariant._id,
                  content: [
                    <div key="">{singleSubVariant.value}</div>,
                    <div key="">{singleSubVariant.vendor}</div>,
                    <div key="">{singleSubVariant.products.length ?? 0}</div>,
                    <Dropdown
                      key={`${singleSubVariant._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={() => {
                                navigate(
                                  `/products/variants/${id}/subvariant/edit/${singleSubVariant._id}`,
                                )
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: '',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="variants-table"
        loading={fetchingSubVariants}
        isEmpty={[undefined, 0].includes(data?.data?.subvariants.length)}
        currentPage={query.page}
        totalDataCount={data?.data.total ?? 0}
        pageLimit={query.limit}
        onPageChange={(number) => {
          setQuery({ ...query, page: number })
        }}
      />
    </Layout>
  )
}
