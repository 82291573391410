import React, { useState } from 'react'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useModalControl } from '../../hooks/useModalControl'
import { AddCategoryModal } from '../../components/modal/app_modals/addCategoryModal'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { SearchInput } from '../../components/inputs/searchInput'
import { AddSubCategoryModal } from '../../components/modal/app_modals/addSubCategoryModal'
import { SingleSubCategory } from '../../redux-toolkits/products/products.type'
import { useGetSubCategoriesQuery } from '../../redux-toolkits/products/products.slice'
import { Status } from '../../components/cards/statusTag'
import AddProductCategoryModal from '../../components/modal/app_modals/addCategoryToProduct'

const SubCategories: React.FC = () => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })
  const [search, setSearch] = useState<string>()

  const [selectedCategory, setSelectedCategory] = useState<SingleSubCategory>()
  const [mode, setMode] = useState<'create' | 'edit'>('create')

  const { setModalOpen, modalOpen, handleModalOpen, handleModalClose, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useGetSubCategoriesQuery({
    skip: query.skip,
    limit: query.limit,
    search: search,
  })

  return (
    <div>
      <PageHeader title="Sub-Categories" />

      <div className="w-full gap-4 flex flex-wrap items-center justify-between">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setSearch(search === '' ? undefined : search)
              setQuery({ ...query, skip: 0 })
            }}
            placeholder="Search sub-categories"
          />
        </div>

        <Button
          icon="plus"
          size="medium"
          label="Add new sub-category"
          primary
          onClick={() => {
            setMode('create')
            handleModalOpen('addCategoryModal')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total sub-categories count"
          count={data?.data.totalCount ?? 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={[
          'Name',
          'Parent category',
          'Parent sub-category',
          'Status',
          'Actions',
        ]}
        rows={
          data?.data?.result.length
            ? data.data.result.map((singleSubCategory) => {
                return {
                  id: singleSubCategory._id,
                  content: [
                    <div key="">{singleSubCategory.name}</div>,
                    <span
                      key=""
                      className="line-clamp-1 text-ellipsis max-w-[250px]"
                    >
                      {singleSubCategory?.category?.name ?? ''}
                    </span>,
                    <span
                      key=""
                      className="line-clamp-1 text-ellipsis max-w-[250px]"
                    >
                      {singleSubCategory?.parentSubcategory?.name ?? 'N/A'}
                    </span>,
                    <Status
                      key={`${singleSubCategory._id}-status`}
                      type={singleSubCategory.isActive ? 'success' : 'fail'}
                      text={singleSubCategory.isActive ? 'Active' : 'Inactive'}
                    />,
                    <Dropdown
                      key={`${singleSubCategory._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addCategoryModal')
                                setSelectedCategory(singleSubCategory)
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: 'edit',
                        },
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addProductCategoryModal')
                                setSelectedCategory(singleSubCategory)
                              }}
                            >
                              Add Products
                            </button>
                          ),
                          value: 'add-products',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="categories-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.result.length)}
        currentPage={query.skip / query.limit + 1}
        totalDataCount={data?.data.totalCount ?? 0}
        pageLimit={query.limit}
        onPageChange={(number) => {
          setQuery({ ...query, skip: (number - 1) * query.limit })
        }}
      />

      {modalOpen && modal === 'addCategoryModal' && (
        <AddSubCategoryModal
          open={modalOpen && modal === 'addCategoryModal'}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            handleModalClose()
            setSelectedCategory(undefined)
          }}
          onSuccessCallback={() => {
            setSelectedCategory(undefined)
            refetch()
          }}
          mode={mode}
          subCategory={selectedCategory}
        />
      )}

           {modalOpen && modal === 'addProductCategoryModal' && (
              <AddProductCategoryModal
                open={modalOpen}
                setOpen={setModalOpen}
                subcategoryId={selectedCategory?._id ?? ''}
              />
            )}
    </div>
  )
}

export default SubCategories
