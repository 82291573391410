import React, { useState } from 'react'
import Layout from '../../layout'
import clsx from 'clsx'
import TeamMembers from './TeamMembers'
import RolesSection from './Roles'
import PremissionsSection from './Permissions'
import { Locations } from './Locations'
import { PageHeader } from '../../components/typography/PageHeader'
import { TabNav } from '../../components/nav/tabNav'
import { WalletSettings } from './Wallet'

const pageTabs = [
  { name: 'Team Members', id: 'Team Members' },
  { name: 'Roles', id: 'Roles' },
  { name: 'Permissions', id: 'Permissions' },
  { name: 'Locations', id: 'Locations' },
  { name: 'Wallet', id: 'Wallet' },
]
const Settings: React.FC = () => {
  const [tabs, setTabs] = useState([
    { name: 'Team Members', isActive: true },
    { name: 'Roles', isActive: false },
    { name: 'Permissions', isActive: false },
    { name: 'Locations', isActive: false },
    { name: 'Wallet', isActive: false },
  ])
  const [activeTab, setActiveTab] = useState('Team Members')

  return (
    <Layout>
      <PageHeader title="Settings" />

      <TabNav
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={pageTabs}
        className="mb-10"
      />

      {activeTab === 'Team Members' && <TeamMembers />}
      {activeTab === 'Roles' && <RolesSection />}
      {activeTab === 'Permissions' && <PremissionsSection />}
      {activeTab === 'Locations' && <Locations />}
      {activeTab === 'Wallet' && <WalletSettings />}
    </Layout>
  )
}

export default Settings
