import React, { useState } from 'react'
import { format } from 'date-fns'
import {
  calculateFromPrice,
  formatCurrency,
  getVendorName,
} from '../../helpers'
import { Product } from '../../types/types'
import { TableComponent } from '../../components/table'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { Link, useNavigate } from 'react-router-dom'
import { UpdateItemStockModal } from '../../components/modal/app_modals/updateItemStockmodal'
import { useModalControl } from '../../hooks/useModalControl'

interface Props {
  productList: Product[]
  currentPage?: number
  totalDataCount?: number
  pageLimit?: number
  onPageChange?: (page: number) => void
  loading?: boolean
  refetch: () => void
}

const ProductsTable: React.FC<Props> = ({
  productList,
  currentPage,
  totalDataCount,
  pageLimit,
  onPageChange,
  loading,
  refetch,
}) => {
  const [selected, setSelected] = useState<Product>()
  const [updateMode, setUpdateMode] = useState<'price' | 'quantity'>('quantity')

  const navigate = useNavigate()

  const { modal, modalOpen, setModalOpen, handleModalOpen, handleModalClose } =
    useModalControl()

  return (
    <>
      <TableComponent
        headers={[
          'Name',
          'Price',
          'Stock quantity',
          'Category',
          'Creation Date',
          'Actions',
        ]}
        rows={productList.map((product) => {
          return {
            id: product._id,
            content: [
              <div
                key={`${product._id}-name`}
                className="
                flex min-w-max
                items-center
                gap-2
              "
              >
                <img
                  key={`${product._id}-image`}
                  height={50}
                  width={50}
                  src={product.images[0]}
                  alt="product"
                  className="
                  w-[70px] 
                  h-[70px]
                  object-cover
                  border
                  overflow-hidden rounded-md
                "
                />

                <div className="flex flex-col gap-1">
                  <Link
                    to={`/products/view/${product._id}`}
                    className="
                    text-black font-recoleta-medium
                    hover:underline
                    transition-all duration-500
                    text-base
                    "
                  >
                    {product.name}
                  </Link>

                  <p
                    className="
                    max-w-[350px]
                    text-wrap
                    line-clamp-2
                    text-ellipsis
                  "
                  >
                    {product.description}
                  </p>

                  <p
                    className="
                    bg-bg-gray
                    px-2 py-1
                    rounded-full
                    max-w-fit
                    text-xs
                    flex items-center
                    gap-1
                    "
                  >
                    <Icon id="vendor" height={10} width={10} />
                    {getVendorName(product?.vendor)}
                  </p>
                </div>
              </div>,
              <p
                className="
                max-w-[100px] text-wrap break-words
                "
                key={`${product._id}-price`}
              >
                {calculateFromPrice(product)}
              </p>,
              product.inStockQuantity.toLocaleString(),
              <p
                className="
                max-w-[100px] line-clamp-2 text-ellipsis text-wrap
                "
                key={`${product._id}-categories`}
              >
                {(product?.category ?? [])
                  ?.map((cat) => cat?.name)
                  .join(', ') ?? ''}
              </p>,
              format(new Date(product.createdAt), 'dd/MM/yyyy h:mma'),
              <Dropdown
                key={`${product._id}-controls`}
                menuButton={
                  <Icon id="ellipses" height={18} width={18} className="" />
                }
                onClickMenuItem={() => {}}
                menuItems={[
                  {
                    name: (
                      <button
                        className="disabled:opacity-30 w-full text-left"
                        onClick={(): any => {
                          navigate(`/products/view/${product._id}`)
                        }}
                      >
                        Edit
                      </button>
                    ),
                    value: '',
                  },
                  {
                    name: (
                      <button
                        className="disabled:opacity-30 w-full text-left"
                        onClick={(): any => {
                          setUpdateMode('quantity')
                          setSelected(product)
                          handleModalOpen('updateItemStockModal')
                        }}
                      >
                        Manage stock
                      </button>
                    ),
                    value: '',
                  },
                  {
                    name: (
                      <button
                        className="disabled:opacity-30 w-full text-left"
                        onClick={(): any => {
                          setUpdateMode('price')
                          setSelected(product)
                          handleModalOpen('updateItemStockModal')
                        }}
                      >
                        Manage price
                      </button>
                    ),
                    value: '',
                  },
                ]}
              />,
            ],
          }
        })}
        isEmpty={[undefined, 0].includes(productList.length)}
        emptyText="No products found"
        loading={loading}
        currentPage={currentPage}
        totalDataCount={totalDataCount}
        pageLimit={pageLimit}
        onPageChange={onPageChange}
      />

      {selected && (
        <UpdateItemStockModal
          open={modalOpen && modal === 'updateItemStockModal'}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            handleModalClose()
            setSelected(undefined)
          }}
          onSuccessCallback={() => {
            handleModalClose()
            setSelected(undefined)
            refetch()
          }}
          product={selected}
          type={updateMode}
        />
      )}
    </>
  )
}

export default ProductsTable
