import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import Spinner from '../../components/spinner/Spinner'
import {
  useGetOrdersQuery,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useGetSettlementsQuery,
} from '../../redux-toolkits/orders/orders.slice'

import {
  CURRENT_PAGE_NUMBER,
  NUMBER_OF_ITEMS_PER_PAGE,
  ORDERSTATUS,
  partyMappings,
  SETTLEMENTSTATUS,
} from '../../constants'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { PageHeader } from '../../components/typography/PageHeader'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { updateNewOrderStatus } from '../../redux-toolkits/orders/order.reducer'
import { useAppDispatch } from '../../hooks'
import { SearchInput } from '../../components/inputs/searchInput'
import CountCard from '../orders/CountCard'
import OrderTable from '../orders/OrderTable'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import { StatusTypes } from '../../types/types'
import { formatCurrency, getVendorName } from '../../helpers'
import { format } from 'date-fns'

export const SettlementPage: React.FC = () => {
  const navigate = useNavigate()

  const [status, setStatus] = useState<string>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const [party, setParty] = useState<string>('all')

  const limit = NUMBER_OF_ITEMS_PER_PAGE

  const {
    data: settlements,
    isFetching,
    isError,
    isSuccess,
  } = useGetSettlementsQuery({
    party: party === 'all' ? undefined : party,
    page: page,
    limit: limit,
  })

  return (
    <Layout>
      {isFetching ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader title={`Settlement`} className="capitalize" />

          <CountCardContainer>
            <CountCard
              count={0}
              text="Good Ledger Balance"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={0}
              text="Total Settlement"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={0}
              text="Total Amount Settled"
              icon=""
              isCurrency={true}
            />
          </CountCardContainer>

          <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
            <div className="!w-full md:!max-w-[400px]">
              <SearchInput
                searchFieldName="search"
                searchFunction={() => {}}
                placeholder="Search"
                setSearchValue={() => {}}
                otherFilterparams={{
                  page: '1',
                  limit: NUMBER_OF_ITEMS_PER_PAGE,
                }}
              />
            </div>

            <Dropdown
              menuClassName=""
              menuButton={
                <Button
                  label={`Filter: ${party}`}
                  primary={false}
                  icon="filter"
                  className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                />
              }
              onClickMenuItem={(selected) => {
                setParty(selected.value as string)
              }}
              menuItems={[
                { name: 'All', value: 'all' },
                { name: 'Vendor', value: 'VENDOR' },
                { name: 'Good Operations', value: 'GOOD' },
                { name: 'Others', value: 'OTHERS' },
              ]}
            />
          </div>

          <TableComponent
            name="Settlements"
            showName={false}
            headers={[
              'Transaction Id',
              'Order Id',
              'Party',
              'Amount Settled',
              'Status',
              'Date',
            ]}
            rows={
              settlements
                ? settlements.data.docs.map((item) => {
                    return {
                      id: item._id,
                      content: [
                        <Link
                          to={`/settlement/${item._id}`}
                          className="
                          hover:text-black hover:underline
                          transition-all duration-500
                          "
                          key={`${item._id}-transaction-id`}
                        >
                          {item._id}
                        </Link>,
                        <Link
                          to={`/orders/${item.order._id}`}
                          className="
                          hover:text-black hover:underline
                          transition-all duration-500
                          "
                          key={`${item.order._id}-product-id`}
                        >
                          #{item.order.orderNumber}
                        </Link>,
                        item.party === 'VENDOR' ? (
                          <div
                            key={`${item._id}-vendor-party`}
                            className="flex gap-2 items-center"
                          >
                            <img
                              src={item.vendor.logo ?? ''}
                              className="object-cover w-[40px] h-[40px] rounded-full overflow-hidden"
                            />
                            <span>{getVendorName(item.vendor)}</span>
                          </div>
                        ) : (
                          (partyMappings[
                            item.party as keyof typeof partyMappings
                          ] as string)
                        ),
                        formatCurrency(item.amount),
                        <Status
                          text={item.status.toLowerCase()}
                          type={
                            (SETTLEMENTSTATUS.find(
                              (status) =>
                                status.name.toLowerCase() ===
                                item.status.toLowerCase(),
                            )?.type ?? 'warn') as StatusTypes
                          }
                          key={`${item._id}-status`}
                        />,
                        `${format(item.createdAt, 'yyyy-MM-dd')}`,
                      ],
                    }
                  })
                : []
            }
            onPageChange={(page) => {
              setPage(page)
            }}
            pageLimit={limit}
            currentPage={page}
            loading={isFetching}
            isEmpty={isError}
            totalDataCount={settlements?.data.total ?? 0}
          />
        </>
      )}
    </Layout>
  )
}
