import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, ReactNode } from 'react'
import React from 'react'
import { LoadingOval } from '../spinner/Spinner'
import { PRIMARY_COLOR } from '../../constants'

const Dropdown = ({
  menuItems,
  menuButton,
  customMenuItems = undefined,
  onClickMenuItem,
  position = 'right',
  widthClass = 'w-56',
  className = '',
  closeOnClick = true,
  menuClassName = '',
  loading = false,
  disabled = false,
  menuItemClassName = '',
}: {
  menuButton: ReactNode | string
  onClickMenuItem?: (item: {
    name: string | ReactNode
    value: string | object | boolean
  }) => void
  menuItems?: (
    | { name: string | ReactNode; value: string | object | boolean }
    | undefined
  )[]
  customMenuItems?: ReactNode
  position?: string
  widthClass?: string
  className?: string
  closeOnClick?: boolean
  menuClassName?: string
  menuItemClassName?: string
  loading?: boolean
  disabled?: boolean
}) => {
  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      <MenuButton
        as={'a'}
        className={
          'cursor-pointer hover:scale-[0.98] transition-all duration-300'
        }
        onClick={(e) => {
          e.stopPropagation()
        }}
        disabled={disabled}
      >
        {menuButton}
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className={clsx(
            `absolute 
              ${widthClass} 
              z-[999] mt-5
              overflow-y-scroll
              overflow-x-hidden bg-white
              origin-top-right 
              rounded-2xl shadow-dropdown-custom
              focus:outline-none
              hide-scroll-bar
              ${menuClassName}
              `,
            {
              'left-0 right-0': position === 'center',
              'right-0': position === 'right',
              'left-0': position === 'left',
            },
          )}
        >
          {loading && (
            <div className="py-8 flex items-center justify-center">
              <LoadingOval
                color={PRIMARY_COLOR}
                loaderHeight="25"
                loaderWidth="25"
              />
            </div>
          )}

          {!loading && customMenuItems !== undefined
            ? customMenuItems
            : undefined}

          {!loading && !customMenuItems
            ? menuItems
                ?.filter((item) => item !== undefined)
                ?.map((item, index) => {
                  const key = `menu-item-${index}`
                  return (
                    <MenuItem key={key}>
                      {({ focus }) => (
                        <button
                          type="button"
                          className={clsx(
                            `w-full text-sec-black text-sm ${menuItemClassName}`,
                            focus && 'bg-primary/5 text-black',
                            'block px-4 py-2 first:pt-4 last:pb-4 text-sm text-left',
                          )}
                          onClick={(e) => {
                            !closeOnClick && e.preventDefault()
                            onClickMenuItem && onClickMenuItem(item)
                          }}
                        >
                          {item.name}
                        </button>
                      )}
                    </MenuItem>
                  )
                })
            : undefined}

          {!loading &&
          !customMenuItems &&
          [0, undefined].includes(menuItems?.length) ? (
            <div className="text-sec-black text-sm py-8 text-center">
              No data found
            </div>
          ) : undefined}
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default Dropdown
