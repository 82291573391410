import React, { ChangeEvent, useRef } from 'react'
import Icon from '../icon'

export const ImageUploadButton: React.FC<{
  showText?: boolean
  className?: string
  handleChange: (val: ChangeEvent<HTMLInputElement>) => void
  iconDim?: number
  text?: string
  icon?: string
}> = ({
  showText = true,
  handleChange,
  className = '',
  iconDim = 20,
  text = 'Click to upload image',
  icon = 'camera',
}) => {
  const imageRef = useRef<HTMLInputElement | null>(null)

  return (
    <button
      type="button"
      onClick={() => {
        imageRef?.current?.click()
      }}
      className={`
            bg-transparent text-neutral
            absolute top-4 right-4
            py-2 px-4 rounded-full
            flex flex-col items-center justify-center gap-2
            text-sm
            ${className}
            `}
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%237A7978FF' stroke-width='4' stroke-dasharray='10%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }}
    >
      <Icon id={icon} width={iconDim} height={iconDim} />
      {showText && <p className="">{text}</p>}
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        className="!hidden"
        ref={imageRef}
      />
    </button>
  )
}
