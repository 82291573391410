import { Switch } from '@headlessui/react'
import React from 'react'

interface SwitchProps {
  checked: boolean
  disabled?: boolean
  onChange?: () => void
}
const CustSwitch: React.FC<SwitchProps> = ({
  checked,
  disabled,
  onChange = () => {},
}): JSX.Element => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="group inline-flex h-4 w-8 items-center rounded-full bg-gray-200 transition data-[checked]:bg-primary"
    >
      <span className="size-3 translate-x-0.5 rounded-full bg-white transition group-data-[checked]:translate-x-[18px]" />
    </Switch>
  )
}

export default CustSwitch
