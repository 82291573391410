import React, { ChangeEvent, useEffect, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateSubCategorySchema } from '../../../validation/validate'
import {
  useAddCategoryToProductsMutation,
  useCreateSubCategoryMutation,
  useEditSubCategoryMutation,
  useFetchProductsBySubcategoryQuery,
  useGetSubCategoriesQuery,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { SingleSubCategory } from '../../../redux-toolkits/products/products.type'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { useFetchListCategoriesQuery } from '../../../redux-toolkits/lists/list.slice'
import { Product, QueryProps } from '../../../types/types'
import { ProductTile } from '../../product/productTile'
import { EmptyState } from '../../emptyState/emptyState'
import { removeElementAtIndex, reorderProducts } from '../../../helpers'
import { HeaderSubheader } from '../../typography/HeaderSubheader'
import { SelectProductModal } from './selectProductModal'
import { useModalControl } from '../../../hooks/useModalControl'
import { PageHeader } from '../../typography/PageHeader'
import { Status } from '../../cards/statusTag'
import { ImageBanner } from '../../images/imageBanner'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { ImageUploadPreviewModal } from './ImagePreviewModal'
import { useDraggable } from '../../../hooks/useDraggable'

const statuses = [
  { text: 'inactive', value: false },
  { text: 'active', value: true },
]

export const AddSubCategoryModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  subCategory?: SingleSubCategory
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  subCategory,
}) => {
  const [productPicks, setProductPicks] = useState<Product[]>([])

  const [subcategoryPicks, setSubcategoryPicks] = useState<Product[]>([])

  const [openStates, setOpenStates] = useState({
    subcategory: false,
    product: false,
  })

  const toggle = (key: 'subcategory' | 'product') => {
    setOpenStates((prev) => ({ ...prev, [key]: !prev[key] }))
  }

  const { handleDragOver, handleDragStart, handleDrop } = useDraggable()

  const [subCatQueryParam, setSubCatQueryParam] = useState<QueryProps>({
    search: undefined,
    skip: 0,
    limit: '100',
  })

  const [uploadedIcon, setUploadedIcon] = useState<File>()

  const { modal, modalOpen, setModalOpen, handleModalOpen, handleModalClose } =
    useModalControl()

  const [createSubCategory, { isLoading: creating }] =
    useCreateSubCategoryMutation()

  const [addCategory] = useAddCategoryToProductsMutation()

  const { data, refetch } = useFetchProductsBySubcategoryQuery(
    { subcategoryId: subCategory?._id ?? '' },
    { skip: !subCategory?._id },
  )

  const fetchedProducts = data?.data?.data ?? []

  const [editSubCategory, { isLoading: editing }] = useEditSubCategoryMutation()

  const { isLoading: loadingCategories, data: categories } =
    useFetchListCategoriesQuery({
      skip: 0,
      limit: 100,
    })

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      icon: subCategory?.icon ?? '',
      name: subCategory?.name ?? '',
      categoryId: subCategory?.category?._id ?? '',
      category: subCategory?.category ?? undefined,
      parentSubcategory: subCategory?.parentSubcategory ?? undefined,
      isActive: subCategory?.isActive ?? false,
    },
    enableReinitialize: true,
    validationSchema: CreateSubCategorySchema,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        icon: values.icon,
        categoryId: values.categoryId,
        isActive: values.isActive,
        parentSubcategory: values.parentSubcategory?._id,
        ourPicks: productPicks.map((product) => product._id),
      }

      if (mode === 'create') {
        createSubCategory(payload).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && subCategory) {
        editSubCategory({ id: subCategory._id, payload: payload }).then(
          async (resp) => {
            if (resp.data?.success) {
              await addCategory({
                categoryId: values.categoryId,
                subcategoryId: subCategory._id,
                productIds: subcategoryPicks.map((product) => product._id),
              })
              refetch()
              resetForm()
              setOpen(false)
              onSuccessCallback()
            }
          },
        )
      }
    },
  })

  useEffect(() => {
    setProductPicks(
      mode == 'create'
        ? []
        : JSON.parse(JSON.stringify(subCategory?.ourPicks ?? [])),
    )
  }, [open])

  useEffect(() => {
    if (mode === 'edit') {
      if (fetchedProducts && Array.isArray(fetchedProducts)) {
        setSubcategoryPicks(fetchedProducts)
      } else {
        setSubcategoryPicks([])
      }
    }
  }, [fetchedProducts, mode, open])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const selectedFile = event.target.files[0]

    setUploadedIcon(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new sub-category' : 'Edit sub-category'}
      fullScreen={mode === 'edit'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        {mode === 'edit' && (
          <div className="mb-10 flex items-center justify-between gap-4">
            <div className="flex flex-col gap-2">
              <PageHeader title={subCategory?.name ?? ''} className="!mb-0" />
              <div className="flex items-center gap-2">
                <Status
                  type={subCategory?.isActive ? 'success' : 'fail'}
                  text={subCategory?.isActive ? 'Active' : 'Inactive'}
                />
                <p className="text-base">•</p>
                <p>
                  <span className="font-medium">{productPicks.length}</span>{' '}
                  featured products
                </p>
              </div>
            </div>

            <Button
              label="Save"
              type="submit"
              className="mt-10 !px-10"
              disabled={!isValid || creating || editing}
              loading={creating || editing}
              primary
            />
          </div>
        )}

        <div className="flex flex-col gap-6">
          {mode === 'edit' && (
            <HeaderSubheader
              title="Details"
              text="Manage sub-category details."
            />
          )}

          <div
            className={`
            grid gap-4
            ${mode === 'create' && 'grid-cols-1'}
            ${mode === 'edit' && 'grid-cols-4'}
            `}
          >
            <div className={`${mode === 'edit' && '!col-span-full'}`}>
              <div className={`relative  max-w-fit`}>
                <ImageBanner
                  imageUrl={values.icon}
                  loading={false}
                  className="
                        !rounded-full
                        !w-[120px] !max-h-[120px]
                        "
                  errors={errors.icon ? errors.icon : ''}
                />
                <ImageUploadButton
                  handleChange={(e) => {
                    handleFileChange(e)
                  }}
                  showText={false}
                  className="
                        !left-0 !right-0 !top-[calc(50%-20px)]
                        ml-auto mr-auto !max-w-fit
                        !px-2
                        "
                />
              </div>
            </div>
            <TextInput
              type="text"
              name="name"
              title="Name"
              placeholder="Sub-category name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.name && touched.name ? errors.name : ''}
              hasIcon={false}
            />
            <Dropdown
              loading={loadingCategories}
              className=""
              menuClassName="max-h-[200px]"
              widthClass="!w-full"
              menuButton={
                <TextInput
                  type="text"
                  title="Category"
                  name="category"
                  placeholder="Product category"
                  value={values?.category?.name ?? ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.categoryId && touched.categoryId
                      ? errors.categoryId
                      : ''
                  }
                  hasIcon={false}
                  readOnly
                  extra={
                    <Icon
                      id={'left-caret'}
                      width={16}
                      height={16}
                      className="-rotate-90 text-sec-black"
                    />
                  }
                />
              }
              onClickMenuItem={(selection) => {
                const selected: { name: string; value: ListCategoryDoc } = {
                  name: selection.name as string,
                  value: selection.value as ListCategoryDoc,
                }

                setFieldValue('category', selected.value)
                setFieldValue('categoryId', selected.value._id)
                setFieldValue('parentSubcategory', undefined)
              }}
              menuItems={categories?.data.data.map((category) => {
                return {
                  name: category.name,
                  value: category,
                }
              })}
            />
            <Dropdown
              loading={loadingCategories}
              className=""
              menuClassName="max-h-[200px]"
              widthClass="!w-full"
              menuButton={
                <TextInput
                  type="text"
                  title="Parent Sub-category"
                  name="parentSubcategory"
                  placeholder="Parent Sub-category"
                  value={values?.parentSubcategory?.name ?? ''}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.parentSubcategory && touched.parentSubcategory
                      ? errors.parentSubcategory
                      : ''
                  }
                  hasIcon={false}
                  readOnly
                  extra={
                    <Icon
                      id={'left-caret'}
                      width={16}
                      height={16}
                      className="-rotate-90 text-sec-black"
                    />
                  }
                />
              }
              onClickMenuItem={(selection) => {
                const selected: { name: string; value: ListCategoryDoc } = {
                  name: selection.name as string,
                  value: selection.value as ListCategoryDoc,
                }

                setFieldValue('parentSubcategory', selected.value)
              }}
              menuItems={
                values?.category?.subcategories?.map((subCategory) => {
                  return {
                    name: subCategory.name,
                    value: subCategory,
                  }
                }) ?? []
              }
            />

            <Dropdown
              widthClass="!min-w-full"
              position="center"
              menuButton={
                <TextInput
                  type="text"
                  title="Status"
                  name="status"
                  placeholder="Select status"
                  value={values.isActive ? 'Active' : 'Inactive'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  hasIcon={false}
                  readOnly
                  extra={
                    <Icon
                      id={'left-caret'}
                      width={16}
                      height={16}
                      className="-rotate-90 text-sec-black"
                    />
                  }
                />
              }
              onClickMenuItem={(selection) => {
                setFieldValue('isActive', selection.value)
              }}
              menuItems={statuses.map((status) => {
                return {
                  name: status.text,
                  value: status.value,
                }
              })}
            />
          </div>
        </div>

        {mode === 'edit' && (
          <div className="mt-10">
            <div className="flex items-center justify-between py-4">
              <div
                className={`${openStates.product ? 'invisible' : 'visible'}`}
              >
                <HeaderSubheader
                  title="Curate Subcategory"
                  text="If you curate subcategory, Only the curated products will show on good online."
                />
              </div>
              <button
                type="button"
                onClick={() => toggle('product')}
                className="p-2"
              >
                <Icon
                  id={'left-caret'}
                  width={18}
                  height={16}
                  className={`transition-all duration-300 ${
                    openStates.product ? 'rotate-90' : '-rotate-90'
                  }`}
                />
              </button>
            </div>

            {openStates.product && (
              <div className="mt-5">
                <div className="flex items-center justify-between gap-4 animate-fadeIn transition-opacity duration-300">
                  <HeaderSubheader
                    title="Curate Subcategory"
                    text="If you curate subcategory, Only the curated products will show on good online."
                  />

                  <Button
                    icon="plus"
                    label="Add products"
                    onClick={() => {
                      handleModalOpen('selectProductModal')
                    }}
                    primary
                    disabled={productPicks.length >= 12}
                  />
                </div>

                <ul className="grid grid-cols-2 gap-10 mt-6">
                  {productPicks.map((product, index) =>
                    product?._id ? (
                      <li
                        key={product._id}
                        draggable
                        onDragStart={handleDragStart(index)}
                        onDrop={handleDrop(
                          index,
                          productPicks,
                          (products, startIndex, index) => {
                            const reorderedList = reorderProducts(
                              products,
                              startIndex,
                              index,
                            )
                            setProductPicks(reorderedList)
                          },
                        )}
                        onDragOver={handleDragOver}
                        className="cursor-pointer"
                      >
                        <ProductTile
                          product={product}
                          showDelete
                          onDelete={() => {
                            const productsCopy = JSON.parse(
                              JSON.stringify(productPicks),
                            )
                            setProductPicks(
                              removeElementAtIndex(productsCopy, index),
                            )
                          }}
                        />
                      </li>
                    ) : undefined,
                  )}
                </ul>

                {mode === 'edit' && productPicks.length === 0 && (
                  <EmptyState text="No selection" />
                )}
              </div>
            )}
          </div>
        )}

        {mode === 'edit' && (
          <div className="mt-10">
            <div className="flex items-center justify-between py-4">
              <div
                className={`${
                  openStates.subcategory ? 'invisible' : 'visible'
                }`}
              >
                <HeaderSubheader
                  title="Subcategory Products"
                  text="View or Add products to subcategory."
                />
              </div>
              <button
                type="button"
                onClick={() => toggle('subcategory')}
                className="p-2"
              >
                <Icon
                  id={'left-caret'}
                  width={18}
                  height={16}
                  className={`transition-all duration-300 ${
                    openStates.subcategory ? 'rotate-90' : '-rotate-90'
                  }`}
                />
              </button>
            </div>

            {openStates.subcategory && (
              <div className="mt-5">
                <div className="flex items-center justify-between gap-4 animate-fadeIn transition-opacity duration-300">
                  <HeaderSubheader
                    title="Subcategory Products"
                    text="View or Add products to subcategory."
                  />

                  <Button
                    icon="plus"
                    label="Add products"
                    onClick={() => {
                      handleModalOpen('selectSubcategoryProductModal')
                    }}
                    primary
                    disabled={subcategoryPicks.length >= 12}
                  />
                </div>

                <ul className="grid grid-cols-2 gap-10 mt-10">
                  {Array.isArray(subcategoryPicks) &&
                    subcategoryPicks.map((product, index) =>
                      product?._id ? (
                        <li
                          key={product._id}
                          draggable
                          onDragStart={handleDragStart(index)}
                          onDrop={handleDrop(
                            index,
                            subcategoryPicks,
                            (products, startIndex, index) => {
                              const reorderedList = reorderProducts(
                                products,
                                startIndex,
                                index,
                              )
                              setSubcategoryPicks(reorderedList)
                            },
                          )}
                          onDragOver={handleDragOver}
                          className="cursor-pointer"
                        >
                          <ProductTile
                            product={product}
                            showDelete
                            onDelete={() => {
                              setSubcategoryPicks(
                                removeElementAtIndex(
                                  [...subcategoryPicks],
                                  index,
                                ),
                              )
                            }}
                          />
                        </li>
                    ) : undefined,
                    )}
                </ul>

                {mode === 'edit' && subcategoryPicks.length === 0 && (
                  <EmptyState text="No selection" />
                )}
              </div>
            )}
          </div>
        )}

        {mode === 'create' && (
          <Button
            label="Proceed"
            type="submit"
            className="mt-10 mb-10"
            disabled={!isValid || creating || editing}
            loading={creating || editing}
            primary
          />
        )}
      </form>

      <SelectProductModal
        open={modal === 'selectProductModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {}}
        onSuccessCallback={(selected) => {
          if (
            !productPicks
              .map((product) => product._id)
              .includes(selected._id) &&
            productPicks.length < 12
          ) {
            setProductPicks([...productPicks, selected])
          }
        }}
        query={{
          subcategoryId: subCategory?.name?.toLowerCase()?.includes('top picks')
            ? undefined
            : subCategory?._id,
          categoryId: subCategory?.name?.toLowerCase()?.includes('top picks')
            ? subCategory.category._id
            : undefined,
        }}
      />

      <SelectProductModal
        open={modal === 'selectSubcategoryProductModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {}}
        onSuccessCallback={(selected) => {
          if (
            !subcategoryPicks.some((product) => product._id === selected._id) &&
            subcategoryPicks.length < 12
          ) {
            setSubcategoryPicks([...subcategoryPicks, selected])
          }
        }}
        query={{
          categoryId: subCategory?.category?._id,
        }}
      />

      {uploadedIcon ? (
        <ImageUploadPreviewModal
          image={uploadedIcon as File}
          open={modal === 'imageUploadPreviewModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            setUploadedIcon(undefined)
          }}
          onSuccessCallback={(url) => {
            setFieldValue('icon', url)
          }}
        />
      ) : null}
    </ModalComponent>
  )
}
