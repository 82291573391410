import React from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { ErrorText } from '../typography/ErrorText'

export const ManageProductCategories = ({
  values,
  setFieldValue,
  errors,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
  errors?: string
}) => {
  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <div className="flex gap-4 items-start justify-between">
        <div className="flex items-center gap-4">
          <HeaderSubheader
            title="Product Categories"
            text="Specify product categories"
          />

          {errors && <ErrorText text={`(${errors})`} />}
        </div>

        <Button
          primary
          icon="plus"
          size="medium"
          label="Manage categories"
          onClick={() => {
            handleModalOpen('selectCategoriesModal')
          }}
          className="!max-w-fit"
        />
      </div>

      <div className="flex flex-row gap-4 items-center flex-wrap">
        {values.category.map((category, idx) => {
          const key = `tag-${idx}`
          return (
            <div
              key={key}
              className="
              text-sm rounded-md
              py-2 px-4
              bg-gray-bg
              "
            >
              {category.name}
            </div>
          )
        })}
      </div>

      <SelectCategoriesModal
        open={modalOpen && modal === 'selectCategoriesModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={(categories) => {
          setFieldValue('category', categories)
          handleModalClose()
        }}
        selectedCategories={values.category}
      />
    </div>
  )
}
