import React, { useState } from 'react'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useModalControl } from '../../hooks/useModalControl'
import { AddCategoryModal } from '../../components/modal/app_modals/addCategoryModal'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { useGetVariantsQuery } from '../../redux-toolkits/products/products.slice'
import { QueryProps, SingleVariant } from '../../types/types'
import { CreateVariantModal } from '../../components/modal/app_modals/createVariantModal'
import { SearchInput } from '../../components/inputs/searchInput'
import { useNavigate } from 'react-router-dom'

const Variants: React.FC = () => {
  const [query, setQuery] = useState<QueryProps>({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: undefined,
  })

  const [selectedVariant, setSelectedVariant] = useState<SingleVariant>()
  const [mode, setMode] = useState<'create' | 'edit'>('create')

  const navigate = useNavigate()

  const { setModalOpen, modalOpen, handleModalOpen, handleModalClose, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useGetVariantsQuery({
    page: query.page,
    limit: query.limit,
    search: query.search,
  })

  return (
    <div>
      <PageHeader title="Variants" />

      <div className="w-full gap-4 flex flex-wrap items-center justify-between">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setQuery({
                ...query,
                search: search === '' ? undefined : search,
                page: 1,
              })
            }}
            placeholder="Search variants"
          />
        </div>

        <Button
          icon="plus"
          size="medium"
          label="Add new product variant"
          primary
          onClick={() => {
            setMode('create')
            handleModalOpen('addVariantModal')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total variants count"
          count={data?.data.total ?? 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Actions']}
        rows={
          data?.data.docs.length
            ? data.data.docs.map((singleVariant) => {
                return {
                  id: singleVariant._id,
                  content: [
                    <div key="">{singleVariant.name}</div>,
                    <Dropdown
                      key={`${singleVariant._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addVariantModal')
                                setSelectedVariant(singleVariant)
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: '',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="variants-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.docs.length)}
        currentPage={Number(query.page) ?? 1}
        totalDataCount={data?.data.total ?? 0}
        pageLimit={Number(query.limit)}
        onPageChange={(number) => {
          setQuery({ ...query, page: number })
        }}
      />

      <CreateVariantModal
        open={modalOpen && modal === 'addVariantModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedVariant(undefined)
        }}
        onSuccessCallback={() => {
          setSelectedVariant(undefined)
          refetch()
        }}
        mode={mode}
        variant={selectedVariant}
      />
    </div>
  )
}

export default Variants
