import React, { ReactNode, useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Icon from '../icon'
import { Logo } from '../logo'
import { Store, Wallet } from 'lucide-react'
import { CURRENT_PAGE_NUMBER, NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { useAppSelector } from '../../hooks'
import { orderSelector } from '../../redux-toolkits/orders/order.reducer'

interface SubMenu {
  title: string
  href: string
}

const SideBar: React.FC<{
  navOpen?: boolean
  setNavOpen?: (val: boolean) => void
}> = ({ navOpen, setNavOpen }) => {
  const { isNewOrderReceived } = useAppSelector(orderSelector)

  const [navItems, setNavItems] = useState<
    Array<{
      id: number
      text: string
      icon: string
      dimension: number
      href: string
      hidden?: string
      subMenu: SubMenu[] | []
      notificationElement?: ReactNode
    }>
  >([])
  const [subMenuOpen, setSubMenuOpen] = useState<{
    state: boolean
    index: number | null
  }>({
    state: false,
    index: null,
  })

  const { pathname } = useLocation()

  useEffect(() => {
    setNavItems([
      {
        id: 1,
        text: 'Dashboard',
        icon: 'dashboard-icon',
        dimension: 23,
        href: '/dashboard',
        subMenu: [],
      },
      {
        id: 2,
        text: 'Creators',
        icon: 'vendor',
        dimension: 23,
        href: '/vendors',
        hidden: '',
        subMenu: [],
      },
      {
        id: 3,
        text: 'Orders',
        icon: 'order-icon',
        dimension: 23,
        href: `/orders?status=all&skip=${CURRENT_PAGE_NUMBER}&limit=${NUMBER_OF_ITEMS_PER_PAGE}`,
        subMenu: [],
        notificationElement: isNewOrderReceived ? (
          <div className=" w-2 h-2 bg-primary rounded-full animate-ping" />
        ) : undefined,
      },
      {
        id: 4,
        text: 'Products',
        icon: 'product-icon',
        dimension: 23,
        href: '',
        hidden: '',
        subMenu: [
          {
            title: 'All Products',
            href: '/products',
          },
          {
            title: 'Everyday Lists',
            href: '/lists/curated',
          },
          {
            title: 'Good Lists',
            href: '/lists/articles',
          },
          {
            title: 'Discover',
            href: '/discover',
          },
          {
            title: 'Discounts',
            href: '/discounts',
          },
        ],
      },

      {
        id: 5,
        text: 'Finance',
        icon: 'hand-coins',
        dimension: 23,
        href: '',
        hidden: '',
        subMenu: [
          {
            title: 'Settlement',
            href: '/settlement',
          },
          {
            title: 'Transactions',
            href: '/transactions',
          },
        ],
      },

      {
        id: 6,
        text: 'CRM',
        icon: 'customers-icon',
        dimension: 23,
        href: '',
        hidden: '',
        subMenu: [
          {
            title: 'Waitlist',
            href: '/waitlist',
          },
        ],
      },
      {
        id: 7,
        text: 'Settings',
        icon: 'gear-icon',
        dimension: 23,
        href: '/settings',
        hidden: '',
        subMenu: [],
      },
    ])
  }, [isNewOrderReceived])

  return (
    <div
      className={`
      bg-frost
      h-full
      border-r-divider-gray border-r
      px-4 md:px-10 py-4 md:py-10
      overflow-y-scroll
      hide-scroll-bar
      flex flex-col
      w-full max-w-[320px]
      absolute xl:relative
      right-[100%] xl:right-auto ${navOpen && '!left-0'}
      !z-[998]
      `}
    >
      {/* Main  */}
      <div className="text-base">
        <div className="flex flex-col text-pry-gray">
          {navItems.map(
            ({
              href,
              id,
              text,
              icon,
              hidden,
              subMenu,
              notificationElement,
            }) => (
              <NavLink
                to={href}
                key={id}
                onClick={(e) => {
                  if (subMenu.length > 0) {
                    e.preventDefault()
                    setSubMenuOpen({
                      state: !subMenuOpen.state,
                      index: id,
                    })
                  } else {
                    setNavOpen && setNavOpen(false)
                  }
                }}
              >
                {({ isActive }) => {
                  const isSubMenuActive = subMenu.some(({ href }) =>
                    pathname.includes(href),
                  )
                  return hidden ? undefined : (
                    <>
                      <div
                        className={`
                        flex items-center
                        gap-4 p-4
                        ${
                          (isActive && subMenu.length === 0) ||
                          (isSubMenuActive && subMenu.length > 0)
                            ? 'text-primary bg-primary/5 font-semibold'
                            : ''
                        }
                        rounded-[8px]
                        hover:text-primary 
                        transition-all duration-500
                        `}
                      >
                        <Icon id={icon} width={18} height={18} />

                        <div className="flex-1">{text}</div>

                        {notificationElement}

                        {subMenu.length > 0 && (
                          <Icon
                            id="left-caret"
                            width={18}
                            height={12}
                            className={`transition-all duration-300 ${
                              subMenuOpen.state && subMenuOpen.index === id
                                ? 'rotate-90'
                                : '-rotate-90'
                            }`}
                          />
                        )}
                      </div>

                      {subMenuOpen.state && subMenuOpen.index === id ? (
                        <div
                          className="
                      flex flex-col
                      pl-[50px] pr-4
                      "
                        >
                          {subMenu.map(({ href, title }, idx) => {
                            const key = `${title}-subMenu-${idx}`
                            return (
                              <NavLink
                                to={href}
                                key={key}
                                className={`
                                py-4
                              hover:text-primary transition-all duration-300
                              `}
                                onClick={() => {
                                  setNavOpen && setNavOpen(false)
                                }}
                              >
                                {({ isActive }) => (
                                  <span
                                    className={`${isActive && 'text-primary'}`}
                                  >
                                    {title}
                                  </span>
                                )}
                              </NavLink>
                            )
                          })}
                        </div>
                      ) : undefined}
                    </>
                  )
                }}
              </NavLink>
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default SideBar
