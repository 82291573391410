import React from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'
import { Tag } from '../../redux-toolkits/products/products.type'
import { SelectTagsModal } from '../modal/app_modals/selectTagsModal'

export const ManageProductTags = ({
  values,
  setFieldValue,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
}) => {
  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <div className="flex gap-4 items-start justify-between">
        <HeaderSubheader title="Product Tags" text="Specify product tags" />

        <Button
          primary
          icon="plus"
          size="medium"
          label="Manage tags"
          onClick={() => {
            handleModalOpen('selectTagsModal')
          }}
          className="!max-w-fit"
        />
      </div>

      <div className="flex flex-row gap-4 items-center flex-wrap">
        {values.tags.map((tag: Tag, idx) => {
          const key = `tag-${idx}`
          return (
            <div
              key={key}
              className="
                          text-sm rounded-md
                          py-2 px-4
                          bg-gray-bg
                          "
            >
              {tag.name}
            </div>
          )
        })}
      </div>

      <SelectTagsModal
        open={modalOpen && modal === 'selectTagsModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={(tags) => {
          setFieldValue('tags', tags)
          handleModalClose()
        }}
        selectedTags={values.tags}
      />
    </div>
  )
}
