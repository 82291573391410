import React, { useState, useMemo } from 'react'
import Icon from '../../components/icon'
import BarChart from '../../components/charts/BarChart'
import {
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useGetTopCreatorsByOrdersQuery,
  useGetTopCreatorsBySalesQuery,
} from '../../redux-toolkits/orders/orders.slice'
import { useGetOrderAnalyticsQuery } from '../../redux-toolkits/orders/orderAnalytics.slice'
import Spinner from '../../components/spinner/Spinner'
import { formatCurrency } from '../../helpers'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import { TabNav } from '../../components/nav/tabNav'
import { AnalyticsDuration } from '../../redux-toolkits/orders/orders.type'

export const DashboardPage: React.FC = () => {
  const [duration, setDuration] = useState<AnalyticsDuration>('day')
  const [orderAnalyticType, setOrderAnalyticType] = useState<string>('count')
  const { data: counts } = useGetOrdersMetricsCountQuery({})
  const { data: values } = useGetOrdersMetricsValueQuery({})

  const { data: orderAnalytics, isLoading: isLoadingAnalytics } =
    useGetOrderAnalyticsQuery({ duration: duration })

  const { data: topCreatorsByOrders } = useGetTopCreatorsByOrdersQuery()
  const { data: topCreatorsBySales } = useGetTopCreatorsBySalesQuery()

  const orderAnalyticsData = useMemo(
    () => orderAnalytics?.data || [],
    [orderAnalytics],
  )
  const labels = useMemo(
    () =>
      orderAnalyticsData.map((data) => {
        switch (duration) {
          case 'day':
            return `${data.hour}:00`
          case 'week':
            return data.day
          case 'month':
            return data.date
          case 'year':
            return data.month
          default:
            return data.day
        }
      }),
    [orderAnalyticsData],
  )
  const orderValueAnalytics = useMemo(
    () => orderAnalyticsData.map((data) => data.total),
    [orderAnalyticsData],
  )
  const orderCountAnalytics = useMemo(
    () => orderAnalyticsData.map((data) => data.count),
    [orderAnalyticsData],
  )

  return (
    <Layout>
      {!isLoadingAnalytics ? (
        <>
          <PageHeader title="Dashboard" />

          <div
            className="
            grid grid-flow-row 
            grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]
            gap-10
            "
          >
            <div className="col-span-full">
              <ChartCard
                title="Order Analytics"
                value={undefined}
                filter={
                  <div
                    className="
                    !w-full
                    flex flex-wrap
                    items-end justify-between
                    gap-2
                    "
                  >
                    <TabNav
                      activeTab={orderAnalyticType}
                      setActiveTab={(selected) => {
                        setOrderAnalyticType(selected)
                      }}
                      tabs={[
                        {
                          name: 'Order counts',
                          id: 'count',
                        },
                        {
                          name: 'Order values',
                          id: 'value',
                        },
                      ]}
                      type="nav"
                      size="small"
                    />

                    <TabNav
                      activeTab={duration}
                      setActiveTab={(selected) => {
                        setDuration(selected as AnalyticsDuration)
                      }}
                      tabs={[
                        {
                          name: 'Today',
                          id: 'day',
                        },
                        {
                          name: 'This week',
                          id: 'week',
                        },
                        {
                          name: 'This month',
                          id: 'month',
                        },
                        {
                          name: 'This year',
                          id: 'year',
                        },
                      ]}
                      type="switcher"
                      size="small"
                    />
                  </div>
                }
                chart={
                  <BarChart
                    responsive
                    labels={labels}
                    data={
                      orderAnalyticType === 'count'
                        ? orderCountAnalytics
                        : orderValueAnalytics
                    }
                  />
                }
              />
            </div>

            {/* <div className="col-span-full">
              <ChartCard
                title="Customers"
                value={formatCurrency(463.35)}
                chart={<LineChart responsive labels={labels} data={orderValueAnalytics} />}
              />
            </div> */}

            <ChartCard
              title="Top Creators by Orders"
              chart={
                <div className="flex flex-col">
                  {topCreatorsByOrders?.data.map((creator) => {
                    return (
                      <div
                        key={creator.vendor._id}
                        className="
                        flex items-center justify-between
                        gap-4
                        border-[#555555]/20
                        border-b last:border-b-0
                        py-2.5 first:pt-0
                        "
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-medium">
                            {creator.vendor.name ?? 'N/A'}
                          </span>
                          <p
                            className="
                            bg-white
                            px-2 py-1
                            rounded-full
                            max-w-fit
                            text-xs
                            flex items-center
                            gap-1
                            border border-dark-gray/40
                            "
                          >
                            <Icon id="vendor" height={10} width={10} />
                            {creator?.vendor?.email}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">
                            {creator.orderCount}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />

            <ChartCard
              title="Top Creators by Sales"
              chart={
                <div className="flex flex-col">
                  {topCreatorsBySales?.data.map((creator) => {
                    return (
                      <div
                        key={creator.vendor._id}
                        className="
                        flex items-center justify-between
                        gap-4
                        border-[#555555]/20
                        border-b last:border-b-0
                        py-2.5 first:pt-0
                        "
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-medium">
                            {creator.vendor.name ?? 'N/A'}
                          </span>
                          <p
                            className="
                            bg-white
                            px-2 py-1
                            rounded-full
                            max-w-fit
                            text-xs
                            flex items-center
                            gap-1
                            border border-dark-gray/40
                            "
                          >
                            <Icon id="vendor" height={10} width={10} />
                            {creator?.vendor?.email}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">
                            {formatCurrency(creator.totalSales)}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <Spinner fullScreen={false} />
      )}
    </Layout>
  )
}
