import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../../layout'
import {
  useGetHarmonizedOrdersBreakdownQuery,
  useGetOrderQuery,
  useGetOrderSettlementsQuery,
  useRequestOrderDeliveryMutation,
  useUpdateOrderMutation,
} from '../../redux-toolkits/orders/orders.slice'
import { Link, Navigate, useParams } from 'react-router-dom'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import {
  formatCurrency,
  getMarkUpValue,
  getTimeAgo,
  getVendorName,
} from '../../helpers'
import { format, formatDate } from 'date-fns'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import {
  DISABLE__DELIVERY_REQUEST__FROM,
  ORDERSTATUS,
  partyMappings,
  SETTLEMENTSTATUS,
} from '../../constants'
import Icon from '../../components/icon'
import Dropdown from '../../components/inputs/dropdown'
import { StatusTypes } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import { TableComponent } from '../../components/table'
import { Button } from '../../components/button'

const OrderDetailsPage: React.FC = () => {
  const { harmonizedReference } = useParams<{ harmonizedReference: string }>()

  const {
    data: harmonizedOrder,
    isLoading,
    refetch,
  } = useGetHarmonizedOrdersBreakdownQuery({
    harmonizedReference: harmonizedReference ?? '',
  })

  const [openOrders, setOpenOrders] = useState<{ [key: string]: boolean }>({})

  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation()

  const [requestDelivery, { isLoading: requestingDelivery }] =
    useRequestOrderDeliveryMutation()

  if (!harmonizedReference) return <Navigate to="/dashboard" replace />

  const toggleOrder = (orderId: string) => {
    setOpenOrders((prevState: any) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }))
  }

  const handleChangeStatus = async (
    id: string,
    status: string,
  ): Promise<void> => {
    const response = await updateOrder({ id: id, body: { status: status } })

    if (response.data?.success) {
      refetch()
    }
  }
  const orderId = harmonizedOrder?.data?.orders?.[0]?._id
  const { data: settlements, isLoading: fetchingSettlements } =
    useGetOrderSettlementsQuery({ id: orderId ?? '' }, { skip: !orderId })

  return (
    <Layout>
      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <div className="flex flex-col gap-10 mb-20">
          <div>
            <PageHeader
              title={`Order #${
                harmonizedOrder?.data?.orders?.[0]?.harmonizedReference ?? ''
              }`}
              className="!mb-0"
            />

            <div className="mt-4 flex items-center gap-2 text-sec-black">
              Ordered by{' '}
              <Link
                className="underline"
                to={`/customers/${
                  harmonizedOrder?.data?.orders?.[0]?.userId ?? ''
                }`}
              >{`${
                harmonizedOrder?.data?.orders?.[0]?.personalInformation
                  ?.firstName ?? ''
              } ${
                harmonizedOrder?.data?.orders?.[0]?.personalInformation
                  ?.lastName ?? ''
              }`}</Link>
              <Icon id="dot-icon" width={6} height={6} />
              <span className="">
                {getTimeAgo(
                  harmonizedOrder?.data?.orders?.[0]?.createdAt ?? '',
                )}
              </span>
            </div>
          </div>

          {harmonizedOrder?.data?.orders?.map((order) => (
            <div key={order.orderNumber} className="flex flex-col gap-10">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-4 justify-between">
                  <p className="text-xl font-medium">
                    {`Order #${order.orderNumber ?? ''}`}
                  </p>

                  {harmonizedOrder?.data?.orders?.length > 1 && (
                    <button
                      type="button"
                      onClick={() => toggleOrder(order._id)}
                      className="p-2"
                    >
                      <Icon
                        id={'left-caret'}
                        width={18}
                        height={16}
                        className={`transition-all duration-300 ${
                          openOrders[order._id] ? 'rotate-90' : '-rotate-90'
                        }`}
                      />
                    </button>
                  )}
                </div>

                <div className="flex flex-wrap items-center justify-between gap-4 text-sec-black">
                  <span className="text-xl font-medium">
                    {formatCurrency(order.amount ?? 0)}
                  </span>

                  {(harmonizedOrder?.data?.orders?.length === 1 ||
                    openOrders[order._id]) && (
                    <div className="flex items-center gap-4 animate-fadeIn transition-opacity duration-300">
                      {order?.delivery?.type === 'DELIVERY' && (
                        <Button
                          type="button"
                          label="Request delivery"
                          onClick={async () => {
                            await requestDelivery({ id: order._id })
                          }}
                          loading={requestingDelivery}
                          disabled={
                            requestingDelivery ||
                            (ORDERSTATUS.find(
                              (stat) =>
                                stat.name.toLowerCase() ===
                                order.status.toLowerCase(),
                            )?.id ?? Infinity) >=
                              DISABLE__DELIVERY_REQUEST__FROM
                          }
                          primary
                          size="medium"
                          iconSize={20}
                        />
                      )}

                      <Dropdown
                        menuButton={
                          <div className="p-3 rounded-[8px] border border-black">
                            {updatingOrder ? (
                              <LoadingOval
                                color="black"
                                loaderHeight="18"
                                loaderWidth="18"
                              />
                            ) : (
                              <Icon
                                id="ellipses"
                                height={18}
                                width={18}
                                className=""
                              />
                            )}
                          </div>
                        }
                        onClickMenuItem={() => {}}
                        menuItems={[
                          ...ORDERSTATUS.map((status) => {
                            return {
                              name: (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleChangeStatus(order._id, status.name)
                                  }}
                                  className="text-left"
                                >
                                  Set as {status.name}
                                </button>
                              ),
                              value: '',
                            }
                          }),
                        ]}
                        menuItemClassName="border-b-0"
                        menuClassName="!w-[300px]"
                      />
                    </div>
                  )}
                </div>

                <Status
                  text={order.status.toLowerCase() ?? ''}
                  type={
                    (ORDERSTATUS.find(
                      (status) =>
                        status.name.toLowerCase() ===
                        (order.status ?? '').toLowerCase(),
                    )?.type ?? 'warn') as StatusTypes
                  }
                  className="!text-sm"
                />
              </div>

              {(harmonizedOrder?.data?.orders?.length === 1 ||
                openOrders[order._id]) && (
                <div className="flex flex-col gap-10">
                  <div>
                    <p className="text-xl mb-6 font-medium">Delivery Details</p>

                    <div>
                      <div
                        className="
                            py-4 first:pt-0 last:pb-0
                            border-b flex items-center last:border-b-0
                            justify-between gap-4
                            w-full max-w-[620px]
                            "
                      >
                        <span className="text-sec-black">Fulfilment type:</span>
                        <span className="text-black font-medium">
                          {order?.delivery?.type}
                        </span>
                      </div>

                      <div
                        className="
                            py-4 first:pt-0 last:pb-0
                            border-b flex items-center last:border-b-0
                            justify-between gap-4
                            w-full max-w-[620px]
                            "
                      >
                        <span className="text-sec-black">Tracking ID:</span>
                        <a
                          className="text-black font-medium"
                          target="_blank"
                          href=""
                        >
                          {order?.metadata?.trackingCode}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-6">
                    <p className="text-xl font-medium">Order Details</p>

                    <div>
                      <div
                        className="
                            py-4 first:pt-0 last:pb-0
                            border-b flex items-center last:border-b-0
                            justify-between gap-4
                            w-full max-w-[620px]
                            "
                      >
                        <span className="text-sec-black">
                          {' '}
                          {order?.delivery?.type === 'PICKUP'
                            ? 'Fulfilment Center'
                            : 'Address:'}
                        </span>
                        <span className="text-black font-medium">
                          {order?.delivery?.type === 'PICKUP'
                            ? order?.fulfilmentCentre?.address
                            : order?.delivery?.address}
                        </span>
                      </div>

                      <div
                        className="
                            py-4 first:pt-0 last:pb-0
                            border-b flex items-center last:border-b-0
                            justify-between gap-4
                            w-full max-w-[620px]
                            "
                      >
                        <span className="text-sec-black">
                          Customer Phone Number:
                        </span>
                        <span className="text-black font-medium">
                          {order?.personalInformation?.phone}
                        </span>
                      </div>
                    </div>

                    <div>
                      {order.orderItems?.map((item, idx) => {
                        const key = `item-${item._id}`

                        return (
                          <div
                            className="
                            flex flex-wrap
                            gap-6
                            w-full max-w-[800px]
                            py-6 first:!pt-0 last:!pb-0 only:!pt-0
                            border-b last:border-b-0
                            "
                            key={key}
                          >
                            <div className="min-w-fit">
                              <img
                                src={item.product.images[0] ?? ''}
                                className="w-[140px] h-[140px] object-cover rounded-md"
                              />
                            </div>

                            <div className="flex-1 text-base text-sec-black">
                              <div className="flex items-center gap-4 justify-between">
                                <span className="text-black font-medium">
                                  {item.product.name}
                                </span>
                                <span>Quantity: {item.quantity}</span>
                              </div>

                              <p className="mt-2">
                                {`Base Price: ${formatCurrency(
                                  item.basePricePerUnit,
                                )}`}
                              </p>
                              {item.product.isMarkupEnabled && (
                                <p className="mt-2">
                                  {`Markup Price: ${formatCurrency(
                                    item.markupAmountPerUnit,
                                  )}`}
                                </p>
                              )}

                              {item.isGift && (
                                <div className="mt-4 text-sm">
                                  <div className="text-black mt-4 mb-1 flex gap-2 items-center">
                                    <Icon
                                      id="gift-icon"
                                      width={18}
                                      height={18}
                                    />{' '}
                                    This is a gift
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <div className="flex items-center gap-4 justify-between">
                                      <p>From:</p>
                                      <p>{item.giftFrom}</p>
                                    </div>

                                    <div className="flex flex-col gap-1 justify-between">
                                      <p>Gift note:</p>
                                      <p className="text-left">
                                        {item.giftNote}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {item.variants.length > 0 &&
                                item.variants.map((variant, idx) => {
                                  const key = `${idx}-variant`
                                  return (
                                    <div className="text-sm" key={key}>
                                      <div className="text-black mt-4 mb-1">
                                        {variant.variantId.name}
                                      </div>
                                      <div className="flex flex-col gap-1">
                                        <div
                                          key={key}
                                          className="flex items-center gap-4 justify-between"
                                        >
                                          <span>{variant.value}</span>
                                          <span>
                                            {formatCurrency(variant.price ?? 0)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}

                              {item.comboItems.length > 0 && (
                                <div className="text-sm">
                                  <div className="text-black mt-4 mb-1">
                                    Extra options:
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    {item.comboItems.map((comboItem, idx) => {
                                      const key = `${idx}-combo-item`

                                      return (
                                        <div
                                          key={key}
                                          className="flex items-center gap-4 justify-between"
                                        >
                                          <span>
                                            {comboItem.name} (Qty:{' '}
                                            {comboItem.quantity})
                                          </span>
                                          <span>
                                            {formatCurrency(
                                              comboItem.price ?? 0,
                                            )}
                                          </span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          <TableComponent
            name="Settlements"
            showName
            isEmpty={[undefined, 0].includes(settlements?.data?.length)}
            headers={[
              'Transaction Id',
              'Order Id',
              'Party',
              'Amount Settled',
              'Status',
              'Date',
            ]}
            rows={
              settlements
                ? settlements.data.map((item) => {
                    return {
                      id: item._id,
                      content: [
                        item._id,
                        <Link
                          to={`/orders/${item.order._id}`}
                          className="
                          hover:text-black hover:underline
                          transition-all duration-500
                          "
                          key={`${item.order._id}-product-id`}
                        >
                          #{item.order.orderNumber}
                        </Link>,
                        item.party === 'VENDOR' ? (
                          <div
                            key={`${item._id}-vendor-party`}
                            className="flex gap-2 items-center"
                          >
                            <img
                              src={item.vendor.logo ?? ''}
                              className="object-cover w-[40px] h-[40px] rounded-full overflow-hidden"
                            />
                            <span>{getVendorName(item.vendor)}</span>
                          </div>
                        ) : (
                          (partyMappings[
                            item.party as keyof typeof partyMappings
                          ] as string)
                        ),
                        formatCurrency(item.amount),
                        <Status
                          text={item.status.toLowerCase()}
                          type={
                            (SETTLEMENTSTATUS.find(
                              (status) =>
                                status.name.toLowerCase() ===
                                item.status.toLowerCase(),
                            )?.type ?? 'warn') as StatusTypes
                          }
                          key={`${item._id}-status`}
                        />,
                        `${format(item.createdAt, 'dd/MM/yyyy h:mma')}`,
                      ],
                    }
                  })
                : []
            }
            loading={fetchingSettlements}
          />
        </div>
      )}
    </Layout>
  )
}

export default OrderDetailsPage
