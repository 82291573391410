import { useState } from 'react'

export const useDraggable = () => {
  const handleDragStart = (index: number) => (event: React.DragEvent) => {
    event.dataTransfer.setData('text/plain', index.toString())
  }

  const handleDrop =
    (
      index: number,
      vals: any[],
      callback: (vals: any[], startIndex: number, index: number) => void,
    ) =>
    (event: React.DragEvent) => {
      event.preventDefault()
      const startIndex = Number(event.dataTransfer.getData('text/plain'))
      callback(vals, startIndex, index)
    }

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault()
  }

  return { handleDragStart, handleDragOver, handleDrop }
}
