import React, { useState } from 'react'
import * as Yup from 'yup'
import {
  useDeactivateActivateTeamMemberMutation,
  useEditTeamMemberMutation,
  useGetRolesQuery,
  useGetTeamMembersQuery,
  useInviteTeamMemberMutation,
} from '../../redux-toolkits/user/user.slice'
import { useAppSelector } from '../../hooks'
import { userSelector } from '../../redux-toolkits/user/user.selector'
import { Form, Formik } from 'formik'
import { type TeamMember } from '../../redux-toolkits/user/user.type'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { Button } from '../../components/button'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import ModalComponent from '../../components/modal'
import { ConfirmDeleteModal } from '../../components/modal/app_modals/confirmDeleteModal'
import { TextInput } from '../../components/inputs/textInput'
import { SearchInput } from '../../components/inputs/searchInput'
import { QueryProps } from '../../types/types'

const TeamMembers: React.FC = () => {
  const [query, setQuery] = useState<QueryProps>({
    skip: 0,
    limit: 20,
    search: undefined,
  })
  const [editing, setEditing] = useState<TeamMember | undefined>()
  const [deleting, setDeleting] = useState<TeamMember | undefined>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const { data, isFetching, refetch } = useGetTeamMembersQuery({ ...query })
  const { teamMembers } = useAppSelector(userSelector)

  const closeModal = (): void => {
    setOpenModal(false)
    setEditing(undefined)
  }
  return (
    <div className="flex flex-col gap-10">
      <HeaderSubheader
        title="Team members"
        text="A list of all the users in your account including their name,
              title, email and role."
      />

      <div className="flex items-center justify-between gap-4">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setQuery({
                ...query,
                search: search === '' ? undefined : search,
                skip: 0,
              })
            }}
            placeholder="Seach team members"
          />
        </div>

        <Button
          type="button"
          label="Invite team member"
          primary
          size="medium"
          onClick={() => {
            setOpenModal(true)
          }}
          icon="plus"
        />
      </div>

      <TableComponent
        loading={isFetching}
        headers={[
          'First name',
          'Last name',
          'Email',
          'Role',
          'Status',
          'Actions',
        ]}
        rows={
          data?.data.admins.map((teamMember) => {
            return {
              id: teamMember._id,
              content: [
                teamMember.firstname,
                teamMember.lastname,
                teamMember.email,
                teamMember.role.name ?? '',
                <Status
                  key={`${teamMember._id}-status`}
                  text={teamMember.deleted ? 'Inactive' : 'Active'}
                  type={teamMember.deleted ? 'fail' : 'success'}
                />,
                <Dropdown
                  className="text-left"
                  key={`${teamMember._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          onClick={() => {
                            setEditing(teamMember)
                            setOpenModal(true)
                          }}
                          className="w-full text-left"
                        >
                          Edit
                        </button>
                      ),
                      value: '',
                    },
                    {
                      name: (
                        <button
                          onClick={() => {
                            setDeleting(teamMember)
                            setOpenDeleteModal(true)
                          }}
                          className="w-full text-left"
                        >
                          {teamMember.deleted ? 'Activate' : 'Deactivate'}
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        isEmpty={[0, undefined].includes(data?.data.admins.length)}
        currentPage={Math.floor(Number(query.skip) / Number(query.limit)) + 1}
        totalDataCount={data?.data.totalCount ?? 0}
        pageLimit={Number(query.limit)}
        onPageChange={(number) => {
          setQuery({ ...query, skip: (number - 1) * Number(query.limit) })
        }}
      />

      <CreateTeammateModal
        teamMember={editing}
        open={openModal}
        closeModal={() => {
          closeModal()
          refetch()
        }}
      />

      <ActivateTeamMemberModal
        teamMember={deleting}
        open={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false)
          setDeleting(undefined)
          refetch()
        }}
      />
    </div>
  )
}

const ActivateTeamMemberModal: React.FC<{
  open: boolean
  closeModal: () => void
  teamMember?: TeamMember
}> = ({ open, closeModal, teamMember }) => {
  const [deactivateActivateTeamMember, { isLoading }] =
    useDeactivateActivateTeamMemberMutation()

  return (
    <>
      <ConfirmDeleteModal
        loading={isLoading}
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
        title={`${teamMember?.deleted ? 'Activate' : 'Deactivate'} team member`}
        message={`Are you sure you want to ${
          teamMember?.deleted ? 'activate' : 'deactivate'
        } ${teamMember?.firstname} ${teamMember?.lastname}?`}
        onConfirm={() => {
          deactivateActivateTeamMember({
            payload: { deleted: !teamMember?.deleted },
            id: teamMember?._id ?? '',
          })?.then((resp) => {
            if (resp.data) {
              closeModal()
            }
          })
        }}
      />
    </>
  )
}

const CreateTeammateModal: React.FC<{
  open: boolean
  closeModal: () => void
  teamMember?: TeamMember
}> = ({ open, closeModal, teamMember }) => {
  const { isLoading, data: roles } = useGetRolesQuery({
    skip: 0,
    limit: 100,
  })

  const [inviteTeamMember, { isLoading: invitingTeamMember }] =
    useInviteTeamMemberMutation()
  const [editTeamMember, { isLoading: updatingTeamMember }] =
    useEditTeamMemberMutation()

  return (
    <>
      <ModalComponent
        title={`${
          teamMember !== undefined ? 'Edit' : 'Invite new'
        } team member`}
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
      >
        <Formik
          initialValues={{
            firstname: teamMember?.firstname ?? '',
            lastname: teamMember?.lastname ?? '',
            email: teamMember?.email ?? '',
            roleId: teamMember?.role._id ?? '',
          }}
          validationSchema={Yup.object({
            firstname: Yup.string().required('Required'),
            lastname: Yup.string().required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            roleId: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            if (teamMember !== null && teamMember !== undefined) {
              await editTeamMember({
                payload: values,
                id: teamMember._id,
              })
              closeModal()
            } else {
              await inviteTeamMember(values)
              closeModal()
            }
          }}
        >
          {({
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form className="flex flex-col gap-4">
              <TextInput
                type="text"
                name="firstname"
                placeholder="First name"
                value={values.firstname}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.firstname && touched.firstname ? errors.firstname : ''
                }
                hasIcon={false}
              />

              <TextInput
                type="text"
                name="lastname"
                placeholder="Last name"
                value={values.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.lastname && touched.lastname ? errors.lastname : ''
                }
                hasIcon={false}
              />

              <TextInput
                type="text"
                name="email"
                placeholder="Email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.email && touched.email ? errors.email : ''}
                hasIcon={false}
              />

              <Dropdown
                loading={isLoading}
                className=""
                menuClassName="max-h-[200px]"
                widthClass="!w-full"
                menuButton={
                  <TextInput
                    type="text"
                    name="roleId"
                    placeholder="Role"
                    value={
                      roles?.find((role) => role._id === values.roleId)?.name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors.roleId && touched.roleId ? errors.roleId : ''
                    }
                    hasIcon={false}
                    readOnly
                    extra={
                      <Icon
                        id={'left-caret'}
                        width={16}
                        height={16}
                        className="-rotate-90"
                      />
                    }
                    className="!border-divider-gray"
                  />
                }
                onClickMenuItem={(selection) => {
                  setFieldValue('roleId', selection.value)
                }}
                menuItems={
                  roles?.map((role) => {
                    return {
                      name: role.name,
                      value: role._id,
                    }
                  }) ?? []
                }
              />

              <Button
                disabled={invitingTeamMember || updatingTeamMember}
                loading={invitingTeamMember || updatingTeamMember}
                type="submit"
                label="Proceed"
                primary
                className="!mt-6 !w-full"
              />
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </>
  )
}

export default TeamMembers
