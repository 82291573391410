import React, { useEffect, useState } from 'react'
import {
  OrderPayload,
  SingleOrder,
} from '../../redux-toolkits/orders/orders.type'
import { BACKEND_BASE_URL } from '../../constants'
import { updateNewOrderStatus } from '../../redux-toolkits/orders/order.reducer'
import { useAppDispatch } from '../../hooks'

const OrderSSEComponent = () => {
  const [newOrders, setNewOrders] = useState<SingleOrder[]>([])
  const [audio, setAudio] = useState<HTMLAudioElement>()

  const dispatch = useAppDispatch()

  useEffect(() => {
    // Create a new EventSource that connects to the SSE endpoint
    const eventSource = new EventSource(`${BACKEND_BASE_URL}admin/order/sse`)

    eventSource.onmessage = (event) => {
      const newOrderData: SingleOrder = JSON.parse(event.data)

      if (newOrderData._id) {
        setNewOrders([...newOrders, newOrderData])
        dispatch(updateNewOrderStatus(true))

        audio &&
          audio.play().catch((error) => {
            console.error('Error playing the sound:', error)
          })
      }
    }

    eventSource.onerror = (error) => {
      console.error('Error with EventSource:', error)
      eventSource.close()
    }

    return () => {
      eventSource.close()
    }
  }, [audio])

  useEffect(() => {
    const sound = new Audio('/assets/audio/notification-sound.mp3')
    console.log(sound)

    setAudio(sound)
  }, [])

  return undefined
}

export default OrderSSEComponent
