import React, { useState } from 'react'

import { useNavigate } from 'react-router'

import { NUMBER_OF_ITEMS_PER_PAGE } from '../../../../constants'
import { TableComponent } from '../../../../components/table'
import {
  useDeleteListArticleMutation,
  useFetchAllListArticlesQuery,
} from '../../../../redux-toolkits/lists/list.slice'
import { Article } from '../../../../types/types'
import { useModalControl } from '../../../../hooks/useModalControl'
import { ConfirmDeleteModal } from '../../../../components/modal/app_modals/confirmDeleteModal'
import Icon from '../../../../components/icon'
import { formatCurrency } from '../../../../helpers'

interface Props {
  className?: string
}

const ListArticlesTable: React.FC<Props> = ({
  className = '',
}): JSX.Element => {
  const [selectedArticle, setSelectedArticle] = useState<Article>()
  const { modal, modalOpen, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const navigate = useNavigate()

  const [query, setQuery] = useState({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const { data, isFetching, refetch } = useFetchAllListArticlesQuery({
    query: {
      page: query.page,
      limit: query.limit,
    },
  })

  const [deleteArticle, { isLoading: isDeleting }] =
    useDeleteListArticleMutation()

  return (
    <div className={`${className}`}>
      <TableComponent
        headers={[]}
        rows={
          data?.data?.result?.length
            ? data.data.result.map((listDetail) => {
                return {
                  id: listDetail._id,
                  content: [
                    <div
                      key=""
                      className="
                      flex gap-4 items-center
                      !max-w-full !overflow-hidden
                    "
                    >
                      <img
                        src={listDetail.bannerUrl}
                        className="
                            w-[60px]
                            h-[60px]
                            object-cover
                            rounded-[8px]
                            "
                        alt="article-cover"
                      />

                      <div
                        className="
                        flex-1
                        flex flex-col gap-2 text-sm
                        max-w-full overflow-hidden
                      "
                      >
                        <button
                          className="
                          text-base
                          text-left
                          hover:underline
                          transition-all duration-500
                          w-fit
                          font-recoleta-medium
                          text-black
                          "
                          onClick={() => {
                            navigate(`/lists/articles/view/${listDetail._id}`)
                          }}
                        >
                          {listDetail.title}
                        </button>
                        <p className="text-sm">
                          Starting from:{' '}
                          {formatCurrency(listDetail?.startingFrom ?? 0)}
                        </p>
                        <p
                          className="
                          max-w-full 
                          !overflow-hidden 
                          !text-ellipsis
                          line-clamp-2
                          text-wrap
                          text-sm
                        "
                        >
                          {listDetail.description}
                        </p>
                      </div>

                      <button
                        onClick={() => {
                          setSelectedArticle(listDetail)
                          handleModalOpen('confirmArticleDeleteModal')
                        }}
                        className="text-danger bg-danger/5 rounded-full p-2"
                      >
                        <Icon id="bin" width={16} height={16} />
                      </button>
                    </div>,
                  ],
                }
              })
            : []
        }
        name="lists-table"
        isRowClickable={false}
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.result?.length)}
        currentPage={query.page}
        totalDataCount={data?.data?.count}
        pageLimit={query.limit}
        pageRangeDisplayed={3}
        onPageChange={(pageNo) => {
          setQuery({ ...query, page: pageNo })
        }}
        fixedTableWidth
      />

      <ConfirmDeleteModal
        open={modal === 'confirmArticleDeleteModal' && modalOpen}
        setOpen={setModalOpen}
        message={
          <div>
            Are you sure you want to delete the{' '}
            <span className="">&quot;{selectedArticle?.title}</span>
            &quot; article?
          </div>
        }
        onCloseCallback={() => {
          setSelectedArticle(undefined)
        }}
        loading={isDeleting}
        onConfirm={() => {
          if (selectedArticle) {
            deleteArticle({ id: selectedArticle?._id }).then((resp) => {
              if (resp.data?.success) {
                setSelectedArticle(undefined)
                handleModalClose()
                refetch()
              }
            })
          }
        }}
      />
    </div>
  )
}

export default ListArticlesTable
