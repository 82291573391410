import React, { useState, useMemo } from 'react'
import Icon from '../../components/icon'
// import { Link } from 'react-router-dom'
import BarChart from '../../components/charts/BarChart'
import LineChart from '../../components/charts/LineChart'
import {
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useGetOrdersQuery,
  useGetTopCreatorsByOrdersQuery,
  useGetTopCreatorsBySalesQuery,
} from '../../redux-toolkits/orders/orders.slice'
import { useGetOrderAnalyticsQuery } from '../../redux-toolkits/orders/orderAnalytics.slice'
import Spinner from '../../components/spinner/Spinner'
import { formatCurrency } from '../../helpers'
import Layout from '../../layout'
import OrderTable from '../orders/OrderTable'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TextInput } from '../../components/inputs/textInput'
import { TabNav } from '../../components/nav/tabNav'
import { AnalyticsDuration } from '../../redux-toolkits/orders/orders.type'

export const DashboardPage: React.FC = () => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [duration, setDuration] = useState<AnalyticsDuration>('day')
  const [orderAnalyticType, setOrderAnalyticType] = useState<string>('count')
  const { data: counts } = useGetOrdersMetricsCountQuery({ startDate, endDate })
  const { data: values } = useGetOrdersMetricsValueQuery({ startDate, endDate })

  const { data: orderAnalytics, isLoading: isLoadingAnalytics } =
    useGetOrderAnalyticsQuery({ duration: duration })

  const { data: topCreatorsByOrders } = useGetTopCreatorsByOrdersQuery()
  const { data: topCreatorsBySales } = useGetTopCreatorsBySalesQuery()

  const value = values?.data
  const count = counts?.data

  const orderAnalyticsData = useMemo(
    () => orderAnalytics?.data || [],
    [orderAnalytics],
  )
  const labels = useMemo(
    () =>
      orderAnalyticsData.map((data) => {
        switch (duration) {
          case 'day':
            return `${data.hour}:00`
          case 'week':
            return data.day
          case 'month':
            return data.date
          case 'year':
            return data.month
          default:
            return data.day
        }
      }),
    [orderAnalyticsData],
  )
  const orderValueAnalytics = useMemo(
    () => orderAnalyticsData.map((data) => data.total),
    [orderAnalyticsData],
  )
  const orderCountAnalytics = useMemo(
    () => orderAnalyticsData.map((data) => data.count),
    [orderAnalyticsData],
  )

  return (
    <Layout>
      {!isLoadingAnalytics ? (
        <>
          <PageHeader title="Dashboard" />

          <div className="flex justify-end">
            <div className="flex items-center gap-6 flex-wrap">
              <div className="flex flex-col gap-2">
                <p className="text-sm text-sec-black">Start Date:</p>
                <div>
                  <TextInput
                    placeholder="Start date"
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                    hasIcon={false}
                    className="!border-black"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-sm text-sec-black">End Date:</p>
                <div>
                  <TextInput
                    placeholder="End date"
                    type="date"
                    name="endDate"
                    value={
                      startDate === '' || startDate === undefined ? '' : endDate
                    }
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                    hasIcon={false}
                    readOnly={startDate === '' || startDate === undefined}
                    className="!border-black"
                  />
                </div>
              </div>
            </div>
          </div>

          <CountCardContainer>
            <CountCard
              count={value?.totalOrderValue ?? 0}
              text="Total order"
              isCurrency={true}
            />
            <CountCard
              count={value?.completedOrderValue ?? 0}
              text="Completed order"
              isCurrency={true}
            />
            <CountCard
              count={value?.pendingOrderValue ?? 0}
              text="Pending order"
              isCurrency={true}
            />
            <CountCard
              count={value?.processingOrderValue ?? 0}
              text="Processing order"
              isCurrency={true}
            />
            <CountCard
              count={value?.cancelledOrderValue ?? 0}
              text="Cancelled order"
              isCurrency={true}
            />

            <CountCard
              count={count?.totalOrderCount ?? 0}
              text="Total order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.pendingOrderCount ?? 0}
              text="Pending order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.processingOrderCount ?? 0}
              text="Processing order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.completedOrderCount ?? 0}
              text="Completed order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.cancelledOrderCount ?? 0}
              text="Cancelled order count"
              isCurrency={false}
            />
          </CountCardContainer>

          <div
            className="
            grid grid-flow-row 
            grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]
            gap-10
            "
          >
            <div className="col-span-full">
              <ChartCard
                title="Order Analytics"
                value={undefined}
                filter={
                  <div
                    className="
                    !w-full
                    flex flex-wrap
                    items-end justify-between
                    gap-2
                    "
                  >
                    <TabNav
                      activeTab={orderAnalyticType}
                      setActiveTab={(selected) => {
                        setOrderAnalyticType(selected)
                      }}
                      tabs={[
                        {
                          name: 'Order counts',
                          id: 'count',
                        },
                        {
                          name: 'Order values',
                          id: 'value',
                        },
                      ]}
                      type="nav"
                      size="small"
                    />

                    <TabNav
                      activeTab={duration}
                      setActiveTab={(selected) => {
                        setDuration(selected as AnalyticsDuration)
                      }}
                      tabs={[
                        {
                          name: 'Today',
                          id: 'day',
                        },
                        {
                          name: 'This week',
                          id: 'week',
                        },
                        {
                          name: 'This month',
                          id: 'month',
                        },
                        {
                          name: 'This year',
                          id: 'year',
                        },
                      ]}
                      type="switcher"
                      size="small"
                    />
                  </div>
                }
                chart={
                  <BarChart
                    responsive
                    labels={labels}
                    data={
                      orderAnalyticType === 'count'
                        ? orderCountAnalytics
                        : orderValueAnalytics
                    }
                  />
                }
              />
            </div>

            {/* <div className="col-span-full">
              <ChartCard
                title="Customers"
                value={formatCurrency(463.35)}
                chart={<LineChart responsive labels={labels} data={orderValueAnalytics} />}
              />
            </div> */}

            <ChartCard
              title="Top Creators by Orders"
              chart={
                <div className="flex flex-col">
                  {topCreatorsByOrders?.data.map((creator) => {
                    return (
                      <div
                        key={creator.vendor._id}
                        className="
                        flex items-center justify-between
                        gap-4
                        border-[#555555]/20
                        border-b last:border-b-0
                        py-2.5 first:pt-0
                        "
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-medium">
                            {creator.vendor.name ?? 'N/A'}
                          </span>
                          <p
                            className="
                            bg-white
                            px-2 py-1
                            rounded-full
                            max-w-fit
                            text-xs
                            flex items-center
                            gap-1
                            border border-dark-gray/40
                            "
                          >
                            <Icon id="vendor" height={10} width={10} />
                            {creator?.vendor?.email}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">
                            {creator.orderCount}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />

            <ChartCard
              title="Top Creators by Sales"
              chart={
                <div className="flex flex-col">
                  {topCreatorsBySales?.data.map((creator) => {
                    return (
                      <div
                        key={creator.vendor._id}
                        className="
                        flex items-center justify-between
                        gap-4
                        border-[#555555]/20
                        border-b last:border-b-0
                        py-2.5 first:pt-0
                        "
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-medium">
                            {creator.vendor.name ?? 'N/A'}
                          </span>
                          <p
                            className="
                            bg-white
                            px-2 py-1
                            rounded-full
                            max-w-fit
                            text-xs
                            flex items-center
                            gap-1
                            border border-dark-gray/40
                            "
                          >
                            <Icon id="vendor" height={10} width={10} />
                            {creator?.vendor?.email}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">
                            {formatCurrency(creator.totalSales)}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <Spinner fullScreen={false} />
      )}
    </Layout>
  )
}
