import React from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'

export const ManageProductSubCategories = ({
  values,
  setFieldValue,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
}) => {
  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <div className="flex gap-4 items-start justify-between">
        <HeaderSubheader
          title="Product Sub-Categories"
          text="Specify product sub-categories"
        />

        <Button
          primary
          icon="plus"
          size="medium"
          label="Manage sub-categories"
          onClick={() => {
            handleModalOpen('selectSubCategoriesModal')
          }}
          className="!max-w-fit"
          disabled={values.category.length < 1}
        />
      </div>

      <div className="flex flex-row gap-4 items-center flex-wrap">
        {values.subCategory.map((subCategory, idx) => {
          const key = `tag-${idx}`
          return (
            <div
              key={key}
              className="
                          text-sm rounded-md
                          py-2 px-4
                          bg-gray-bg
                          "
            >
              {subCategory.name}
            </div>
          )
        })}
      </div>

      <SelectSubCategoriesModal
        open={modalOpen && modal === 'selectSubCategoriesModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={(subCategory) => {
          setFieldValue('subCategory', subCategory)
          handleModalClose()
        }}
        selectedSubCategories={values.subCategory}
        categories={values.category.map((cat) => cat._id)}
      />
    </div>
  )
}
