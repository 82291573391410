import React from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { RetrySettlementSchema } from '../../../features/users/validate'

export const ConfirmRetrySettlementModal = ({
  open,
  setOpen,
  onCloseCallback,
  settlementId,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  settlementId: string
}) => {
  const { values, errors, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: {
        password: '',
      },
      validationSchema: RetrySettlementSchema,
      onSubmit: (values) => {
        console.log(values)
      },
    })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={onCloseCallback}
      title={'Enter your password to retry settlement payment'}
      childrenClassName="!max-w-[420px]"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <TextInput
          type="text"
          name="name"
          placeholder="Password"
          value={values.password}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.password && touched.password ? errors.password : ''}
          hasIcon={false}
        />

        <Button
          type="submit"
          label="Retry payment"
          primary
          className="!w-full"
        />
      </form>
    </ModalComponent>
  )
}
