import React, { useState } from 'react'
import Layout from '../../layout'
import {
  useGetAllVendorsMetricsQuery,
  useGetAllVendorsQuery,
  useInviteVendorMutation,
  useReInviteVendorMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import ReactPaginate from 'react-paginate'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import { Plus } from 'lucide-react'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import * as Yup from 'yup'
import { Formik, Field, ErrorMessage, Form } from 'formik'
import { Vendor } from '../../types/types'
import ModalComponent from '../../components/modal'
import { TextInput } from '../../components/inputs/textInput'
import { formatCurrency, formatNumber, getVendorName } from '../../helpers'
import { Checkbox } from '../../components/inputs/Checkbox'
import { NavLink, useNavigate } from 'react-router-dom'
import { Status } from '../../components/cards/statusTag'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { SearchInput } from '../../components/inputs/searchInput'
import { ChartCard } from '../../components/cards/ChartCard'
import BarChart from '../../components/charts/BarChart'
import { Avatar } from '../../components/avatar/Avatar'

const AllVendors: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState<string>()

  const { data: vendorMetrics } = useGetAllVendorsMetricsQuery(undefined)

  const { data, isFetching } = useGetAllVendorsQuery({
    skip: `${(page - 1) * NUMBER_OF_ITEMS_PER_PAGE}`,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: search && search !== '' ? search : undefined,
  })

  const [resendInvitation, { isLoading: resendingInvitation }] =
    useReInviteVendorMutation()

  return (
    <>
      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total Creators"
          count={vendorMetrics?.data.totalVendors ?? 0}
        />
        <CountCard
          isCurrency={false}
          text="Total Active Creators"
          count={vendorMetrics?.data.activeVendors ?? 0}
        />
        <CountCard
          isCurrency={true}
          text="Total Revenue"
          count={vendorMetrics?.data.totalRevenue ?? 0}
        />
      </CountCardContainer>

      <div className="mb-10">
        <ChartCard
          title="Top Creators by Revenue"
          value={undefined}
          chart={
            <BarChart
              responsive
              barThickness={24}
              height={150}
              labels={
                vendorMetrics?.data?.vendorMetrics
                  ?.map((metrics) => metrics.businessName ?? 'N/A')
                  ?.slice(0, 11) ?? []
              }
              data={
                vendorMetrics?.data?.vendorMetrics
                  ?.map((metrics) => metrics.totalRevenue)
                  ?.slice(0, 11) ?? []
              }
            />
          }
        />
      </div>

      <div className="mb-10 flex items-center gap-4 justify-between">
        <SearchInput
          placeholder="Search creator"
          searchFunction={(val) => {
            setSearch(val)
          }}
          className="!w-screen !max-w-[400px]"
        />

        <Button
          label="Invite creator"
          onClick={() => setOpenModal(true)}
          primary
          icon="plus"
          size="medium"
        />
      </div>

      <TableComponent
        headers={[
          'Business Name',
          'Category',
          'Total Orders Received',
          'Status',
          'Total Sales',
          'Actions',
        ]}
        rows={
          data?.data?.vendors?.map((vendor) => {
            return {
              id: vendor._id,
              content: [
                <NavLink
                  to={`/vendors/${vendor._id}`}
                  className={`flex gap-2 items-center`}
                  key={`vendor-${vendor._id}-name`}
                >
                  {vendor.logo ? (
                    <img
                      src={vendor.logo}
                      className="object-cover w-[40px] h-[40px] rounded-full overflow-hidden"
                      alt="Vendor Logo"
                    />
                  ) : (
                    <Avatar name={`${vendor?.businessName}`} useRandomBgColor />
                  )}
                  <span className="hover:underline ">
                    {vendor.businessName}
                  </span>
                </NavLink>,
                <p
                  key={`vendor-${vendor._id}-status`}
                  className="max-w-[150px] !line-clamp-1"
                >
                  {(vendor?.category ?? []).join(', ')}
                </p>,
                vendor?.totalOrders ?? '',
                <Status
                  text={vendor?.status ?? ''}
                  type={vendor?.status === 'active' ? 'success' : 'fail'}
                  key={`vendor-${vendor._id}-status`}
                />,
                formatCurrency(vendor?.totalSales ?? 0),
                <Dropdown
                  key={`${vendor._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left flex items-center justify-between"
                          onClick={(e): any => {
                            e.stopPropagation()
                            resendInvitation({ id: vendor._id })
                          }}
                          disabled={resendingInvitation}
                        >
                          Resend invitation
                          {resendingInvitation && (
                            <LoadingOval
                              loaderWidth="16"
                              loaderHeight="16"
                              color="black"
                            />
                          )}
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        name="vendors"
        loading={isFetching}
        isEmpty={!data?.data || data.data.vendors.length < 1}
        totalDataCount={data?.data.count}
        currentPage={page}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          setPage(page)
        }}
      />

      <InviteVendorModal
        open={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </>
  )
}

const InviteVendorModal: React.FC<{
  open: boolean
  closeModal: () => void
  vendor?: Vendor
}> = ({ open, closeModal, vendor }) => {
  const [inviteVendor, { isLoading: invitingVendor }] =
    useInviteVendorMutation()

  return (
    <ModalComponent
      title={'Invite Creator'}
      open={open}
      setOpen={() => {}}
      onCloseCallback={() => {
        closeModal()
      }}
    >
      <Formik
        initialValues={{
          name: vendor?.name ?? '',
          email: vendor?.email ?? '',
          splitPercentage: vendor?.splitPercentage ?? '',
          isSplitEnabled: vendor?.isSplitEnabled ?? false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          isSplitEnabled: Yup.boolean().required(),
          splitPercentage: Yup.number()
            .min(15)
            .max(30)
            .when('isSplitEnabled', (isSplitEnabled, schema) => {
              return isSplitEnabled[0] ? schema.required() : schema.optional()
            }),
        })}
        onSubmit={async (values) => {
          await inviteVendor({
            ...values,
            splitPercentage: values.isSplitEnabled
              ? (values.splitPercentage as number)
              : undefined,
          }).then((resp) => {
            if (resp.data?.success) {
              closeModal()
            }
          })
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form className="flex flex-col gap-4">
            <TextInput
              name="name"
              placeholder="Creator Name"
              type="text"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.name && touched.name ? errors.name : ''}
              hasIcon={false}
            />
            <TextInput
              name="email"
              placeholder="Email"
              type="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.email && touched.email ? errors.email : ''}
              hasIcon={false}
            />

            <Checkbox
              checkStatus={values.isSplitEnabled}
              text="Enable split percentage"
              handleChange={(status) => {
                setFieldValue('isSplitEnabled', status)
              }}
            />

            {values.isSplitEnabled && (
              <TextInput
                name="splitPercentage"
                placeholder="Split percentage (15 - 30)"
                type="splitPercentage"
                value={String(values.splitPercentage)}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.splitPercentage && touched.splitPercentage
                    ? errors.splitPercentage
                    : ''
                }
                hasIcon={false}
              />
            )}
            <Button
              disabled={invitingVendor}
              loading={invitingVendor}
              type="submit"
              label="Proceed"
              primary
              className="!mt-6 !w-full"
            />
          </Form>
        )}
      </Formik>
    </ModalComponent>
  )
}

export default AllVendors
