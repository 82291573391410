import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type AuthResponse } from '../user/user.type'
import Cookies from 'js-cookie'
import { COOKIE_KEY } from '../../constants'
import { RootState } from '../store'

type OrderState = {
  isNewOrderReceived: boolean
}
const initialState: OrderState = {
  isNewOrderReceived: false,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateNewOrderStatus: (
      state: OrderState,
      action: PayloadAction<boolean>,
    ) => {
      state.isNewOrderReceived = action.payload
    },
  },
})

export const { updateNewOrderStatus } = orderSlice.actions
export default orderSlice.reducer
export const orderSelector = (state: RootState): OrderState => state.order
