import React from 'react'
import Layout from '../../../layout'
import CuratedListsTable from './CuratedListsTable'
import { PageHeader } from '../../../components/typography/PageHeader'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/button'

export const CuratedListsPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <div className="flex justify-between gap-4 items-center mb-10">
        <PageHeader title="Everyday Lists" className="!mb-0" />

        <Button
          type="button"
          primary
          size="medium"
          onClick={() => {
            navigate('/lists/curated/new')
          }}
          label="Create list"
          icon="plus"
        />
      </div>

      <CuratedListsTable />
    </Layout>
  )
}
