import React, { useEffect } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  AddDeliveryLocation,
  CreateCategoryPayload,
  DeliveryTimeType,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddDeliveryLocationSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { ComboItem, DeliveryLocation } from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Icon from '../../icon'
import Dropdown from '../../inputs/dropdown'
import { useLazyFetchAvailableLocationsQuery } from '../../../redux-toolkits/user/user.slice'

const deliveryTypeOptions = [
  {
    name: 'Minutes',
    value: 'minutes',
  },
  {
    name: 'Days',
    value: 'days',
  },
]

const deliveryTimeOptions = [
  {
    name: '30 - 45 mins',
    value: {
      name: '30 - 45 mins',
      min: 30,
      max: 45,
      type: deliveryTypeOptions[0].value,
    },
  },
  {
    name: '45 mins - 1 hr',
    value: {
      name: '45 mins - 1 hr',
      min: 45,
      max: 60,
      type: deliveryTypeOptions[0].value,
    },
  },
  {
    name: 'Same day',
    value: {
      name: 'Same day',
      min: 1,
      max: 1,
      type: deliveryTypeOptions[1].value,
    },
  },
  {
    name: '1 - 2 days',
    value: {
      name: '1 - 2 days',
      min: 1,
      max: 2,
      type: deliveryTypeOptions[1].value,
    },
  },
  {
    name: '3 - 5 days',
    value: {
      name: '3 - 5 days',
      min: 3,
      max: 5,
      type: deliveryTypeOptions[1].value,
    },
  },
  {
    name: '5 - 7 days',
    value: {
      name: '5 - 7 days',
      min: 5,
      max: 7,
      type: deliveryTypeOptions[1].value,
    },
  },
  {
    name: 'Under 15 days',
    value: {
      name: 'Under 15 days',
      min: 1,
      max: 15,
      type: deliveryTypeOptions[1].value,
    },
  },
]

export const AddDeliveryLocationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: AddDeliveryLocation) => void
  mode: 'edit' | 'create'
  location?: AddDeliveryLocation
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  mode,
  location,
}) => {
  const [fetchLocations, { isFetching, data: locationsData }] =
    useLazyFetchAvailableLocationsQuery()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
    initialValues,
  } = useFormik({
    initialValues: location
      ? {
          locationId: location.locationId,
          locationName: location.locationName,
          minimumDeliveryTime: location.minimumDeliveryTime,
          maximumDeliveryTime: location.maximumDeliveryTime,
          type: location.type,
          deliveryTime: location.deliveryTime,
        }
      : {
          locationId: '',
          locationName: '',
          minimumDeliveryTime: 0,
          maximumDeliveryTime: 0,
          type: 'minutes',
          deliveryTime: '',
        },
    enableReinitialize: true,
    validationSchema: AddDeliveryLocationSchema,
    onSubmit: (values, { resetForm }) => {
      const itemToAdd: AddDeliveryLocation = {
        locationId: values.locationId,
        locationName: values.locationName,
        // minimumDeliveryTime: values.minimumDeliveryTime,
        // maximumDeliveryTime: values.maximumDeliveryTime,
        // type: values.type as DeliveryTimeType,
        deliveryTime: values.deliveryTime,
      }

      onSuccessCallback(itemToAdd)
      resetForm()
    },
  })

  useEffect(() => {
    open && fetchLocations({ skip: 0, limit: 1000 })
  }, [open, values])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new location' : 'Edit location'}
    >
      <div className="flex flex-col gap-4">
        <Dropdown
          loading={isFetching}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="type"
              placeholder="Delivers to"
              value={values.locationName}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.locationId && touched.locationId ? errors.locationId : ''
              }
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('locationId', selection.value)
            setFieldValue('locationName', selection.name)
          }}
          menuItems={locationsData?.data.docs.map((loc) => {
            return {
              name: loc.name,
              value: loc._id,
            }
          })}
        />

        <Dropdown
          loading={false}
          className=""
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="deliveryTime"
              placeholder="Delivers in"
              value={values.deliveryTime}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.deliveryTime && touched.deliveryTime
                  ? errors.deliveryTime
                  : ''
              }
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray"
            />
          }
          onClickMenuItem={(selection) => {
            const selectedValue = selection.value as {
              name: string
              min: number
              max: number
              type: string
            }
            setFieldValue('minimumDeliveryTime', selectedValue.min)
            setFieldValue('maximumDeliveryTime', selectedValue.max)
            setFieldValue('type', selectedValue.type)
            setFieldValue('deliveryTime', selection.name)
          }}
          menuItems={deliveryTimeOptions}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
          onClick={() => {
            handleSubmit()
          }}
        />
      </div>
    </ModalComponent>
  )
}
