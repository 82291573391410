import React, { useState } from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { ComboItem, CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'
import { Tag } from '../../redux-toolkits/products/products.type'
import { SelectTagsModal } from '../modal/app_modals/selectTagsModal'
import Icon from '../icon'
import { removeElementAtIndex } from '../../helpers'
import { Checkbox } from '../inputs/Checkbox'
import { AddComboItemModal } from '../modal/app_modals/addComboItemModal'

export const ManageProductCombos = ({
  values,
  setFieldValue,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
}) => {
  const [selectedComboItem, setSelectedComboItem] = useState<{
    index: number
    item: ComboItem
  }>()

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <div className="flex gap-4 items-start justify-between">
        <HeaderSubheader title="Product Combo" text="Specify combo products" />

        <Button
          primary
          icon="plus"
          size="medium"
          label="Add combo item"
          onClick={() => {
            handleModalOpen('addComboItemModal')
          }}
          className="!max-w-fit"
          disabled={!values.isCombo}
        />
      </div>

      <Checkbox
        checkStatus={values.isCombo}
        text="This a combo product"
        handleChange={(status) => {
          setFieldValue('isCombo', status)
        }}
      />

      {values.isCombo && (
        <>
          <div className="flex flex-row gap-4 items-center flex-wrap">
            {values.comboItems.map((item: ComboItem, idx) => {
              const key = `comboItem-${idx}`
              return (
                <div
                  key={key}
                  className="
                              flex gap-4 items-center 
                              border rounded-md
                              py-2 px-4
                              relative
                              hover:border-primary
                              transition-all durtation-300
                              "
                >
                  {item.required && (
                    <span
                      className="
                                  absolute -top-[6px] right-1 
                                  text-primary text-2xl
                                  !leading-none
                                  px-1 !py-0 !mb-0 bg-white
                                  block
                                  h-[14px]
                                  font-recoleta-bold
                                  "
                    >
                      *
                    </span>
                  )}

                  <button
                    type="button"
                    className="text-sm"
                    onClick={() => {
                      setSelectedComboItem({ index: idx, item })
                      handleModalOpen('addComboItemModal')
                    }}
                  >
                    {item.name}
                  </button>

                  <button
                    type="button"
                    className="
                                w-fit text-danger 
                                bg-danger/5 rounded-full p-2
                                "
                    onClick={() => {
                      const comboList: string[] = JSON.parse(
                        JSON.stringify(values.comboItems ?? []),
                      )
                      setFieldValue(
                        'comboItems',
                        removeElementAtIndex(comboList, idx),
                      )
                    }}
                  >
                    <Icon id="close-icon" width={10} height={10} />
                  </button>
                </div>
              )
            })}
          </div>
        </>
      )}

      <AddComboItemModal
        open={modalOpen && modal === 'addComboItemModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedComboItem(undefined)
        }}
        onSuccessCallback={(item) => {
          const combos: ComboItem[] = JSON.parse(
            JSON.stringify(values.comboItems ?? []),
          )
          if (selectedComboItem) {
            combos[selectedComboItem.index] = item
          } else {
            combos.push(item)
          }
          setFieldValue('comboItems', combos)
          setSelectedComboItem(undefined)
          handleModalClose()
        }}
        comboItem={selectedComboItem?.item}
        mode={selectedComboItem ? 'edit' : 'create'}
      />
    </div>
  )
}
