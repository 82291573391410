import React, { ChangeEvent, useState } from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { ComboItem, CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'
import {
  AddDeliveryLocation,
  Tag,
} from '../../redux-toolkits/products/products.type'
import { SelectTagsModal } from '../modal/app_modals/selectTagsModal'
import Icon from '../icon'
import { removeElementAtIndex } from '../../helpers'
import { Checkbox } from '../inputs/Checkbox'
import { AddComboItemModal } from '../modal/app_modals/addComboItemModal'
import { AddDeliveryLocationModal } from '../modal/app_modals/addDeliveryLocation'
import { AddNewCard } from '../cards/AddNewCard'
import { ImageUploadButton } from '../button/imageUploadButton'
import { ImageBanner } from '../images/imageBanner'

export const ManageProductImages = ({
  values,
  setFieldValue,
  handleFileChange,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <HeaderSubheader
        title="Product Images"
        text="Upload product  image(s)."
      />

      <div
        className="
        grid grid-flow-col !auto-cols-[minmax(300px,300px)]
        gap-4
        overflow-x-scroll
        mt-4
        "
      >
        {values.images.map((imageUrl, idx) => {
          const key = `productImage-${idx}`
          return (
            <ImageBanner
              key={key}
              className="!h-[300px] !w-[300px]"
              imageUrl={imageUrl}
              onDelete={() => {
                const imageList: string[] = JSON.parse(
                  JSON.stringify(values.images ?? []),
                )
                setFieldValue('images', removeElementAtIndex(imageList, idx))
              }}
            />
          )
        })}
        {values.images.length < 3 && (
          <ImageUploadButton
            showText
            handleChange={handleFileChange}
            className="!relative !rounded-lg h-[300px] !m-0 !top-0 !right-0"
            iconDim={64}
            icon="pic-icon"
          />
        )}
      </div>
    </div>
  )
}
