import { useFormik } from 'formik'
import React, { useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { formatCurrency, removeElementAtIndex } from '../../../helpers'
import Icon from '../../icon'
import { EmptyState } from '../../emptyState/emptyState'
import { SelectProductModal } from './selectProductModal'
import { Product } from '../../../types/types'
import { useAddCategoryToProductsMutation } from '../../../redux-toolkits/products/products.slice'

interface SelectItemsModalProps {
  open: boolean
  setOpen: (val: boolean) => void
  categoryId?: string
  subcategoryId?: string
}

const AddProductCategoryModal: React.FC<SelectItemsModalProps> = ({
  open,
  setOpen,
  categoryId,
  subcategoryId,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [addCategory, { isLoading: creating }] =
    useAddCategoryToProductsMutation()

  const formik = useFormik({
    initialValues: {
      items: [] as Product[],
    },
    onSubmit: async (values) => {
      if (!values.items.length) return

      const response = await addCategory({
        categoryId,
        subcategoryId,
        productIds: values.items.map((p) => p._id),
      }).unwrap()
    },
  })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      title="Select Items"
      position="right"
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-end">
          <Button
            label="Select items"
            type="button"
            className="!w-fit"
            onClick={() => setModalOpen(true)}
            size="small"
            icon="plus"
          />
        </div>

        <div className="flex flex-col overflow-y-scroll min-h-[300px] p-4">
          {formik.values.items.length > 0 ? (
            formik.values.items.map((product, idx) => (
              <div
                key={product._id}
                className="flex gap-2 items-center border-b border-divider-gray py-2 first:pt-0"
              >
                <img
                  alt={product.name}
                  src={product.images[0]}
                  className="w-[50px] h-[50px] object-cover rounded-md overflow-hidden border"
                />
                <div className="flex-1">
                  <span>{product.name}</span>
                </div>
                <div className="text-sm text-sec-black">
                  {formatCurrency(product.price)}
                </div>
                <button
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      'items',
                      removeElementAtIndex([...formik.values.items], idx),
                    )
                  }
                  className="text-red-500"
                >
                  <Icon
                    className="text-danger"
                    width={16}
                    height={16}
                    id="bin"
                  />
                </button>
              </div>
            ))
          ) : (
            <EmptyState text="No products selected" />
          )}
        </div>

        <Button
          label="Proceed"
          type="submit"
          className="mt-10 w-full"
          disabled={creating || formik.values.items.length === 0}
          loading={creating}
          primary
        />

        {modalOpen && (
          <SelectProductModal
            open={modalOpen}
            setOpen={setModalOpen}
            onCloseCallback={() => setModalOpen(false)}
            onSuccessCallback={(product: Product) => {
              if (!formik.values.items.some((p) => p._id === product._id)) {
                formik.setFieldValue('items', [...formik.values.items, product])
              }
            }}
          />
        )}
      </form>
    </ModalComponent>
  )
}

export default AddProductCategoryModal
