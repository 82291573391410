import React, { useState } from 'react'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import Layout from '../../layout'
import Icon from '../../components/icon'
import { useModalControl } from '../../hooks/useModalControl'
import { ViewFeaturedItemModal } from '../../components/modal/app_modals/viewFeaturedItemModal'
import { FeaturedItemTypes } from '../../types/types'
import { CreatedDiscoverItem } from '../../redux-toolkits/discover/discover.types'
import { useGetDiscoverCardsQuery } from '../../redux-toolkits/discover/discover.slice'
import Spinner from '../../components/spinner/Spinner'
import { PageHeader } from '../../components/typography/PageHeader'

const DiscoverCard = ({
  title,
  feature,
  onClick,
}: {
  title: string
  feature?: CreatedDiscoverItem
  onClick?: (feat?: CreatedDiscoverItem) => void
}) => {
  const empty = !feature
  return (
    <button
      className="flex flex-col gap-2 min-w-full"
      onClick={() => {
        onClick && onClick(feature)
      }}
    >
      <span
        className="
        text-sm font-normal
        block
        "
      >
        {title}
      </span>
      <div
        style={{
          background: feature?.image ? `url('${feature.image}')` : '#F7F7F7',
        }}
        className={`
            !bg-cover
            text-left
            flex flex-col
            justify-between
            w-full
            h-[300px] border
            rounded-lg
            hover:scale-[0.98]
            cursor-pointer
            p-4
            transition-all duration-300
            ${empty && '!items-center !justify-center h-full'}
        `}
      >
        {empty && <Icon id="add-icon-2" />}

        {feature && (
          <>
            <span className="font-normal">{feature.title}</span>

            <span className="text-xs block max-w-[150px]">
              {feature.description}
            </span>
          </>
        )}
      </div>
    </button>
  )
}

export const DiscoverPage = () => {
  const [featureType, setFeatureType] = useState<FeaturedItemTypes>('Product')
  const [selectedFeature, setSelectedFeature] = useState<CreatedDiscoverItem>()

  const { modal, modalOpen, handleModalOpen, setModalOpen, handleModalClose } =
    useModalControl()

  const { data, isLoading, refetch } = useGetDiscoverCardsQuery()

  return (
    <Layout>
      <PageHeader title="Discover" className="mb-10" />

      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <div
          className="
        mt-10 grid grid-cols-4
        gap-2
        "
        >
          <DiscoverCard
            title="Featured Product"
            onClick={(seleted) => {
              setSelectedFeature(seleted)
              setFeatureType('Product')
              handleModalOpen('viewFeaturedItemModal')
            }}
            feature={data?.data.find(({ type }) => type === 'Product')}
          />
          <DiscoverCard
            title="Featured Everyday List"
            onClick={(seleted) => {
              setSelectedFeature(seleted)
              setFeatureType('List')
              handleModalOpen('viewFeaturedItemModal')
            }}
            feature={data?.data.find(({ type }) => type === 'List')}
          />
          <DiscoverCard
            title="Featured Listicle"
            onClick={(seleted) => {
              setSelectedFeature(seleted)
              setFeatureType('Article')
              handleModalOpen('viewFeaturedItemModal')
            }}
            feature={data?.data.find(({ type }) => type === 'Article')}
          />
          <DiscoverCard
            title="Featured Event"
            onClick={(seleted) => {
              setSelectedFeature(seleted)
              setFeatureType('Event')
              handleModalOpen('viewFeaturedItemModal')
            }}
            feature={data?.data.find(({ type }) => type === 'Event')}
          />
        </div>
      )}

      <ViewFeaturedItemModal
        open={modalOpen && modal === 'viewFeaturedItemModal'}
        setOpen={setModalOpen}
        type={featureType}
        mode="create"
        feature={selectedFeature}
        successCallback={() => {
          handleModalClose()
          refetch()
        }}
      />
    </Layout>
  )
}
