import React from 'react'
import Icon from '../../components/icon'
import { formatCurrency, formatNumber } from '../../helpers'

interface CountCardProps {
  count: number
  text: string
  icon?: string
  isCurrency: boolean
}

const CountCard: React.FC<CountCardProps> = ({
  count,
  text,
  icon,
  isCurrency,
}) => {
  return (
    <div
      className="
      bg-transparent
      p-6 rounded-2xl flex items-center 
      justify-between
      border border-divider-gray
      "
    >
      <div className="space-y-2">
        <p
          className="
          text-base capitalize 
          text-sec-black
          "
        >
          {text}
        </p>
        <p className="text-2xl font-bold">
          {isCurrency ? formatCurrency(count) : formatNumber(count)}
        </p>
      </div>

      {icon !== undefined && (
        <div className="text-gray-200">
          <Icon id={icon} height={35} width={35} />
        </div>
      )}
    </div>
  )
}

export default CountCard
