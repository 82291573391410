import React, { useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategoryPayload } from '../../../redux-toolkits/products/products.type'
import {
  addLocationManualSchema,
  addPickUpLocationManualSchema,
  CreateCategorySchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import AddressInput from '../../inputs/addressInput'
import {
  useAddAvailableLocationsMutation,
  useAddPickupLocationMutation,
} from '../../../redux-toolkits/user/user.slice'
import { AddAvailableLocationsPayload } from '../../../redux-toolkits/user/user.type'
import { TabNav } from '../../nav/tabNav'
import { ObjectSchema } from 'yup'

type LocationData = {
  name: string
  city: string
  state: string
  long: string
  lat: string
  address: string
}

export const AddLocationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (data: LocationData, reset: () => void) => void
  title: string
  fields: ('name' | 'address' | 'city' | 'state' | 'coordinates')[]
  schema: ObjectSchema<any>
  loading: boolean
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  title,
  fields,
  schema,
  loading,
}) => {
  const [selectedLocation, setSelectedLocation] =
    useState<AddAvailableLocationsPayload>()

  const [activeTab, setActiveTab] = useState('Search')

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
      city: '',
      state: '',
      long: '',
      lat: '',
      address: '',
    },
    validationSchema: schema,
    onSubmit: (vals, { resetForm }) => {
      onSuccessCallback(vals, resetForm)
    },
  })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={title}
    >
      <TabNav
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          { name: 'Location search', id: 'Search' },
          { name: 'Manual', id: 'Manual' },
        ]}
        className="mb-10"
      />

      {activeTab === 'Search' && (
        <>
          <AddressInput
            onSelect={(selected) => {
              setSelectedLocation({
                isAvailable: true,
                city: selected.city,
                state: selected.state,
                name: selected.address,
                country: selected.country,
                coordinates: [selected.lng, selected.lat],
              })
            }}
            className=""
          />

          <Button
            label="Proceed"
            type="button"
            className="mt-20 w-full"
            disabled={loading || !selectedLocation}
            loading={loading}
            primary
          />
        </>
      )}

      {activeTab === 'Manual' && (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <TextInput
            type="text"
            name="name"
            placeholder="Location name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.name && touched.name ? errors.name : ''}
            hasIcon={false}
            className="!border-divider-gray"
          />

          {fields.includes('address') && (
            <TextInput
              type="text"
              name="address"
              placeholder="Location address"
              value={values.address}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.address && touched.address ? errors.address : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {fields.includes('city') && (
            <TextInput
              type="text"
              name="city"
              placeholder="City"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.city && touched.city ? errors.city : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {fields.includes('state') && (
            <TextInput
              type="text"
              name="state"
              placeholder="State"
              value={values.state}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.state && touched.state ? errors.state : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />
          )}

          {fields.includes('coordinates') && (
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                type="text"
                name="long"
                placeholder="Longitude"
                value={values.long}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.long && touched.long ? errors.long : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />

              <TextInput
                type="text"
                name="lat"
                placeholder="Latitude"
                value={values.lat}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.lat && touched.lat ? errors.lat : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />
            </div>
          )}

          <Button
            label="Proceed"
            type="submit"
            className="mt-10 w-full"
            disabled={loading || !isValid}
            loading={loading}
            primary
          />
        </form>
      )}
    </ModalComponent>
  )
}
