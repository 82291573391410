import { useFormik } from 'formik'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Icon from '../../../components/icon'
import { Sections } from '../../../types/types'
import { ArticleSectionLiveView } from './components/articleSectionLiveView'
import { ArticleSectionEditView } from './components/articleSectionEditView'
import { ImageBanner } from '../../../components/images/imageBanner'
import { ImageUploadPreviewModal } from '../../../components/modal/app_modals/ImagePreviewModal'
import Layout from '../../../layout'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreateListArticleMutation,
  useEditListArticleMutation,
  useLazyFetchSingleListArticleQuery,
} from '../../../redux-toolkits/lists/list.slice'
import Spinner from '../../../components/spinner/Spinner'
import { Button } from '../../../components/button'
import { PageHeader } from '../../../components/typography/PageHeader'
import { ImageUploadButton } from '../../../components/button/imageUploadButton'
import { TextInput } from '../../../components/inputs/textInput'
import { SelectLocationModal } from '../../../components/modal/app_modals/selectLocationModal'
import { SingleLocation } from '../../../redux-toolkits/user/user.type'
import { removeElementAtIndex } from '../../../helpers'
import { useModalControl } from '../../../hooks/useModalControl'
import { SelectTagsModal } from '../../../components/modal/app_modals/selectTagsModal'
import { HeaderSubheader } from '../../../components/typography/HeaderSubheader'
import { Tag } from '../../../redux-toolkits/products/products.type'

export const SingleListArticle = ({
  mode = 'create',
}: {
  mode?: 'create' | 'edit'
}) => {
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [sections, setSections] = useState<Sections>([])
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(
    null,
  )

  // const [modal, setModal] = useState<string>('')
  // const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()
  const [locations, setLocations] = useState<SingleLocation[]>([])
  const params = useParams<{ id: string }>()

  const navigate = useNavigate()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const {
    touched,
    errors,
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: '',
      description: '',
      bannerUrl: '',
      tags: [],
    },
    onSubmit: (values) => {
      const payload = {
        ...values,
        location: locations.map((loc) => loc._id),
        article: sections.map((section) => {
          return {
            ...section,
            content: section.content.map((content) => {
              return {
                ...content,
                products: content.products.map((product) => {
                  return product._id ?? product
                }),
              }
            }),
          }
        }),
      }

      mode === 'create' &&
        createListArticle(payload)?.then((resp) => {
          navigate(`/lists/articles/view/${resp.data?.data.article._id}`)
        })

      mode === 'edit' && editListArticle({ id: params.id ?? '', data: payload })
    },
  })

  const [fetchSingleListArticle, { data: singleArticleData, isFetching }] =
    useLazyFetchSingleListArticleQuery()

  const [createListArticle, { isLoading: isCreating }] =
    useCreateListArticleMutation()

  const [editListArticle, { isLoading: isEditing }] =
    useEditListArticleMutation()

  useEffect(() => {
    if (mode === 'edit' && params?.id) {
      fetchSingleListArticle({ id: params.id })
    }
  }, [params])

  useEffect(() => {
    if (singleArticleData) {
      setFieldValue('title', singleArticleData?.data.article.title)
      setFieldValue('description', singleArticleData?.data.article.description)
      setFieldValue('bannerUrl', singleArticleData?.data.article.bannerUrl)
      setFieldValue('tags', singleArticleData?.data.tags)
      setSections(singleArticleData?.data.article.article)
      setLocations(singleArticleData.data.location ?? [])
    }
  }, [singleArticleData])

  return (
    <Layout>
      {mode === 'edit' && isFetching ? (
        <div>
          <Spinner fullScreen={false} />
        </div>
      ) : (
        <div>
          <PageHeader
            title={`${mode === 'create' ? 'Create' : 'Edit'} Article`}
          />
          <form onSubmit={handleSubmit}>
            <div className="flex justify-end">
              <Button
                label="Save"
                type="submit"
                className="mb-12 !px-10"
                loading={isCreating || isEditing}
                disabled={isCreating || isEditing}
                primary
              />
            </div>

            {/* Banner  */}
            <div className="relative">
              <ImageBanner imageUrl={values.bannerUrl} loading={isFetching} />

              <ImageUploadButton handleChange={handleFileChange} />
            </div>

            <div className="mt-10 flex flex-col gap-4">
              {/* Title  */}
              <TextInput
                type="text"
                name="title"
                onChange={handleChange}
                value={values.title}
                onBlur={handleBlur}
                errors={errors.title && touched.title ? errors.title : ''}
                inputClass="!text-3xl font-recoleta-bold"
                className="!border-divider-gray"
                placeholder="Title"
                hasIcon={false}
              />

              {/* Description  */}
              <TextInput
                type="textarea"
                name="description"
                onChange={handleChange}
                value={values.description}
                onBlur={handleBlur}
                errors={
                  errors.description && touched.description
                    ? errors.description
                    : ''
                }
                inputClass="!text-lg !text-sec-black"
                className="!border-divider-gray"
                hasIcon={false}
                placeholder="Description"
                rows={6}
              />

              <div className="flex flex-col gap-2">
                <p className="font-medium">Available in:</p>
                <div className="flex flex-wrap gap-4 items-center">
                  {locations.map((loc, idx) => {
                    return (
                      <div
                        key={loc._id}
                        className="
                        flex py-2 px-3 text-xs gap-4
                        border border-sec-black
                        text-sec-black
                        rounded-full
                        font-medium
                        "
                      >
                        {loc.name}
                        <button
                          className="
                        text-danger
                        "
                          onClick={() => {
                            const locationsCopy: SingleLocation[] = JSON.parse(
                              JSON.stringify(locations),
                            )
                            setLocations(
                              removeElementAtIndex(locationsCopy, idx),
                            )
                          }}
                        >
                          <Icon id="close-icon" width={12} height={12} />
                        </button>
                      </div>
                    )
                  })}
                  <Button
                    type="button"
                    label=""
                    icon="plus"
                    size="medium"
                    onClick={() => {
                      handleModalOpen('selectLocationModal')
                    }}
                    className="!max-w-fit !px-1 !py-1 !rounded-full"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="font-medium">Tags:</p>

                <div className="flex flex-row gap-4 items-center flex-wrap">
                  {values.tags.map((tag: Tag, idx) => {
                    const key = `tag-${idx}`
                    return (
                      <div
                        key={key}
                        className="
                          text-sm rounded-md
                          py-2 px-4
                          bg-gray-bg
                          "
                      >
                        {tag.name}
                      </div>
                    )
                  })}

                  <Button
                    icon="plus"
                    size="medium"
                    label=""
                    onClick={() => {
                      handleModalOpen('selectTagsModal')
                    }}
                    className="!max-w-fit !px-1 !py-1 !rounded-full"
                  />
                </div>
              </div>
            </div>

            {/* Article  sections */}
            <div
              className="
              w-full mt-10
              flex flex-col gap-6
              "
            >
              {sections?.map((section, idx) => {
                const key = `article-section-${idx}`

                return activeSectionIndex === idx ? (
                  <ArticleSectionEditView
                    section={section}
                    setActiveSectionIndex={setActiveSectionIndex}
                    sections={sections}
                    setSections={setSections}
                    sectionIndex={idx}
                    key={key}
                  />
                ) : (
                  <ArticleSectionLiveView
                    section={section}
                    sections={sections}
                    setSections={setSections}
                    setActiveSectionIndex={setActiveSectionIndex}
                    sectionIndex={idx}
                    isAnyOtherSectionActive={
                      activeSectionIndex !== null && activeSectionIndex !== idx
                    }
                    products={singleArticleData?.data.products}
                    key={key}
                  />
                )
              })}
            </div>

            {/* Add new article section  */}
            <div className="mt-4">
              <Button
                type="button"
                onClick={() => {
                  const newSections = [...sections, { content: [] }]
                  if (sections.length == 0) {
                    setSections(newSections)
                    setActiveSectionIndex(newSections.length - 1)
                  }
                  if (
                    sections.length > 0 &&
                    sections[sections.length - 1].content.length > 0
                  ) {
                    // Add blank section to section list
                    setSections(newSections)
                    // Set newly added section as active section
                    setActiveSectionIndex(newSections.length - 1)
                  }
                }}
                label="New section"
                icon="plus"
                size="medium"
                disabled={
                  sections.length === 1 && sections[0].content.length === 0
                }
              />
            </div>
          </form>

          <SelectTagsModal
            open={modalOpen && modal === 'selectTagsModal'}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              handleModalClose()
            }}
            onSuccessCallback={(tags) => {
              setFieldValue('tags', tags)
              handleModalClose()
            }}
            selectedTags={values.tags}
          />

          {uploadedImage && (
            <ImageUploadPreviewModal
              image={uploadedImage}
              open={modal === 'imageUploadPreviewModal' && modalOpen}
              setOpen={setModalOpen}
              onCloseCallback={() => {
                setUploadedImage(undefined)
              }}
              onSuccessCallback={(url) => {
                setFieldValue('bannerUrl', url)
              }}
            />
          )}

          <SelectLocationModal
            open={modal === 'selectLocationModal' && modalOpen}
            setOpen={setModalOpen}
            onCloseCallback={() => {}}
            onSuccessCallback={(location) => {
              const locationIds = locations.map((loc) => loc._id)
              if (!locationIds.includes(location._id)) {
                setLocations([...locations, location])
                handleModalClose()
              }
            }}
          />
        </div>
      )}
    </Layout>
  )
}
