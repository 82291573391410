import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import Spinner from '../../components/spinner/Spinner'
import {
  useGetOrdersQuery,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useGetHarmonizedOrdersQuery,
  useGetOrderQuery,
  useGetHarmonizedOrdersBreakdownQuery,
} from '../../redux-toolkits/orders/orders.slice'
import OrderTable from './OrderTable'
import { NUMBER_OF_ITEMS_PER_PAGE, ORDERSTATUS } from '../../constants'
import CountCard from './CountCard'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { PageHeader } from '../../components/typography/PageHeader'
import { TextInput } from '../../components/inputs/textInput'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { updateNewOrderStatus } from '../../redux-toolkits/orders/order.reducer'
import { useAppDispatch } from '../../hooks'
import { SearchInput } from '../../components/inputs/searchInput'
import { TableComponent } from '../../components/table'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { formatCurrency } from '../../helpers'
import { Status } from '../../components/cards/statusTag'
import { format } from 'date-fns'
import { StatusTypes } from '../../types/types'
import { HarmonizedOrdersModal } from '../../components/modal/app_modals/harmonizedOrdersModal'
import { useModalControl } from '../../hooks/useModalControl'
import { SingleHarmonizedOrder } from '../../redux-toolkits/orders/orders.type'

export const OrdersPage: React.FC = () => {
  const navigate = useNavigate()

  const [singleHarmonized, setSingleHarmonized] =
    useState<SingleHarmonizedOrder>()

  const [searchParams, setSearchParams] = useSearchParams()

  const { handleModalClose, handleModalOpen, modalOpen, setModalOpen, modal } =
    useModalControl()

  const { data: counts, isFetching: fetchingCountsMetrics } =
    useGetOrdersMetricsCountQuery({
      startDate: searchParams.get('startDate') ?? undefined,
      endDate: searchParams.get('endDate') ?? undefined,
    })

  const { data: values, isFetching: fetchingValuesMetrics } =
    useGetOrdersMetricsValueQuery({
      startDate: searchParams.get('startdate') ?? undefined,
      endDate: searchParams.get('endDate') ?? undefined,
    })

  const {
    data: payload,
    isFetching,
    isError,
    isSuccess,
  } = useGetHarmonizedOrdersQuery({
    page: Number(searchParams.get('page') ?? 1),
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    status: [null, 'all', ''].includes(searchParams.get('status'))
      ? undefined
      : searchParams.get('status')!,
    startDate: searchParams.get('startDate') ?? undefined,
    endDate: searchParams.get('endDate') ?? undefined,
    search: [null, 'all', ''].includes(searchParams.get('search'))
      ? undefined
      : searchParams.get('search')!,
  })

  const value = values?.data
  const count = counts?.data

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isFetching && isSuccess) {
      dispatch(updateNewOrderStatus(false))
    }
  }, [isFetching, isSuccess])

  if (isError) {
    return <p>Oops! something went wrong.</p>
  }

  return (
    <Layout>
      {fetchingCountsMetrics || fetchingValuesMetrics ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader title={`Orders`} className="capitalize" />

          <div className="flex items-center justify-end gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">Start Date:</p>
              <div>
                <TextInput
                  placeholder="Start date"
                  type="date"
                  name="startDate"
                  value={searchParams.get('startDate') ?? undefined}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      startDate: e.target.value,
                    })
                  }}
                  hasIcon={false}
                  readOnly={false}
                  className="!border-black !w-full md:max-w-fit !py-3"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-sm text-sec-black">End Date:</p>
              <div>
                <TextInput
                  placeholder="End date"
                  type="date"
                  name="endDate"
                  value={searchParams.get('endDate') ?? undefined}
                  onChange={(e) => {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      endDate: e.target.value,
                    })
                  }}
                  hasIcon={false}
                  readOnly={['', null, undefined].includes(
                    searchParams.get('startDate'),
                  )}
                  className="!border-black !w-full md:max-w-fit !py-3"
                />
              </div>
            </div>
          </div>

          <CountCardContainer>
            <CountCard
              count={value?.totalOrderValue ?? 0}
              text="Total order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.processingOrderValue ?? 0}
              text="Processing order"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={value?.completedOrderValue ?? 0}
              text="Completed order"
              icon=""
              isCurrency={true}
            />
            {/* <CountCard
              count={value?.pendingOrderValue ?? 0}
              text="Pending order"
              icon=""
              isCurrency={true}
            /> */}
            {/* <CountCard
              count={value?.cancelledOrderValue ?? 0}
              text="Cancelled order"
              icon=""
              isCurrency={true}
            /> */}
            <CountCard
              count={count?.totalOrderCount ?? 0}
              text="Total order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.processingOrderCount ?? 0}
              text="Processing order count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={count?.completedOrderCount ?? 0}
              text="Completed order count"
              icon=""
              isCurrency={false}
            />
            {/* <CountCard
              count={count?.pendingOrderCount ?? 0}
              text="Pending order count"
              icon=""
              isCurrency={false}
            /> */}
            {/* <CountCard
              count={count?.cancelledOrderCount ?? 0}
              text="Cancelled order count"
              icon=""
              isCurrency={false}
            /> */}
          </CountCardContainer>

          <div className="flex flex-wrap items-center justify-between gap-4 mb-10">
            <div className="!w-full md:!max-w-[400px]">
              <SearchInput
                searchFunction={(search) => {
                  if (search !== undefined) {
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      page: '1',
                      search: search,
                    })
                  }
                }}
                placeholder="Search reference"
              />
            </div>

            <Dropdown
              menuClassName=""
              menuButton={
                <Button
                  label={`Filter: ${(searchParams.get('status') ?? 'all')
                    .split('_')
                    .join(' ')
                    .charAt(0)}${(searchParams.get('status') ?? 'all')
                    .split('_')
                    .join(' ')
                    .slice(1)
                    .toLowerCase()}`}
                  primary={false}
                  icon="filter"
                  className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                />
              }
              onClickMenuItem={(selected) => {
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  status: selected.value as string,
                  page: '1',
                })
              }}
              menuItems={[
                { name: 'All', value: 'all' },
                ...ORDERSTATUS.map((status) => {
                  const name = status.name.split('_').join(' ')
                  return {
                    name: `${name.charAt(0)}${name.slice(1).toLowerCase()}`,
                    value: status.name,
                  }
                }),
              ]}
            />
          </div>

          <TableComponent
            name={''}
            showName={false}
            headers={['Harmonized Reference', 'Amount', 'Date', 'Status']}
            rows={(payload?.data?.orders ?? []).map((order) => {
              return {
                id: order.harmonizedReference,
                content: [
                  <div key={`${order.harmonizedReference}-product-id`}>
                    <button
                      className="
                      hover:text-black hover:underline
                      transition-all duration-500
                      w-fit
                  "
                      onClick={() => {
                        navigate(`/orders/${order.harmonizedReference}`)
                      }}
                    >
                      #{order.harmonizedReference}
                    </button>

                    <p className="text-xs">{order.completionSummary}</p>
                  </div>,
                  `${formatCurrency(order.totalAmount)}`,
                  `${format(order.createdAt, 'dd/MM/yyyy h:mma')}`,
                  <div
                    key={`${order.harmonizedReference}-status`}
                    className="flex items-center gap-2 overflow-visible"
                  >
                    <Status
                      text={order.orderStatus.toLowerCase()}
                      type={
                        (ORDERSTATUS.find(
                          (status) =>
                            status.name.toLowerCase() ===
                            order.orderStatus.toLowerCase(),
                        )?.type ?? 'warn') as StatusTypes
                      }
                    />
                  </div>,
                ],
              }
            })}
            isEmpty={[undefined, 0].includes(payload?.data?.orders?.length)}
            loading={isFetching}
            currentPage={Number(searchParams.get('page') ?? 1)}
            totalDataCount={payload?.data?.total ?? 0}
            pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
            onPageChange={(page) => {
              setSearchParams({
                ...Object.fromEntries(searchParams),
                page: String(page),
              })
            }}
          />
        </>
      )}

      {singleHarmonized && modalOpen && (
        <HarmonizedOrdersModal
          order={singleHarmonized}
          open={modalOpen && modal === 'viewHarmonizedOrdersModal'}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            handleModalClose()
            setSingleHarmonized(undefined)
          }}
        />
      )}
    </Layout>
  )
}
