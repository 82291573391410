import React, { useState } from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import { ComboItem, CreateProductValues } from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'
import {
  AddDeliveryLocation,
  Tag,
} from '../../redux-toolkits/products/products.type'
import { SelectTagsModal } from '../modal/app_modals/selectTagsModal'
import Icon from '../icon'
import { removeElementAtIndex } from '../../helpers'
import { Checkbox } from '../inputs/Checkbox'
import { AddComboItemModal } from '../modal/app_modals/addComboItemModal'
import { AddDeliveryLocationModal } from '../modal/app_modals/addDeliveryLocation'
import { ErrorText } from '../typography/ErrorText'

export const ManageProductLocations = ({
  values,
  setFieldValue,
  errors,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
  errors?: string
}) => {
  const [selectedLocation, setSelectedLocation] = useState<{
    index: number
    item: AddDeliveryLocation
  }>()

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <div className="flex gap-4 items-start justify-between">
        <div className="flex items-center gap-4">
          <HeaderSubheader
            title="Available Locations"
            text="Specify locations this product can be delivered to"
          />

          {errors && <ErrorText text={`(${errors})`} />}
        </div>

        <Button
          primary
          icon="plus"
          size="medium"
          label="Manage locations"
          onClick={() => {
            handleModalOpen('addDeliveryLocationModal')
          }}
          className="!max-w-fit"
        />
      </div>

      <div className="flex flex-row gap-4 items-center flex-wrap">
        {values.deliveryLocation.map((item: AddDeliveryLocation, idx) => {
          const key = `location-${idx}`
          return (
            <div
              key={key}
              className="
                          flex gap-4 items-center 
                          border rounded-md
                          py-2 px-4
                          relative
                          hover:border-primary
                          transition-all durtation-300
                          "
            >
              <button
                type="button"
                className="text-sm"
                onClick={() => {
                  setSelectedLocation({ index: idx, item })
                  handleModalOpen('addDeliveryLocationModal')
                }}
              >
                Delivers to {item.locationName} in{' '}
                <span className="font-medium">{item.deliveryTime}</span>
              </button>

              <button
                type="button"
                className="
                            w-fit text-danger 
                            bg-danger/5 rounded-full p-2
                            "
                onClick={() => {
                  const locationList: string[] = JSON.parse(
                    JSON.stringify(values.deliveryLocation ?? []),
                  )
                  setFieldValue(
                    'deliveryLocation',
                    removeElementAtIndex(locationList, idx),
                  )
                }}
              >
                <Icon id="close-icon" width={10} height={10} />
              </button>
            </div>
          )
        })}
      </div>

      <AddDeliveryLocationModal
        open={modalOpen && modal === 'addDeliveryLocationModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedLocation(undefined)
        }}
        onSuccessCallback={(item) => {
          const locations: AddDeliveryLocation[] = JSON.parse(
            JSON.stringify(values.deliveryLocation ?? []),
          )
          if (selectedLocation) {
            locations[selectedLocation.index] = item
          } else {
            locations.push(item)
          }
          setFieldValue('deliveryLocation', locations)
          setSelectedLocation(undefined)
          handleModalClose()
        }}
        location={selectedLocation?.item}
        mode={selectedLocation ? 'edit' : 'create'}
      />
    </div>
  )
}
