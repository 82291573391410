import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategoryPayload } from '../../../redux-toolkits/products/products.type'
import {
  CreateCategorySchema,
  CreateVariantSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateVariantMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { SingleVariant } from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'

export const CreateVariantModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  variant?: SingleVariant
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  variant,
}) => {
  const [createVariant, { isLoading: creating }] = useCreateVariantMutation()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: variant?.name ?? '',
      isSubVariant: variant?.isSubVariant ?? false,
    },
    enableReinitialize: true,
    validationSchema: CreateVariantSchema,
    onSubmit: (values) => {
      if (mode === 'create') {
        createVariant(values).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && variant) {
        console.log(variant)
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new variant' : 'Edit variant'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="name"
          placeholder="Variant name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
          className="!border-divider-gray"
        />

        {mode === 'create' && (
          <Checkbox
            checkStatus={values.isSubVariant}
            text="This has sub-variants"
            handleChange={(status) => {
              setFieldValue('isSubVariant', status)
            }}
          />
        )}

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating}
          loading={creating}
          primary
        />
      </form>
    </ModalComponent>
  )
}
