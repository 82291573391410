import moment from 'moment'
import { ErrorType } from '../redux-toolkits/user/user.type'
import { Product, Vendor } from '../types/types'

const formatCurrency = (amount: string | number): string => {
  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
  }).format(Number(amount))
}

const formatNumber = (count: number): string => {
  return new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: 3,
  }).format(count)
}

const getErrorMessage = (error: ErrorType) => {
  return error.error?.data?.error ?? 'Something went wrong, try again later.'
}

export { formatCurrency, formatNumber, getErrorMessage }

export const removeElementAtIndex = (array: any[], index: number) => {
  // Check if the index is within bounds
  if (index >= 0 && index < array.length) {
    array.splice(index, 1)
  }
  return array
}

export const prettyFormatNumber = (num: number) => {
  if (num === null || num === undefined) return null

  const absNum = Math.abs(num)
  if (absNum < 1000) return num

  const units = ['K', 'M', 'B', 'T']
  const index = Math.floor(Math.log10(absNum) / 3) // Find the appropriate index
  const unit = units[index - 1] || '' // Get the unit suffix

  // Format the number and append the unit
  return `${(num / Math.pow(1000, index)).toFixed(1)}${unit}`
}

export const getVendorName = (vendor: Vendor) => {
  return (
    vendor?.businessInformation?.businessName ??
    vendor?.name ??
    (vendor?.firstName && vendor?.lastName
      ? `${vendor?.firstName} ${vendor?.lastName}`
      : undefined) ??
    vendor?.email ??
    'N/A'
  )
}

export const getTimeAgo = (timeString: string): string => {
  const time = new Date(timeString).getTime()
  const now = Date.now()
  const secondsAgo = Math.floor((now - time) / 1000)

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`
  }

  const minutesAgo = Math.floor(secondsAgo / 60)
  if (minutesAgo < 60) {
    return `${minutesAgo} minutes ago`
  }

  const hoursAgo = Math.floor(minutesAgo / 60)
  if (hoursAgo < 24) {
    return `${hoursAgo} hours ago`
  }

  const daysAgo = Math.floor(hoursAgo / 24)
  return `${daysAgo} days ago`
}

export const convertSize = (size: number) => {
  if (size > 1048576) {
    return `${Math.ceil(size / 1048576)} MB`
  }
  return `${Math.ceil(size / 1024)} KB`
}

export const calculateFromPrice = (product: Product) => {
  if (!product?.comboItems || product.comboItems.length === 0) {
    return formatCurrency(product?.price)
  }

  const totalPrice = product.comboItems.reduce((acc, item) => {
    return acc + item.price * item.minimumQuantity
  }, 0)

  return `From: ${formatCurrency(totalPrice)}`
}

export const reorderProducts = (
  list: Product[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const getMarkUpValue = (percent: number, amount: number) => {
  return Number((percent / 100) * amount)
}

export const formatTime = (
  time: string | Date | moment.Moment | undefined | null,
  format: string = 'hh:mm A',
): string => {
  try {
    if (!time) {
      return ''
    }
    const momentTime = moment(time)
    if (!momentTime.isValid()) {
      throw new Error('Invalid time')
    }
    return momentTime.format(format)
  } catch (error) {
    return 'Invalid time'
  }
}

export const parseTimeToDate = (time: string): Date | null => {
  try {
    const momentTime = moment(time, 'hh:mm A')
    if (!momentTime.isValid()) {
      throw new Error('Invalid time format')
    }
    return momentTime.toDate()
  } catch (error) {
    return null
  }
}
