import React, { useEffect, useState } from 'react'
import { TableComponent } from '../../components/table'
import {
  useFetchAvailableLocationsQuery,
  useFetchPickupLocationsQuery,
  useToggleAvailableLocationMutation,
  useTogglePickupLocationMutation,
  useUpdateAvailableLocationsMutation,
} from '../../redux-toolkits/user/user.slice'
import { Button } from '../../components/button'
import AddressInput from '../../components/inputs/addressInput'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import {
  Location,
  UpdateAvailableLocationsPayload,
} from '../../redux-toolkits/user/user.type'
import Switch from '../../components/switch'
import { useModalControl } from '../../hooks/useModalControl'
import { AddLocationModal } from '../../components/modal/app_modals/addLocationModal'
import Icon from '../../components/icon'

export const Locations = () => {
  const [query, setQuery] = useState({ limit: 10, page: 1 })
  const [pickupQuery, setPickupQuery] = useState({
    limit: 10,
    page: 1,
  })
  const [accordionOpen, setAccordionOpen] = useState({
    availableLocations: false,
    pickupLocations: false,
  })

  const { modalOpen, modal, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const {
    data,
    isLoading: fetchingLocations,
    refetch,
  } = useFetchAvailableLocationsQuery(query)

  const {
    data: pickupLocationsData,
    isLoading: fetchingPickupLocations,
    refetch: refetchPickupLocations,
  } = useFetchPickupLocationsQuery(pickupQuery)

  const [togglePickUp, { isLoading: editingPickUpLocation }] =
    useTogglePickupLocationMutation()

  const [toggleAvailable, { isLoading: editingAvailableLocation }] =
    useToggleAvailableLocationMutation()

  return (
    <div className="flex flex-col gap-10">
      {/* Available Locations  */}
      <div className="flex flex-col gap-10 pb-10 border-b">
        <div className="flex items-center justify-between gap-4">
          <HeaderSubheader
            title="Available locations"
            text="Configure available good locations here."
            className="max-w-fit"
          />

          <Button
            label={'New'}
            primary={false}
            onClick={() => {
              handleModalOpen('addNewLocationModal')
            }}
            size="medium"
            icon="location"
            className="min-w-fit"
            type="button"
          />
        </div>

        <div className="flex gap-2 items-center justify-between">
          <span className="text-sm text-sec-black">
            Available in{' '}
            <span className="text-black font-medium">
              {data?.data.total ?? 0}
            </span>{' '}
            locations
          </span>

          <div className="flex text-sm gap-2 items-center">
            <span className="font-medium">
              {accordionOpen.availableLocations
                ? 'Hide locations'
                : 'View locations'}
            </span>
            <button
              type="button"
              className="p-1.5 rounded-full bg-black text-white"
              onClick={() => {
                setAccordionOpen({
                  ...accordionOpen,
                  availableLocations: !accordionOpen.availableLocations,
                })
              }}
            >
              <Icon
                id="left-caret"
                width={10}
                height={10}
                className={`${
                  accordionOpen.availableLocations ? 'rotate-90' : '-rotate-90'
                }
				transition-all duration-300
				`}
              />
            </button>
          </div>
        </div>

        {accordionOpen.availableLocations && (
          <TableComponent
            headers={['Location', 'City', 'State', ' ']}
            rows={
              data?.data.docs.map((item) => {
                return {
                  id: item._id,
                  content: [
                    item.name,
                    item.city,
                    item.state,
                    <button
                      disabled={editingAvailableLocation}
                      className="flex items-center justify-end w-fit"
                      key={`${item._id}-controls`}
                      onClick={() => {
                        toggleAvailable({
                          id: item._id,
                          isAvailable: !item.isAvailable,
                        }).then(() => {
                          refetch()
                        })
                      }}
                    >
                      <Switch
                        checked={item.isAvailable}
                        disabled={editingAvailableLocation}
                      />
                    </button>,
                  ],
                }
              }) ?? []
            }
            name="available-locations-table"
            isRowClickable={false}
            loading={fetchingLocations}
            isEmpty={data?.data && data?.data.docs.length < 1}
            emptyText="No location has been configured"
            currentPage={query.page}
            totalDataCount={data?.data.total}
            pageLimit={query.limit}
            onPageChange={(page) => {
              setQuery({ ...query, page: page })
            }}
          />
        )}
      </div>

      {/* PickUp Location  */}
      <div className="flex flex-col gap-10 pb-10 border-b">
        <div className="flex items-center justify-between gap-4">
          <HeaderSubheader
            title="Pick-up locations"
            text="Configure good pick-up locations here."
            className="max-w-fit"
          />

          <Button
            label={'New'}
            primary={false}
            onClick={() => {
              handleModalOpen('addNewPickupLocationModal')
            }}
            size="medium"
            icon="location"
            className="min-w-fit"
            type="button"
          />
        </div>

        <div className="flex gap-2 items-center justify-between">
          <span className="text-sm text-sec-black">
            Pick-up in{' '}
            <span className="text-black font-medium">
              {pickupLocationsData?.data.total ?? 0}
            </span>{' '}
            locations
          </span>

          <div className="flex text-sm gap-2 items-center">
            <span className="font-medium">
              {accordionOpen.pickupLocations
                ? 'Hide locations'
                : 'View locations'}
            </span>
            <button
              type="button"
              className="p-1.5 rounded-full bg-black text-white"
              onClick={() => {
                setAccordionOpen({
                  ...accordionOpen,
                  pickupLocations: !accordionOpen.pickupLocations,
                })
              }}
            >
              <Icon
                id="left-caret"
                width={10}
                height={10}
                className={`${
                  accordionOpen.pickupLocations ? 'rotate-90' : '-rotate-90'
                }
				transition-all duration-300
				`}
              />
            </button>
          </div>
        </div>

        {accordionOpen.pickupLocations && (
          <TableComponent
            headers={['Location', 'Address', ' ']}
            rows={
              pickupLocationsData?.data.docs.map((item) => {
                return {
                  id: item._id,
                  content: [
                    item.name,
                    item.address,
                    <button
                      disabled={editingPickUpLocation}
                      className="flex items-center justify-end w-fit"
                      key={`${item._id}-controls`}
                      onClick={() => {
                        togglePickUp({
                          id: item._id,
                          isAvailable: !item.isAvailable,
                        }).then(() => {
                          refetchPickupLocations()
                        })
                      }}
                    >
                      <Switch
                        checked={item.isAvailable}
                        disabled={editingPickUpLocation}
                      />
                    </button>,
                  ],
                }
              }) ?? []
            }
            name="available-locations-table"
            isRowClickable={false}
            loading={fetchingPickupLocations}
            isEmpty={
              pickupLocationsData?.data &&
              pickupLocationsData?.data.docs.length < 1
            }
            currentPage={pickupQuery.page}
            totalDataCount={pickupLocationsData?.data.total}
            emptyText="No location has been configured"
            pageLimit={pickupQuery.limit}
            onPageChange={(page) => {
              setPickupQuery({ ...pickupQuery, page: page })
            }}
          />
        )}
      </div>

      <AddLocationModal
        open={modalOpen && modal === 'addNewLocationModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={() => {
          handleModalClose()
          refetch()
        }}
      />

      <AddLocationModal
        open={modalOpen && modal === 'addNewPickupLocationModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={() => {
          handleModalClose()
          refetchPickupLocations()
        }}
        type="pickup"
      />
    </div>
  )
}
