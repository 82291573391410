import React, { useEffect, useState } from 'react'
import Layout from '../../layout'
import OrderTable from '../orders/OrderTable'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import Dropdown from '../../components/inputs/dropdown'
import { Button } from '../../components/button'
import { SearchInput } from '../../components/inputs/searchInput'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { QueryProps, StatusTypes } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import Icon from '../../components/icon'
import { useGetSingleCustomersQuery } from '../../redux-toolkits/user/user.slice'
import Spinner from '../../components/spinner/Spinner'
import { format } from 'date-fns'
import {
  useGetSingleVendorQuery,
  useGetSingleVendorsMetricsQuery,
  useGetSingleVendorsSettlementsQuery,
  useUpdateVendorDetailsMutation,
} from '../../redux-toolkits/vendors/vendors.slice'
import { ChartCard } from '../../components/cards/ChartCard'
import BarChart from '../../components/charts/BarChart'
import { TableComponent } from '../../components/table'
import { formatCurrency } from '../../helpers'
import { NUMBER_OF_ITEMS_PER_PAGE, SETTLEMENTSTATUS } from '../../constants'
import { TextInput } from '../../components/inputs/textInput'
import { AvailableHoursView } from '../../components/common/availableHoursView'
import { SettlementAccountDetails } from '../../components/common/settlementAccountDetails'

export const SingleVendorPage: React.FC = () => {
  const [viewInformation, setViewInformation] = useState(false)
  const [editIndex, setEditIndex] = useState<number>()
  const [businessInformation, setBusinessInformation] = useState<
    {
      key: string
      title: string
      value: string
      type: 'text' | 'textarea'
      placeholder: string
    }[]
  >([])
  const [settlementQuery, setSettlementQuery] = useState<
    QueryProps & { status?: string }
  >({
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    page: 1,
    status: 'all',
  })

  const { id } = useParams()

  const [updateVendor, { isLoading: updatingVendor }] =
    useUpdateVendorDetailsMutation()

  const {
    data: vendor,
    isLoading,
    refetch,
  } = useGetSingleVendorQuery({
    id: id ?? '',
  })

  const { data: vendorMetrics } = useGetSingleVendorsMetricsQuery({
    id: id ?? '',
  })

  const {
    data: vendorSettlements,
    isError: vendorSettlementFetchError,
    isFetching: fetchingSettlements,
  } = useGetSingleVendorsSettlementsQuery({
    ...settlementQuery,
    id: id ?? '',
    skip: (Number(settlementQuery.page) - 1) * Number(settlementQuery.limit),
    status:
      settlementQuery.status === 'all' ? undefined : settlementQuery.status,
  })

  const navigate = useNavigate()

  const setBusinessInformationData = () => {
    setBusinessInformation([
      {
        key: 'businessName',
        title: 'Business name',
        value: vendor?.data?.businessDetails?.businessName ?? '',
        placeholder: 'Enter business name',
        type: 'text',
      },
      {
        key: 'businessEmail',
        title: 'Business email address',
        value: vendor?.data?.businessDetails?.businessEmail ?? '',
        placeholder: 'Enter business email address',
        type: 'text',
      },
      {
        key: 'businessPhonenumber',
        title: 'Business phone number',
        value: vendor?.data?.businessDetails?.businessPhonenumber ?? '',
        placeholder: 'Enter business phone number',
        type: 'text',
      },
      {
        key: 'businessDescription',
        title: 'Business bio',
        value: vendor?.data?.businessDetails?.businessDescription ?? '',
        placeholder: 'Enter business bio',
        type: 'textarea',
      },
      {
        key: 'tinNumber',
        title: 'Tax Identification Number',
        value: vendor?.data?.businessDetails?.tinNumber ?? '',
        placeholder: 'Enter business TIN',
        type: 'text',
      },
      {
        key: 'cacNumber',
        title: 'CAC Number',
        value: vendor?.data?.businessDetails?.cacNumber ?? '',
        placeholder: 'Enter business CAC number',
        type: 'text',
      },
      // {
      //   key: 'nafdacNumber',
      //   title: 'NAFDAC Number',
      //   value: vendor?.data?.businessDetails?.nafdacNumber ?? '',
      //   placeholder: 'Enter business NAFDAC number',
      //   type: 'text',
      // },
      // {
      //   key: 'sonNumber',
      //   title: 'SON Number',
      //   value: vendor?.data?.businessDetails?.sonNumber ?? '',
      //   placeholder: 'Enter business SON number',
      //   type: 'text',
      // },
    ])
  }

  const updateValueByKey = (key: string, newValue: string) => {
    setBusinessInformation((prevData) => {
      return prevData.map((item) =>
        item.key === key ? { ...item, value: newValue } : item,
      )
    })
  }

  const handleUpdateVendor = async (data?: object) => {
    const payload = businessInformation.reduce(
      (acc: { [key: string]: string }, { key, value }) => {
        acc[key] = value
        return acc
      },
      {},
    )

    const resp = await updateVendor({
      id: id ?? '',
      data: { ...payload, ...(data ?? {}) },
    })

    if (resp.data?.success) {
      refetch()
      setEditIndex(undefined)
    }
  }

  useEffect(() => {
    setBusinessInformationData()
  }, [vendor])

  return (
    <Layout>
      {isLoading ? (
        <Spinner fullScreen={false} />
      ) : (
        <div className="flex flex-col gap-10">
          <button
            type="button"
            onClick={() => {
              navigate(`/vendors`)
            }}
            className="
            soft-shrink w-fit text-left
            flex items-center
            gap-2 font-medium
            "
          >
            <Icon id={'left-caret'} width={16} height={16} />
            <span>Back</span>
          </button>

          <div className="flex flex-col gap-2 text-sec-black">
            <div
              className="
                w-[80px] h-[80px]
                rounded-full bg-[#4F122A]
                flex items-center justify-center
                text-lg text-white font-medium
            "
            >
              {vendor?.data?.profile?.logo ? (
                <img
                  src={vendor?.data?.profile?.logo}
                  className="h-full w-full object-cover rounded-full overflow-hidden"
                />
              ) : (
                vendor?.data?.businessDetails?.businessName.charAt(0)
              )}
            </div>
            <p className="text-[40px] text-black font-recoleta-bold">
              {vendor?.data?.businessDetails?.businessName}
            </p>
            <p>
              Joined{' '}
              {vendor?.data?.businessDetails?.createdAt
                ? format(
                    vendor?.data?.businessDetails?.createdAt,
                    'MMM dd, yyyy',
                  )
                : ''}
            </p>
            <p>
              Owned by {vendor?.data?.profile?.firstName}{' '}
              {vendor?.data?.profile?.lastName}
            </p>
            <div className="flex items-center gap-2">
              <Icon id="envelope" width={24} height={24} />
              <p>{vendor?.data?.businessDetails?.businessEmail}</p>
            </div>
            <div className="flex items-center gap-2">
              <Icon id="phone-icon" width={24} height={24} />
              <p>{vendor?.data?.businessDetails?.businessPhonenumber}</p>
            </div>
            <div className="flex items-center gap-2">
              <p>Creator&apos;s business status:</p>
              <Status
                text={vendor?.data?.profile?.status ?? ''}
                type={
                  vendor?.data?.profile?.status === 'active'
                    ? 'success'
                    : 'fail'
                }
              />
            </div>
          </div>

          <div className="">
            <button
              className="
              flex items-center
              gap-2 text-lg font-medium
              "
              onClick={() => {
                setViewInformation(!viewInformation)
              }}
              type="button"
            >
              <p>Creator Information</p>

              <Icon
                id="left-caret"
                width={10}
                height={10}
                className={`${
                  viewInformation ? 'rotate-90' : '-rotate-90'
                } transition-all duration-300`}
              />
            </button>

            {viewInformation && (
              <div className="mt-6">
                {businessInformation.map((info, idx) => {
                  const key = `${info.key}-${idx}`

                  return (
                    <div
                      className="
                      flex flex-col gap-4
                      border-b last:border-b-0
                      py-4 first:pt-0
                      "
                      key={key}
                    >
                      <div className="flex items-center justify-between">
                        <span className="font-medium text-base">
                          {info.title}:
                        </span>
                        <button
                          type="button"
                          onClick={() => {
                            if (editIndex === idx) {
                              setEditIndex(undefined)
                              setBusinessInformationData()
                            } else {
                              setEditIndex(idx)
                            }
                          }}
                          className="flex items-center gap-2"
                        >
                          {editIndex === idx ? (
                            'Cancel'
                          ) : (
                            <>
                              <span>Edit</span>{' '}
                              <Icon id="edit" width={20} height={20} />
                            </>
                          )}
                        </button>
                      </div>

                      {editIndex === idx ? (
                        <TextInput
                          hasIcon={false}
                          value={info.value}
                          onChange={(e) => {
                            updateValueByKey(info.key, e.target.value)
                          }}
                          type={info.type}
                          placeholder={info.placeholder}
                        />
                      ) : (
                        <div>
                          {info.value !== ''
                            ? info.value
                            : `No ${info.title} provided`}
                        </div>
                      )}

                      {editIndex === idx && (
                        <Button
                          type="button"
                          primary
                          loading={updatingVendor}
                          disabled={updatingVendor}
                          label="Save"
                          className="!max-w-[160px]"
                          onClick={() => {
                            handleUpdateVendor()
                          }}
                        />
                      )}
                    </div>
                  )
                })}

                <AvailableHoursView
                  availableHours={vendor?.data?.businessDetails?.availableHours}
                  className="py-4"
                  loading={updatingVendor}
                  onModify={async (availHours) => {
                    await handleUpdateVendor({ availableHours: availHours })
                  }}
                />

                <SettlementAccountDetails
                  vendor={vendor?.data.profile}
                  className="py-4"
                  loading={updatingVendor}
                  onModify={async (settlementAccount) => {
                    await handleUpdateVendor({
                      accountNumber: settlementAccount.accountNumber,
                      bankCode: settlementAccount.bankCode,
                    })
                  }}
                />
              </div>
            )}
          </div>

          <CountCardContainer className="!my-0">
            <CountCard
              count={vendorMetrics?.data?.totalRevenue ?? 0}
              text="Total Revenue"
              icon=""
              isCurrency={true}
            />
            <CountCard
              count={vendorMetrics?.data?.totalOrderCount ?? 0}
              text="Total Order Count"
              icon=""
              isCurrency={false}
            />
            <CountCard
              count={vendorMetrics?.data?.totalCustomers ?? 0}
              text="Total Number of Customers"
              icon=""
              isCurrency={false}
            />
          </CountCardContainer>

          <div className="">
            <ChartCard
              title="Top Items by Revenue"
              value={undefined}
              chart={
                <BarChart
                  responsive
                  barThickness={24}
                  height={150}
                  labels={
                    vendorMetrics?.data?.topProducts
                      ?.map((metrics) => metrics.productName)
                      ?.slice(0, 11) ?? []
                  }
                  data={
                    vendorMetrics?.data?.topProducts
                      ?.map((metrics) => metrics.totalRevenue)
                      ?.slice(0, 11) ?? []
                  }
                />
              }
            />
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="!w-full md:!max-w-[400px]">
                <SearchInput
                  searchFunction={(search) => {
                    setSettlementQuery({
                      ...settlementQuery,
                      search: search === '' ? undefined : search,
                      skip: 0,
                    })
                  }}
                  placeholder="Search order"
                />
              </div>

              <Dropdown
                menuClassName=""
                menuButton={
                  <Button
                    label={`Filter: ${settlementQuery.status}`}
                    primary={false}
                    icon="filter"
                    className="!py-3 !px-4 !text-base !font-normal !w-full md:max-w-fit"
                  />
                }
                onClickMenuItem={(selected) => {
                  setSettlementQuery({
                    ...settlementQuery,
                    status: selected.value as string,
                    skip: 0,
                  })
                }}
                menuItems={[
                  {
                    name: 'All',
                    value: 'all',
                  },
                  ...SETTLEMENTSTATUS.map((status) => {
                    return {
                      name: status.name,
                      value: status.name,
                    }
                  }),
                ]}
              />
            </div>

            <TableComponent
              name="Settlement History"
              showName={false}
              headers={[
                'Transaction Id',
                'Order Id',
                'Amount Settled',
                'Status',
                'Date',
              ]}
              rows={
                vendorSettlements
                  ? vendorSettlements.data.results.map((item) => {
                      return {
                        id: item._id,
                        content: [
                          <Link
                            to={`/settlement/${item._id}`}
                            className="
                            hover:text-black hover:underline
                            transition-all duration-500
                          "
                            key={`${item._id}-transaction-id`}
                          >
                            {item._id}
                          </Link>,
                          <Link
                            to={`/orders/${item.order._id}`}
                            className="
                            hover:text-black hover:underline
                            transition-all duration-500
                          "
                            key={`${item.order._id}-product-id`}
                          >
                            #{item.order.orderNumber}
                          </Link>,
                          formatCurrency(item.amount),
                          <Status
                            text={item.status.toLowerCase()}
                            type={
                              (SETTLEMENTSTATUS.find(
                                (status) =>
                                  status.name.toLowerCase() ===
                                  item.status.toLowerCase(),
                              )?.type ?? 'warn') as StatusTypes
                            }
                            key={`${item._id}-status`}
                          />,
                          `${format(item.createdAt, 'dd/MM/yyyy h:mma')}`,
                        ],
                      }
                    })
                  : []
              }
              onPageChange={(page) => {
                setSettlementQuery({ ...settlementQuery, page: page })
              }}
              pageLimit={settlementQuery.limit as number}
              currentPage={settlementQuery?.page as number}
              loading={fetchingSettlements}
              isEmpty={
                vendorSettlementFetchError ||
                [undefined, 0].includes(
                  vendorSettlements?.data?.results?.length,
                )
              }
              totalDataCount={vendorSettlements?.data.count ?? 0}
            />
          </div>
        </div>
      )}
    </Layout>
  )
}
