import React, { ChangeEvent, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddProductVariantSchema,
  CreateCategorySchema,
  CreateTagSchema,
  SendVouchersSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
  useSendVouchersMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import {
  ComboItem,
  CreatedProductVariant,
  ProductVariant,
  SingleVariant,
} from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { removeElementAtIndex } from '../../../helpers'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { ImageBanner } from '../../images/imageBanner'
import { useModalControl } from '../../../hooks/useModalControl'
import { ImageUploadPreviewModal } from './ImagePreviewModal'

export const SendVouchersModal: React.FC<{
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
}) => {
  const [sendVouchers, { isLoading }] = useSendVouchersMutation()
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      value: '',
      emails: [],
    },
    enableReinitialize: true,
    validationSchema: SendVouchersSchema,
    onSubmit: async (values, { resetForm }) => {
      const resp = await sendVouchers({
        ...values,
        value: Number(values.value),
      })

      if (resp.data?.success) {
        resetForm()
        onSuccessCallback()
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Send Vouchers'}
    >
      <div className="flex flex-col gap-4">
        <TextInput
          type="text"
          name="value"
          placeholder="Enter voucher value (Amount)"
          value={values.value}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.value && touched.value ? errors.value : ''}
          hasIcon={false}
          className="!border-divider-gray !w-full"
        />

        <div className="flex flex-col gap-4">
          {values.emails.map((value, index) => {
            const key = `${index}`

            return (
              <div key={key} className="flex items-center gap-2">
                <div className="flex-1">
                  <TextInput
                    type="text"
                    name={`emails[${index}]`}
                    placeholder="Enter email address"
                    value={String(value)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors.emails && touched.emails
                        ? errors.emails[index]
                        : ''
                    }
                    hasIcon={false}
                    className=""
                  />
                </div>

                <button
                  type="button"
                  onClick={() => {
                    const valuesCopy = JSON.parse(JSON.stringify(values.emails))

                    setFieldValue(
                      'emails',
                      removeElementAtIndex(valuesCopy, index),
                    )
                  }}
                >
                  <Icon
                    id="close-icon"
                    width={14}
                    height={14}
                    className="text-danger"
                  />
                </button>
              </div>
            )
          })}
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={() => {
              setFieldValue('emails', [...values.emails, ''])
            }}
          >
            Add email
            <Icon
              id="plus"
              width={20}
              height={20}
              className="border rounded-full p-0.5 border-black"
            />
          </button>
        </div>

        <Button
          label="Proceed"
          type="button"
          className="mt-10"
          disabled={!isValid || isLoading}
          loading={isLoading}
          primary
          onClick={() => {
            handleSubmit()
          }}
        />
      </div>
    </ModalComponent>
  )
}
