import React from 'react'
import Icon from '../icon'

interface PaginationProps {
  currentPage: number
  totalCount: number
  itemsPerPage: number
  pageRangeDisplayed: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
  itemsPerPage,
  pageRangeDisplayed,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCount / itemsPerPage)

  const getPageNumbers = (): any => {
    if (totalPages > pageRangeDisplayed) {
      const startPage = Math.max(2, currentPage - 2)
      const endPage = Math.min(totalPages - 1, currentPage + 2)

      let pages = []
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
      }

      if (startPage > 2) {
        pages = ['...', ...pages]
      }
      if (endPage < totalPages - 1) {
        pages = [...pages, '...']
      }

      return [1, ...pages, totalPages]
    }

    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const handlePageChange = (page: number): void => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      onPageChange(page)
    }
  }

  const buttonClassName = `
  p-2 flex items-center justify-center
  soft-shrink rounded-[4px]
  min-w-[32px] h-[32px]
  border border-transparent 
  text-sm text-black
  `

  return (
    <nav
      className="
      w-full flex flex-col items-center 
      justify-center overflow-x-scroll
      gap-4
      text-sm text-sec-black
    "
    >
      <ul
        className="
        flex-1 min-w-max 
        flex flex-nowrap 
        items-center list-none 
        gap-2
        justify-end
        "
      >
        <li
          className={` ${currentPage === 1 ? 'hidden cursor-not-allowed' : ''}`}
        >
          <button
            onClick={() => {
              handlePageChange(currentPage - 1)
            }}
            className={buttonClassName}
            disabled={currentPage === 1}
          >
            <Icon id="left-caret" width={10} height={10} />
          </button>
        </li>
        {getPageNumbers().map((number: number, index: number) => (
          <li key={index} className="block !min-w-fit overflow">
            {typeof number === 'string' ? (
              <span className={buttonClassName}>{number}</span>
            ) : (
              <button
                onClick={() => {
                  handlePageChange(number)
                }}
                className={`${buttonClassName} ${
                  number === currentPage && '!bg-black !font-bold !text-white'
                }`}
              >
                {number}
              </button>
            )}
          </li>
        ))}
        <li
          className={` ${
            currentPage === totalPages ? 'hidden cursor-not-allowed' : ''
          }`}
        >
          <button
            onClick={() => {
              handlePageChange(currentPage + 1)
            }}
            className={buttonClassName}
            disabled={currentPage === totalPages}
          >
            <Icon
              id="left-caret"
              width={10}
              height={10}
              className="rotate-180"
            />
          </button>
        </li>
      </ul>

      <span>
        {itemsPerPage * (currentPage - 1) + 1} - {itemsPerPage * currentPage} of{' '}
        {totalCount} entries
      </span>
    </nav>
  )
}

export default Pagination
