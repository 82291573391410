import React, { useEffect, useState } from 'react'
import {
  useAddProductsToListMutation,
  useGetListProductsQuery,
  useUpdateProductsInListMutation,
} from '../../../redux-toolkits/lists/list.slice'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../../../components/typography/PageHeader'
import { Button } from '../../../components/button'
import Spinner from '../../../components/spinner/Spinner'
import Icon from '../../../components/icon'
import { Product } from '../../../types/types'
import { ProductItem } from '../../../redux-toolkits/lists/list.types'
import { useModalControl } from '../../../hooks/useModalControl'
import { SelectProductModal } from '../../../components/modal/app_modals/selectProductModal'
import { removeElementAtIndex, reorderProducts } from '../../../helpers'
import { AddNewCard } from '../../../components/cards/AddNewCard'
import { ProductTile } from '../../../components/product/productTile'
import { EmptyState } from '../../../components/emptyState/emptyState'
import { useDraggable } from '../../../hooks/useDraggable'

export const ManageListProducts = () => {
  const [products, setProducts] = useState<Product[]>([])

  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const { setModalOpen, modal, handleModalOpen, modalOpen } = useModalControl()

  const { handleDragOver, handleDragStart, handleDrop } = useDraggable()

  const {
    data: listProducts,
    isFetching: fetchingList,
    refetch,
  } = useGetListProductsQuery(id || '')

  const [updateProductsInList, { isLoading: addingToList }] =
    useUpdateProductsInListMutation()

  const [addProductsToList, { isLoading: addingNewToList }] =
    useAddProductsToListMutation()

  useEffect(() => {
    if (listProducts?.data?.products) {
      setProducts(listProducts.data.products)
    }
  }, [listProducts?.data?.products])

  return fetchingList ? (
    <Spinner fullScreen={false} />
  ) : (
    <div>
      <div className="flex flex-wrap items-center justify-between gap-4">
        <PageHeader title={`Manage List Products`} className="!mb-0" />

        <div className="flex items-center gap-4 justify-end">
          <Button
            type="button"
            onClick={() => {
              navigate('/lists/curated')
            }}
            label="Cancel"
            size="medium"
            className="!px-10"
          />

          <Button
            type="submit"
            label="Save"
            primary
            disabled={addingToList || addingNewToList}
            loading={addingToList || addingNewToList}
            size="medium"
            className="!px-10"
            onClick={() => {
              if (listProducts?.data?.products?.length === undefined) {
                addProductsToList({
                  productIds: products.map((product) => product._id),
                  listId: id ?? '',
                })?.then((resp) => {
                  resp.data && refetch()
                })
              } else {
                updateProductsInList({
                  productIds: products.map((product) => product._id),
                  listId: id ?? '',
                })?.then((resp) => {
                  resp.data && refetch()
                })
              }
            }}
          />
        </div>
      </div>

      <div className="mt-10">
        {products && products.length > 0 ? (
          <ul
            className="
                  grid grid-cols-1 md:grid-cols-2 gap-10
                  "
          >
            {products.map((product, idx) => (
              <li
                key={product._id}
                draggable
                onDragStart={handleDragStart(idx)}
                onDrop={handleDrop(
                  idx,
                  products,
                  (products, startIndex, index) => {
                    const reorderedList = reorderProducts(
                      products,
                      startIndex,
                      index,
                    )
                    setProducts(reorderedList)
                  },
                )}
                onDragOver={handleDragOver}
                className="cursor-pointer"
              >
                <ProductTile
                  product={product}
                  showDelete
                  onDelete={() => {
                    const productsCopy = JSON.parse(JSON.stringify(products))
                    setProducts(removeElementAtIndex(productsCopy, idx))
                  }}
                />
              </li>
            ))}
          </ul>
        ) : (
          <EmptyState text="No products have been added to this list" />
        )}
      </div>

      <div
        className={`flex ${
          products.length > 0
            ? 'justify-center mt-10 w-full'
            : 'justify-center mt-0 w-full'
        }`}
      >
        <Button
          type="button"
          onClick={() => {
            handleModalOpen('selectProductModal')
          }}
          icon="plus"
          label="Add products"
          primary
          className="!px-10"
        />
      </div>

      <SelectProductModal
        open={modal === 'selectProductModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {}}
        onSuccessCallback={(selected) => {
          if (!products.map((product) => product._id).includes(selected._id)) {
            setProducts([...products, selected])
          }
        }}
      />
    </div>
  )
}
