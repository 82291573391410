import React from 'react'
import Icon from '../icon'

type ModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  children: React.ReactNode
  onCloseCallback?: () => void
  title?: string
  childrenClassName?: string
}

const ModalComponent: React.FC<ModalProps> = ({
  open,
  setOpen,
  children,
  onCloseCallback,
  title = '',
  childrenClassName = '',
}) => {
  const closeModal = () => {
    setOpen(false)
    onCloseCallback && onCloseCallback()
  }

  return (
    open && (
      <div
        className="
        fixed z-[999] max-h-screen w-screen 
        inset-0 flex justify-center items-end md:items-center
        md:p-4
        "
      >
        <div
          className="fixed z-10 bg-black/60 h-screen w-screen"
          onClick={closeModal}
        />
        <div
          className={`
          bg-white z-30 w-128 h-fit p-4 py-10 md:p-6 rounded-lg
          max-h-[90%]
          flex flex-col
           ${childrenClassName}
          `}
        >
          <div className="flex items-center justify-between gap-4 mb-10">
            <span className="text-xl md:text-2xl font-recoleta-bold">
              {title}
            </span>

            <button type="button" onClick={closeModal}>
              <Icon id="close-circle" width={24} height={24} />
            </button>
          </div>

          <div className={`overflow-y-scroll hide-scroll-bar`}>{children}</div>
        </div>
      </div>
    )
  )
}

export default ModalComponent
