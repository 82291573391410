import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import type { QueryProps, TransactionsResponse } from '../../types/types'
import { apiSlice } from '../api/apiSlice'

export const transactionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query<TransactionsResponse, QueryProps>({
      query: (args) => ({
        url: `/transactions`,
        params: {
          limit: args.limit ?? NUMBER_OF_ITEMS_PER_PAGE,
          page: args.page,
          sortDirection: 'desc',
          search: args.search,
        },
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllTransactionsQuery } = transactionsApiSlice
