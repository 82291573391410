import React, { ChangeEvent, useEffect, useState } from 'react'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../components/button'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import Icon from '../../components/icon'
import { AddNewCard } from '../../components/cards/AddNewCard'
import { Field, useFormik } from 'formik'
import { TextInput } from '../../components/inputs/textInput'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import Dropdown from '../../components/inputs/dropdown'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import {
  useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
} from '../../redux-toolkits/vendors/vendors.slice'
import { debounce } from 'lodash'
import { Checkbox } from '../../components/inputs/Checkbox'
import { SelectProductModal } from '../../components/modal/app_modals/selectProductModal'
import { useModalControl } from '../../hooks/useModalControl'
import {
  ComboItem,
  CreateProductValues,
  DeliveryLocation,
  Product,
  ProductVariant,
} from '../../types/types'
import {
  formatCurrency,
  getVendorName,
  removeElementAtIndex,
} from '../../helpers'
import { SelectTagsModal } from '../../components/modal/app_modals/selectTagsModal'
import {
  AddDeliveryLocation,
  CreateProductPayload,
  DeliveryTimeType,
  SingleSubCategory,
  Tag,
  WeightUnits,
} from '../../redux-toolkits/products/products.type'
import {
  useCreateProductMutation,
  useEditProductMutation,
  useGetVariantsQuery,
  useLazyGetSingleProductQuery,
} from '../../redux-toolkits/products/products.slice'
import Spinner from '../../components/spinner/Spinner'
import { ImageUploadButton } from '../../components/button/imageUploadButton'
import { AddComboItemModal } from '../../components/modal/app_modals/addComboItemModal'
import { AddProductVariantModal } from '../../components/modal/app_modals/addVariantModal'
import { ImageUploadPreviewModal } from '../../components/modal/app_modals/ImagePreviewModal'
import { CreateProductSchema } from '../../validation/validate'
import { AddDeliveryLocationModal } from '../../components/modal/app_modals/addDeliveryLocation'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { SelectCategoriesModal } from '../../components/modal/app_modals/selectCategoriesModal'
import { SelectSubCategoriesModal } from '../../components/modal/app_modals/selectSubCategoriesModal'
import { ManageProductCategories } from '../../components/product/manageProductCategories'
import { ManageProductSubCategories } from '../../components/product/manageProductSubCategories'
import { ManageProductVariants } from '../../components/product/manageProductVariants'
import { ManageProductTags } from '../../components/product/manageProductTags'
import { ManageProductCombos } from '../../components/product/manageProductCombos'
import { ManageProductLocations } from '../../components/product/manageProductLocations'
import { ManageProductImages } from '../../components/product/manageProductImages'
import { Status } from '../../components/cards/statusTag'

const productWeightOptions = [
  {
    name: 'Grams',
    value: 'grams',
  },
  {
    name: 'Kilograms',
    value: 'kilograms',
  },
  {
    name: 'Pounds',
    value: 'pounds',
  },
]

const handleSearch = (searchFunction: (val?: any) => void, query?: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

export const CreateProductPage = () => {
  const { id } = useParams<{ id: string }>()

  const [title, setTitle] = useState<string>('Edit product')
  const [vendorSearch, setVendorSearch] = useState<string>()

  const [uploadedImage, setUploadedImage] = useState<File>()

  const navigate = useNavigate()

  const { isLoading: loadingCategories, data: categories } =
    useFetchListCategoriesQuery({
      skip: 0,
      limit: 100,
    })

  const [
    fetchSingleProduct,
    { isFetching: fetchingSingleProduct, data: productData },
  ] = useLazyGetSingleProductQuery()

  const [getAllVendors, { data: vendors, isFetching: fetchingVendors }] =
    useLazyGetAllVendorsQuery()

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  const [createProduct, { isLoading: creatingProduct }] =
    useCreateProductMutation()

  const [editProduct, { isLoading: editingProduct }] = useEditProductMutation()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const initialValues: CreateProductValues = {
    name: '',
    category: [],
    subCategory: [],
    description: '',
    images: [],
    price: '',
    quantityInStock: '',
    vendor: {
      _id: '',
      name: '',
    },
    tags: [],
    isCombo: false,
    comboItems: [],
    isActive: true,
    isVariant: false,
    variants: [],
    variantCombinations: [],
    lowStockAlert: false,
    lowStockQuantity: '',
    weight: undefined,
    weightUnit: 'grams',
    deliveryLocation: [],
    instantDeliveryEnabled: false,
    isMarkupEnabled: false,
    markupValue: '',
  }
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateProductSchema,
    onSubmit: (values, { resetForm }) => {
      const payload: CreateProductPayload = {
        name: values.name,
        categoryId: values.category.map((cat) => cat._id),
        subcategoryId: values.subCategory.map((subCat) => subCat._id),
        description: values.description,
        images: values.images,
        isActive: values.isActive,
        trackQuantity: true,
        price: Number(values.price),
        inStockQuantity: Number(values.quantityInStock),
        vendorId: values.vendor?._id,
        tags: values.tags.map((tag) => tag._id),
        isCombo: values.isCombo,
        comboItems: values.comboItems,
        isVariant: values.isVariant,
        deliveryLocation: values.deliveryLocation.map((item) => {
          return {
            locationId: item.locationId,
            minimumDeliveryTime: item.minimumDeliveryTime,
            maximumDeliveryTime: item.maximumDeliveryTime,
            type: item.type as DeliveryTimeType,
            deliveryTime: item.deliveryTime,
          }
        }),
        variants: values.variants.map((variant) => {
          return {
            variant: variant.variant._id,
            value: variant?.value,
            price: variant.price,
            images: variant.images ?? [],
          }
        }),
        variantCombinations: values.variantCombinations,
        lowStockAlert: values.lowStockAlert,
        instantDeliveryEnabled: values.instantDeliveryEnabled,
        isMarkupEnabled: values.isMarkupEnabled,
      }

      if (payload.lowStockAlert) {
        payload.lowStockQuantity = Number(values.lowStockQuantity)
      }

      if (payload.isMarkupEnabled) {
        payload.markupValue = Number(values.markupValue)
      }

      if (values.weight && values.weightUnit) {
        payload.weight = {
          value: Number(values.weight),
          unit: values.weightUnit as WeightUnits,
        }
      }

      id
        ? editProduct({ id, body: payload }).then((resp) => {
            if (resp.data?.success) {
              fetchPageProduct()
            }
          })
        : createProduct(payload).then((resp) => {
            if (resp.data?.success) {
              resetForm()
              navigate(`/products/view/${resp.data.data._id}`)
            }
          })
    },
  })

  useEffect(() => {
    handleDebouncedSearch(getAllVendors, {
      page: `1`,
      limit: NUMBER_OF_ITEMS_PER_PAGE.toString(),
      search: vendorSearch,
    })
  }, [vendorSearch])

  const fetchPageProduct = () => {
    id &&
      fetchSingleProduct({ id }).then((resp) => {
        if (resp.data?.success) {
          const product = resp.data.data
          setTitle(product.name)
          setFieldValue('name', product.name)
          setFieldValue('category', product.category ?? [])
          setFieldValue('subCategory', product?.subcategory ?? [])
          setFieldValue('description', product.description)
          setFieldValue('images', product.images ? [...product.images] : [])
          setFieldValue('price', product.price)
          setFieldValue('isActive', product.isActive ?? false)
          setFieldValue('vendor', {
            _id: product.vendor?._id,
            name: getVendorName(product.vendor),
          })
          setFieldValue('quantityInStock', product.inStockQuantity)
          setFieldValue('tags', product.tags)
          setFieldValue('isCombo', product.comboItems.length > 0)
          setFieldValue(
            'comboItems',
            product.comboItems.map(({ _id, ...others }) => {
              return { ...others }
            }),
          )
          setFieldValue('isVariant', product.variants.length > 0)
          setFieldValue(
            'variants',
            product.variants.map(({ _id, ...others }) => {
              return { ...others }
            }),
          )
          setFieldValue(
            'variantCombinations',
            product?.variantCombinations ?? [],
          )
          setFieldValue('lowStockAlert', product.lowStockAlert)
          setFieldValue('lowStockQuantity', product.lowStockQuantity ?? '')
          setFieldValue('weight', product.weight?.value)
          setFieldValue('weightUnit', product.weight?.unit ?? 'grams')
          setFieldValue(
            'deliveryLocation',
            product?.deliveryLocation?.map((loc) => {
              return {
                locationName: loc.locationId.name,
                locationId: loc.locationId._id,
                minimumDeliveryTime: loc.minimumDeliveryTime,
                maximumDeliveryTime: loc.maximumDeliveryTime,
                type: loc.type,
                deliveryTime: loc.deliveryTime,
              }
            }) ?? [],
          )
          setFieldValue(
            'instantDeliveryEnabled',
            product.instantDeliveryEnabled ?? false,
          ),
            setFieldValue('isMarkupEnabled', product.isMarkupEnabled ?? false),
            setFieldValue('markupValue', product.markupValue ?? '')
        }
      })
  }

  useEffect(() => {
    if (id) {
      fetchPageProduct()
    }
  }, [id])

  return (
    <Layout>
      {id && fetchingSingleProduct ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <form className="flex flex-col gap-10" onSubmit={handleSubmit}>
            <div className="flex w-full items-start gap-4 justify-between">
              <PageHeader
                title={
                  id ? (
                    <div className="flex flex-col gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          navigate(`/products`)
                        }}
                        className="soft-shrink flex items-center gap-4"
                      >
                        <Icon id={'left-caret'} width={14} height={14} />
                        {title}
                      </button>

                      <p className="text-sec-black font-geist text-base font-medium">
                        {formatCurrency(values.price)}
                      </p>

                      <div className="flex items-center gap-2 text-sm font-geist">
                        <p>
                          <span className="font-medium">
                            {values.quantityInStock}
                          </span>{' '}
                          items in stock
                        </p>
                        <p className="text-base">•</p>
                        <Status
                          type={
                            productData?.data?.isActive ? 'success' : 'fail'
                          }
                          text={
                            productData?.data?.isActive ? 'Active' : 'Inactive'
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    'Create product'
                  )
                }
                className="flex items-center gap-4"
              />

              <div className="flex flex-row gap-4 items-center flex-1 justify-end">
                <Button
                  type="submit"
                  primary
                  label="Save"
                  className="!w-full !max-w-[150px]"
                  loading={creatingProduct || editingProduct}
                  disabled={creatingProduct || editingProduct}
                />

                {id && (
                  <Dropdown
                    menuButton={
                      <div className="p-3 rounded-[8px] border border-black">
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      </div>
                    }
                    onClickMenuItem={() => {}}
                    menuItems={[
                      {
                        name: (
                          <button
                            type="button"
                            onClick={() => {
                              setFieldValue(
                                'isActive',
                                !productData?.data?.isActive,
                              )
                              handleSubmit()
                            }}
                            className="text-left"
                            disabled={editingProduct}
                          >
                            {productData?.data?.isActive
                              ? 'Deactivate'
                              : 'Activate'}
                          </button>
                        ),
                        value: '',
                      },
                    ]}
                    menuItemClassName="border-b-0"
                    menuClassName="!w-[200px]"
                  />
                )}
              </div>
            </div>

            {/* IMAGES  */}
            <ManageProductImages
              values={values}
              setFieldValue={setFieldValue}
              handleFileChange={handleFileChange}
            />

            {/* GENERAL DETAILS */}
            <div
              className="
              flex flex-col gap-6 
              pb-10 border-b border-divider-gray"
            >
              <HeaderSubheader
                title="Product Details"
                text="Enter basic product details and information."
              />

              <div
                className="
                grid grid-flow-row 
                grid-cols-[repeat(auto-fit,_minmax(302px,1fr))]
                gap-4
              "
              >
                <div className="col-span-full">
                  <TextInput
                    type="text"
                    name="name"
                    placeholder="Product name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors.name && touched.name ? errors.name : ''}
                    hasIcon={false}
                    className=""
                  />
                </div>

                <Dropdown
                  loading={fetchingVendors}
                  className=""
                  menuClassName="max-h-[200px]"
                  widthClass="!w-full"
                  menuButton={
                    <TextInput
                      type="text"
                      name="vendor"
                      placeholder="Vendor"
                      value={vendorSearch ? vendorSearch : values.vendor.name}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setVendorSearch(e.target.value)
                      }}
                      errors={
                        errors.vendor?._id && touched.vendor?._id
                          ? errors.vendor._id
                          : ''
                      }
                      hasIcon={false}
                      readOnly={false}
                      extra={
                        <Icon
                          id={'left-caret'}
                          width={16}
                          height={16}
                          className="-rotate-90 text-sec-black"
                        />
                      }
                      className="!border-divider-gray"
                    />
                  }
                  onClickMenuItem={(selection) => {
                    setFieldValue('vendor', {
                      _id: selection.value,
                      name: selection.name,
                    })
                    setVendorSearch(undefined)
                  }}
                  menuItems={vendors?.data.vendors.map((vendor) => {
                    return {
                      name: vendor.businessName,
                      value: vendor._id,
                    }
                  })}
                />

                <TextInput
                  type="text"
                  name="price"
                  placeholder="Price"
                  value={values.price}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors.price && touched.price ? errors.price : ''}
                  hasIcon={false}
                  readOnly={id !== undefined}
                  showAsReadOnly
                  className=""
                />

                <TextInput
                  type="text"
                  name="quantityInStock"
                  placeholder="Quantity in stock"
                  value={values.quantityInStock}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.quantityInStock && touched.quantityInStock
                      ? errors.quantityInStock
                      : ''
                  }
                  hasIcon={false}
                  readOnly={id !== undefined}
                  showAsReadOnly
                  className=""
                />

                <div className="col-span-full">
                  <TextInput
                    type="textarea"
                    name="description"
                    placeholder="Product description"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors.description && touched.description
                        ? errors.description
                        : ''
                    }
                    hasIcon={false}
                    className=""
                  />
                </div>

                <div className="col-span-full flex flex-col gap-4 justify-center">
                  <Checkbox
                    checkStatus={values.lowStockAlert}
                    text="Enable low stock notification"
                    handleChange={(status) => {
                      setFieldValue('lowStockAlert', status)
                    }}
                  />

                  {values.lowStockAlert && (
                    <TextInput
                      type="text"
                      name="lowStockQuantity"
                      placeholder="Low stock quantity"
                      value={values.lowStockQuantity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        errors.lowStockQuantity && touched.lowStockQuantity
                          ? errors.lowStockQuantity
                          : ''
                      }
                      hasIcon={false}
                      className="max-w-[302px]"
                    />
                  )}
                </div>

                <div className="col-span-full flex flex-col gap-4 justify-center">
                  <Checkbox
                    checkStatus={values.isMarkupEnabled}
                    text="Enable markup percentage"
                    handleChange={(status) => {
                      setFieldValue('isMarkupEnabled', status)
                    }}
                  />

                  {values.isMarkupEnabled && (
                    <TextInput
                      type="text"
                      name="markupValue"
                      placeholder="Markup value"
                      value={values.markupValue ?? ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        errors.markupValue && touched.markupValue
                          ? errors.markupValue
                          : ''
                      }
                      hasIcon={false}
                      className="max-w-[302px]"
                    />
                  )}
                </div>

                <div className="col-span-full flex flex-col gap-4 justify-center">
                  <Checkbox
                    checkStatus={values.instantDeliveryEnabled}
                    text="Enable instant delivery"
                    handleChange={(status) => {
                      setFieldValue('instantDeliveryEnabled', status)
                    }}
                  />
                </div>
              </div>
            </div>
            {/* WEIGHT  */}
            <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
              <HeaderSubheader
                title="Product Weight"
                text="Specify product weight"
              />
              <div className="col-span-full flex gap-4 items-center">
                <TextInput
                  type="text"
                  name="weight"
                  placeholder="Product weight"
                  value={values.weight}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors.weight && touched.weight ? errors.weight : ''}
                  hasIcon={false}
                  className=""
                />

                {values.weight && (
                  <Dropdown
                    loading={false}
                    className=""
                    menuClassName="max-h-[200px]"
                    widthClass="!w-full"
                    menuButton={
                      <TextInput
                        type="text"
                        name="weightUnit"
                        placeholder="Weight unit"
                        value={values.weightUnit}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={
                          errors.weightUnit && touched.weightUnit
                            ? errors.weightUnit
                            : ''
                        }
                        hasIcon={false}
                        readOnly
                        extra={
                          <Icon
                            id={'left-caret'}
                            width={16}
                            height={16}
                            className="-rotate-90 text-sec-black"
                          />
                        }
                        className="!border-divider-gray"
                      />
                    }
                    onClickMenuItem={(selection) => {
                      setFieldValue('weightUnit', selection.value)
                    }}
                    menuItems={productWeightOptions}
                  />
                )}
              </div>
            </div>
            {/* TAGS  */}
            <ManageProductTags values={values} setFieldValue={setFieldValue} />
            {/* CATEGORIES  */}
            <ManageProductCategories
              values={values}
              setFieldValue={setFieldValue}
              errors={(errors?.category ?? '') as string}
            />
            {/* SUB-CATEGORIES  */}
            <ManageProductSubCategories
              values={values}
              setFieldValue={setFieldValue}
            />
            {/* COMBOS  */}
            <ManageProductCombos
              values={values}
              setFieldValue={setFieldValue}
            />
            {/* LOCATIONS  */}
            <ManageProductLocations
              values={values}
              setFieldValue={setFieldValue}
              errors={(errors?.deliveryLocation ?? '') as string}
            />
            {/* VARIANTS  */}
            <ManageProductVariants
              values={values}
              setFieldValue={setFieldValue}
            />
          </form>

          {uploadedImage && (
            <ImageUploadPreviewModal
              image={uploadedImage}
              open={modal === 'imageUploadPreviewModal' && modalOpen}
              setOpen={setModalOpen}
              onCloseCallback={() => {
                setUploadedImage(undefined)
              }}
              onSuccessCallback={(url) => {
                const imagesCopy: string[] = JSON.parse(
                  JSON.stringify(values.images ?? []),
                )
                imagesCopy.push(url)
                setFieldValue('images', imagesCopy)
                setUploadedImage(undefined)
              }}
            />
          )}
        </>
      )}
    </Layout>
  )
}
