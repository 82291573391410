import React, { useState } from 'react'
import {
  useCreatePermissionMutation,
  useEditPermissionMutation,
  useGetPermissionsQuery,
} from '../../redux-toolkits/user/user.slice'
import { useAppSelector } from '../../hooks'
import { userSelector } from '../../redux-toolkits/user/user.selector'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { type Permission } from '../../redux-toolkits/user/user.type'
import Spinner from '../../components/spinner/Spinner'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { Button } from '../../components/button'
import { TableComponent } from '../../components/table'
import { Status } from '../../components/cards/statusTag'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { TextInput } from '../../components/inputs/textInput'
import ModalComponent from '../../components/modal'

const PremissionsSection: React.FC = () => {
  const { isLoading } = useGetPermissionsQuery({
    skip: 0,
    limit: 100,
  })

  const { permissions } = useAppSelector(userSelector)

  return (
    <div>
      <div className="flex flex-col gap-10">
        <div className="flex items-center">
          <HeaderSubheader
            title="Permissions"
            text="Here you view available permissions for your team members."
          />
        </div>

        <TableComponent
          loading={isLoading}
          headers={['Name', 'Description', 'Status', 'Code', ' ']}
          rows={permissions.map((permission) => {
            return {
              id: permission._id,
              content: [
                permission.name,
                permission.description,
                <Status
                  key={`${permission._id}-status`}
                  text={!permission.active ? 'Inactive' : 'Active'}
                  type={!permission.active ? 'fail' : 'success'}
                />,
                permission.code,
              ],
            }
          })}
        />
      </div>
    </div>
  )
}

export default PremissionsSection
