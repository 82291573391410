import React, { ChangeEvent, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { ImageBanner } from '../../images/imageBanner'
import { ImageUploadPreviewModal } from './ImagePreviewModal'
import { useModalControl } from '../../../hooks/useModalControl'

export const AddTagModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  mode: 'edit' | 'create'
  tag?: Tag
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  mode,
  tag,
}) => {
  const [createTag, { isLoading: creating }] = useCreateProductTagMutation()
  const [editTag, { isLoading: editing }] = useEditProductTagMutation()
  const [uploadedIcon, setUploadedIcon] = useState<File>()
  const { modal, modalOpen, setModalOpen, handleModalOpen, handleModalClose } =
    useModalControl()

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return
      }
  
      const selectedFile = event.target.files[0]
  
      setUploadedIcon(selectedFile)
  
      handleModalOpen('imageUploadPreviewModal')
    }
  
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      icon: tag?.icon ?? '',
      name: tag?.name ?? '',
      description: tag?.description ?? '',
    },
    enableReinitialize: true,
    validationSchema: CreateTagSchema,
    onSubmit: (values) => {
      if (mode === 'create') {
        createTag(values).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      } else if (mode === 'edit' && tag) {
        editTag({ id: tag?._id, payload: values }).then((resp) => {
          if (resp.data?.success) {
            resetForm()
            setOpen(false)
            onSuccessCallback()
          }
        })
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new tag' : 'Edit tag'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className={`relative  max-w-fit`}>
          <ImageBanner
            imageUrl={values.icon}
            loading={false}
            className="
                                !rounded-full
                                !w-[120px] !max-h-[120px]
                                "
            errors={errors.icon ? errors.icon : ''}
          />
          <ImageUploadButton
            handleChange={(e) => {
              handleFileChange(e)
            }}
            showText={false}
            className="
                                !left-0 !right-0 !top-[calc(50%-20px)]
                                ml-auto mr-auto !max-w-fit
                                !px-2
                                "
          />
        </div>
        <TextInput
          type="text"
          name="name"
          placeholder="Tag name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
        />

        <TextInput
          type="textarea"
          name="description"
          placeholder="Tag description"
          value={values.description}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.description && touched.description ? errors.description : ''
          }
          hasIcon={false}
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || creating || editing}
          loading={creating || editing}
          primary
        />
      </form>
      {uploadedIcon ? (
        <ImageUploadPreviewModal
          image={uploadedIcon as File}
          open={modal === 'imageUploadPreviewModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            setUploadedIcon(undefined)
          }}
          onSuccessCallback={(url) => {
            setFieldValue('icon', url)
          }}
        />
      ) : null}
    </ModalComponent>
  )
}
