import React, { FC } from 'react'
import { Product } from '../../types/types'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { calculateFromPrice } from '../../helpers'
import Icon from '../icon'

interface Iprops {
  product: Product
  onViewProduct?: (product: Product) => void
  disableClick?: boolean
  selected?: boolean
  showDelete?: boolean
  onDelete?: () => void
}
export const ProductTile: FC<Iprops> = ({
  product,
  onViewProduct,
  disableClick = false,
  //selected = false,
  showDelete = false,
  onDelete = () => {},
}) => {
  const outOfStock = product?.inStockQuantity === 0
  const navigate = useNavigate()

  const viewProduct = () => {
    if (!disableClick) {
      onViewProduct
        ? onViewProduct(product)
        : navigate(`/products/view/${product?._id}`)
    }
  }

  return (
    <div className="relative bg-[white] border-b border-default-gray-3">
      <div
        className={`
        pb-5
        flex justify-between
        gap-4
        `}
      >
        <div
          className={clsx(
            `
            relative 
            w-[88px] md:w-[140px]
            h-[88px] md:h-[140px]`,
            {
              'opacity-25': outOfStock,
            },
          )}
        >
          <img
            src={product?.images[0]}
            alt={product?.name}
            className="rounded-md w-full h-full object-cover"
          />
        </div>
        <div className="flex-1 min-w-0">
          <button
            className={clsx(
              `
                flex gap-2
                items-center
                text-base
                justify-between
                mr-10 font-medium
                underline-offset-8
                hover:underline hover:underline-offset-4
                transition-all duration-300
                `,
              {
                'opacity-25': outOfStock,
              },
            )}
            onClick={viewProduct}
            type="button"
          >
            {product?.name}
          </button>

          <div
            className={clsx(
              `
                text-sm mt-1 
                md:mt-2 
                w-44 sm:w-60 md:w-128 lg:w-full 
                line-clamp-2 overflow-hidden 
                text-ellipsis
                text-sec-black
                `,
              {
                'opacity-25': outOfStock,
              },
            )}
          >
            {product?.description}
          </div>
          <div
            className={clsx(
              `
                text-sm
                md:text-base mt-1 md:mt-2
                text-sec-black
                `,
              {
                'opacity-25': outOfStock,
              },
            )}
          >
            {calculateFromPrice(product)}
          </div>

          {showDelete && (
            <button
              className={clsx(
                'absolute right-0 top-1 p-2 bg-danger/10 rounded-full',
                {
                  'opacity-25': outOfStock,
                },
              )}
              type="button"
              onClick={onDelete}
            >
              <Icon className="text-danger" width={16} height={16} id="bin" />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
