import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { useUploadImageMutation } from '../../../redux-toolkits/misc/misc.slice'
import {
  useGetAllProductsQuery,
  useGetAllProductTagsQuery,
  useGetSubCategoriesQuery,
  useLazyGetAllProductsQuery,
  useLazyGetAllProductTagsQuery,
} from '../../../redux-toolkits/products/products.slice'
import { Product, QueryProps } from '../../../types/types'
import { TextInput } from '../../inputs/textInput'
import { debounce } from 'lodash'
import { LoadingOval } from '../../spinner/Spinner'
import { PRIMARY_COLOR } from '../../../constants'
import { formatCurrency } from '../../../helpers'
import { Checkbox } from '../../inputs/Checkbox'
import {
  SingleSubCategory,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import { EmptyState } from '../../emptyState/emptyState'
import { SearchInput } from '../../inputs/searchInput'
import { useFetchListCategoriesQuery } from '../../../redux-toolkits/lists/list.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'

const defaultquery = {
  search: undefined,
  skip: 0,
  limit: '100',
}

export const SelectSubCategoriesModal = ({
  open,
  setOpen,
  onCloseCallback,
  onSuccessCallback,
  selectedSubCategories = [],
  categories = [],
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: SingleSubCategory[]) => void
  selectedSubCategories?: SingleSubCategory[]
  categories?: string[]
}) => {
  const [queryParam, setQueryParam] = useState<QueryProps>(defaultquery)

  const [subCategories, setSubCategories] = useState<SingleSubCategory[]>(
    selectedSubCategories,
  )

  const { data, isFetching } = useGetSubCategoriesQuery({
    ...queryParam,
    categories: categories.length > 0 ? categories.join(',') : undefined,
  })

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        onCloseCallback && onCloseCallback()
        setQueryParam(defaultquery)
        setSubCategories(selectedSubCategories)
      }}
      title="Manage Sub-Categories"
    >
      <div
        className="
        h-screen max-h-[500px]
        flex flex-col gap-4
      "
      >
        <SearchInput
          searchFunction={(search) => {
            setQueryParam({
              ...queryParam,
              search: search === '' ? undefined : search,
              skip: 0,
            })
          }}
          placeholder="Search sub-categories"
        />

        {/* Sub-Categories  */}
        <div className="flex-1 overflow-y-scroll">
          {isFetching ? (
            <div className="w-full flex justify-center pt-10">
              <LoadingOval
                loaderWidth="25"
                loaderHeight="25"
                color={PRIMARY_COLOR}
              />
            </div>
          ) : (
            <div>
              {data?.data?.result?.length && data?.data?.result?.length > 0 ? (
                <div className="flex flex-col">
                  {data?.data?.result?.map((subCategory, idx) => {
                    const key = `sub-category-selection-option-${idx}-${subCategory._id}`
                    return (
                      <Checkbox
                        checkStatus={selectedSubCategories
                          .map((subCategory) => subCategory._id)
                          .includes(subCategory._id)}
                        handleChange={(checked) => {
                          if (checked) {
                            const subCategoriesCopy: SingleSubCategory[] =
                              JSON.parse(JSON.stringify(subCategories))
                            subCategoriesCopy.push(subCategory)

                            setSubCategories(subCategoriesCopy)
                          } else {
                            const subCategoriesCopy: SingleSubCategory[] =
                              JSON.parse(JSON.stringify(subCategories))
                            setSubCategories(
                              subCategoriesCopy.filter(
                                (singleSubCategory) =>
                                  singleSubCategory._id !== subCategory._id,
                              ),
                            )
                          }
                        }}
                        text={
                          <div className="text-xs">
                            <p className="text-base font-medium">
                              {subCategory.name}
                            </p>

                            <p>Category: {subCategory.category.name}</p>
                            <p>
                              Parent sub-category:{' '}
                              {subCategory?.parentSubcategory?.name ?? 'N/A'}
                            </p>
                          </div>
                        }
                        textPosition="left"
                        className="
                        py-3 border-b last:border-b-0
                        w-full justify-between
                        "
                        key={subCategory._id}
                      />
                    )
                  })}
                </div>
              ) : (
                <EmptyState text="No sub-categories found" />
              )}
            </div>
          )}
        </div>

        <Button
          type="button"
          primary
          label="Done"
          onClick={() => {
            onSuccessCallback && onSuccessCallback(subCategories)
          }}
        />
      </div>
    </ModalComponent>
  )
}
