import Autocomplete from 'react-google-autocomplete'
import React from 'react'
import { SelectedGoogleLocation } from '../../types/types'
import Icon from '../icon'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import Dropdown from './dropdown'
import { SearchInput } from './searchInput'
import { Libraries, useLoadScript } from '@react-google-maps/api'
import { LoadingOval } from '../spinner/Spinner'
import { GOOGLE_API_KEY, PRIMARY_COLOR } from '../../constants'

const libraries: Libraries = ['places']

const AddressInput = ({
  onSelect,
  value,
  bgColor = 'bg-gray-bg',
  className,
}: {
  onSelect?: (address: SelectedGoogleLocation) => void
  value?: string
  bgColor?: string
  className?: string
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  })

  const {
    ready,
    value: autoCompleteValue,
    setValue: setAutoCompleteValue,
    suggestions: { status, data, loading },
    clearSuggestions,
  } = usePlacesAutocomplete()

  const handleSelect = async (address: string, placeId: string) => {
    setAutoCompleteValue(address, false)
    clearSuggestions()

    try {
      const results = await getGeocode({ address })
      const { lat, lng } = await getLatLng(results[0])

      console.log(results)

      const placeData = {
        address: address,
        city: '',
        state: 'string',
        country: 'string',
        lat: lat,
        lng: lng,
        destinationGooglePlaceID: placeId,
      }

      if (onSelect) {
        onSelect(placeData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return !isLoaded ? (
    <div className="flex items-center justify-center">
      <LoadingOval loaderHeight="30" loaderWidth="30" color={PRIMARY_COLOR} />
    </div>
  ) : (
    <div
      className={`
        ${className}
        `}
    >
      <SearchInput
        searchFunction={(search) => {
          setAutoCompleteValue(search)
        }}
        value={autoCompleteValue}
      />

      {loading ? (
        <div className="flex items-center justify-center mt-6">
          <LoadingOval
            loaderHeight="30"
            loaderWidth="30"
            color={PRIMARY_COLOR}
          />
        </div>
      ) : (
        <div
          className={`${
            data.length > 0 ? 'h-[250px]' : 'h-0'
          } overflow-y-scroll transition-all duration-300
         w-full flex flex-col mt-6
         `}
        >
          {status === 'OK'
            ? data.map((place) => {
                return (
                  <button
                    type="button"
                    key={place.place_id}
                    className="
                    flex gap-4 items-center py-3
                    border-b last:border-b-0
                    w-full text-left soft-shrink
                  "
                    onClick={async () => {
                      await handleSelect(place.description, place.place_id)
                    }}
                  >
                    <Icon id={'location'} width={16} height={16} />
                    <p className="flex-1">{place.description}</p>
                  </button>
                )
              })
            : []}
        </div>
      )}
    </div>
  )
}

export default AddressInput
