import React from 'react'
import { ErrorText } from '../typography/ErrorText'
import Icon from '../icon'

export const ImageBanner = ({
  loading,
  imageUrl,
  className,
  errors,
  onDelete,
}: {
  loading?: boolean
  imageUrl: string
  className?: string
  errors?: string
  onDelete?: () => void
}) => {
  return (
    <div
      style={{ backgroundImage: !loading ? `url('${imageUrl}')` : '' }}
      className={`
        h-[120px] md:h-[240px] lg:h-[400px]
        w-full
        rounded-md
        bg-cover
        relative
        ${loading && 'animate-pulse !bg-gray-300'}
        ${!imageUrl && '!border-pry-gray border border-dashed'}
        relative
        ${className}
    `}
    >
      {errors && (
        <span className="">
          <ErrorText text={errors} />
        </span>
      )}

      {onDelete && (
        <button
          type="button"
          className="absolute p-2 bg-danger rounded-full top-2 right-2"
          onClick={onDelete}
        >
          <Icon className="text-white" width={16} height={16} id="bin" />
        </button>
      )}
    </div>
  )
}
