import { ErrorType } from '../redux-toolkits/user/user.type'
import { Vendor } from '../types/types'

const formatCurrency = (amount: string | number): string => {
  return new Intl.NumberFormat('en-NG', {
    currency: 'NGN',
    style: 'currency',
  }).format(Number(amount))
}

const formatNumber = (count: number): string => {
  return new Intl.NumberFormat('en-IN', {
    maximumSignificantDigits: 3,
  }).format(count)
}

const getErrorMessage = (error: ErrorType) => {
  return error.error?.data?.error ?? 'Something went wrong, try again later.'
}

export { formatCurrency, formatNumber, getErrorMessage }

export const removeElementAtIndex = (array: any[], index: number) => {
  // Check if the index is within bounds
  if (index >= 0 && index < array.length) {
    array.splice(index, 1)
  }
  return array
}

export const prettyFormatNumber = (num: number) => {
  if (num === null || num === undefined) return null

  const absNum = Math.abs(num)
  if (absNum < 1000) return num

  const units = ['K', 'M', 'B', 'T']
  const index = Math.floor(Math.log10(absNum) / 3) // Find the appropriate index
  const unit = units[index - 1] || '' // Get the unit suffix

  // Format the number and append the unit
  return `${(num / Math.pow(1000, index)).toFixed(1)}${unit}`
}

export const getVendorName = (vendor: Vendor) => {
  return (
    vendor?.businessInformation?.businessName ??
    vendor?.name ??
    (vendor?.firstName && vendor?.lastName
      ? `${vendor?.firstName} ${vendor?.lastName}`
      : undefined) ??
    vendor?.email ??
    'N/A'
  )
}
