import React, { useEffect, useState } from 'react'
import { Button } from '../button'
import { HeaderSubheader } from '../typography/HeaderSubheader'
import {
  Combination,
  CreatedProductVariant,
  CreateProductValues,
  SingleVariant,
  TransformedVariant,
  VariantCombination,
} from '../../types/types'
import { SelectCategoriesModal } from '../modal/app_modals/selectCategoriesModal'
import { useModalControl } from '../../hooks/useModalControl'
import { FormikErrors } from 'formik'
import { SelectSubCategoriesModal } from '../modal/app_modals/selectSubCategoriesModal'
import Icon from '../icon'
import { formatCurrency, removeElementAtIndex } from '../../helpers'
import { Checkbox } from '../inputs/Checkbox'
import { AddProductVariantModal } from '../modal/app_modals/addVariantModal'
import { useGetVariantsQuery } from '../../redux-toolkits/products/products.slice'
import { TableComponent } from '../table'
import Dropdown from '../inputs/dropdown'
import { EditVariantionCombinationModal } from '../modal/app_modals/editVariationModal'

export const ManageProductVariants = ({
  values,
  setFieldValue,
}: {
  values: CreateProductValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<CreateProductValues>>
}) => {
  const [selectedVariant, setSelectedVariant] = useState<{
    index: number
    item: SingleVariant
  }>()
  const [selectedCombination, setSelectedCombination] = useState<{
    index: number
    item: VariantCombination
  }>()
  const [transformedVariants, setTransformedVariants] = useState<
    TransformedVariant[]
  >([])

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  const { isLoading: loadingVariants, data: variants } = useGetVariantsQuery({
    skip: '0',
    page: '1',
    limit: '100',
  })

  useEffect(() => {
    if (transformedVariants.length > 0) {
      // Get all variant combinations
      const variantCombinations = transformedVariants.reduce(
        (acc, values) =>
          acc.flatMap((accVal) =>
            values.values.map((value) => [
              ...accVal,
              {
                variant: values.variant._id,
                value: value,
              },
            ]),
          ),
        [[]] as Combination[][],
      )

      // Create new combination array, reuse existing values for existing combinations
      const newCombinations = [
        ...variantCombinations.map((combo) => {
          // todo: come up with a better logic for identifying existing combinations here
          const existingCombination = values.variantCombinations.find(
            (com) =>
              JSON.stringify(
                com.variantCombination.map((item) => {
                  return {
                    variant: (item.variant as SingleVariant)._id,
                    value: item.value,
                  }
                }),
              ) === JSON.stringify(combo),
          )

          return {
            variantCombination: combo,
            inventoryCount: existingCombination?.inventoryCount ?? 0,
            price: existingCombination?.price ?? 0,
            isActive: existingCombination?.isActive ?? true,
            images: existingCombination?.images ?? [],
          }
        }),
      ]

      // Set new combinations
      setFieldValue('variantCombinations', newCombinations)
    }
  }, [transformedVariants])

  useEffect(() => {
    // Transform list of duplicate variants and values into list of single variant and array of all its values
    setTransformedVariants(
      values.variants.reduce((acc: TransformedVariant[], curr) => {
        // Find if the variant already exists in the accumulator
        const existing = acc.find(
          (item) => item.variant._id === curr.variant._id,
        )

        if (existing) {
          // If found, push the values into the value array
          existing.values.push(curr.value)
        } else {
          // If not found, create a new object for that variant with the values as an array
          acc.push({
            variant: curr.variant,
            values: [curr.value],
          })
        }
        return acc
      }, []),
    )
  }, [values.variants])

  return (
    <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
      <HeaderSubheader
        title="Product Variants"
        text="Specify product variants"
      />

      <Checkbox
        checkStatus={values.isVariant}
        text="This product has variants"
        handleChange={(status) => {
          setFieldValue('isVariant', status)
        }}
      />

      {values.isVariant && (
        <>
          <div className="flex items-center gap-4 justify-between">
            <p className="text-lg font-medium">Options</p>
            <Button
              primary
              icon="plus"
              size="medium"
              label="Add option"
              onClick={() => {
                handleModalOpen('addProductVariantModal')
              }}
              className="!max-w-fit"
            />
          </div>

          <TableComponent
            headers={['Option name', 'Properties', 'Actions']}
            rows={transformedVariants.map((variant, idx) => {
              const key = `${idx}`

              return {
                id: key,
                content: [
                  variant.variant.name,
                  variant.values.join(', '),
                  <Dropdown
                    key={`${key}-controls`}
                    menuButton={
                      <Icon id="ellipses" height={18} width={18} className="" />
                    }
                    onClickMenuItem={() => {}}
                    menuItems={[
                      {
                        name: (
                          <button
                            type="button"
                            className="disabled:opacity-30 w-full text-left"
                            onClick={(): any => {
                              setSelectedVariant({
                                index: idx,
                                item: variant.variant,
                              })
                              handleModalOpen('addProductVariantModal')
                            }}
                          >
                            Edit
                          </button>
                        ),
                        value: '',
                      },
                      {
                        name: (
                          <button
                            type="button"
                            className="disabled:opacity-30 w-full text-left"
                            onClick={(): any => {
                              const variantsCopy: CreatedProductVariant[] =
                                JSON.parse(JSON.stringify(values.variants))
                              // Remove all occurrences of variants where id matches
                              setFieldValue(
                                'variants',
                                variantsCopy.filter(
                                  (variantC) =>
                                    variantC.variant._id ===
                                    variant.variant._id,
                                ),
                              )
                            }}
                          >
                            Delete
                          </button>
                        ),
                        value: '',
                      },
                    ]}
                  />,
                ],
              }
            })}
          />

          {values.variantCombinations.length > 0 && (
            <>
              <p className="text-lg font-medium">Variations</p>

              <TableComponent
                headers={[
                  'Variation name',
                  'Price',
                  'Quantity in stock',
                  'Active',
                  'Actions',
                ]}
                rows={values.variantCombinations.map((combination, idx) => {
                  const key = `${idx}`

                  return {
                    id: key,
                    content: [
                      combination.variantCombination
                        .map((item) => item.value)
                        .join(', '),
                      formatCurrency(combination.price),
                      combination.inventoryCount,
                      <Checkbox
                        checkStatus={combination.isActive}
                        text=""
                        handleChange={(status) => {
                          const variantCombinationsCopy: VariantCombination[] =
                            JSON.parse(
                              JSON.stringify(values.variantCombinations ?? []),
                            )

                          variantCombinationsCopy[idx] = {
                            ...variantCombinationsCopy[idx],
                            isActive: status,
                          }

                          setFieldValue(
                            'variantCombinations',
                            variantCombinationsCopy,
                          )
                        }}
                        key={`${key}-check`}
                      />,
                      <Dropdown
                        key={`${key}-controls`}
                        menuButton={
                          <Icon
                            id="ellipses"
                            height={18}
                            width={18}
                            className=""
                          />
                        }
                        onClickMenuItem={() => {}}
                        menuItems={[
                          {
                            name: (
                              <button
                                type="button"
                                className="disabled:opacity-30 w-full text-left"
                                onClick={(): any => {
                                  setSelectedCombination({
                                    index: idx,
                                    item: combination,
                                  })
                                  handleModalOpen('editVariationModal')
                                }}
                              >
                                Edit
                              </button>
                            ),
                            value: '',
                          },
                        ]}
                      />,
                    ],
                  }
                })}
              />
            </>
          )}
        </>
      )}

      <AddProductVariantModal
        open={modalOpen && modal === 'addProductVariantModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedVariant(undefined)
        }}
        onSuccessCallback={(item) => {
          let variants: CreatedProductVariant[] = JSON.parse(
            JSON.stringify(values.variants ?? []),
          )
          if (selectedVariant) {
            variants = [
              ...variants.filter(
                (variant) => variant.variant._id !== selectedVariant.item._id,
              ),
              ...item,
            ]
          } else {
            variants = [...variants, ...item]
          }
          setFieldValue('variants', variants)
          setSelectedVariant(undefined)
          handleModalClose()
        }}
        selectedVariant={selectedVariant?.item}
        productVariants={values.variants}
        loading={loadingVariants}
        variants={variants?.data.docs ?? []}
        mode={selectedVariant ? 'edit' : 'create'}
      />

      <EditVariantionCombinationModal
        open={modalOpen && modal === 'editVariationModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedCombination(undefined)
        }}
        onSuccessCallback={(item) => {
          const variantCombinationsCopy: VariantCombination[] = JSON.parse(
            JSON.stringify(values.variantCombinations ?? []),
          )
          if (selectedCombination) {
            variantCombinationsCopy[selectedCombination.index] = item
          }
          setFieldValue('variantCombinations', variantCombinationsCopy)
          setSelectedCombination(undefined)
          handleModalClose()
        }}
        variantCombination={selectedCombination?.item}
      />
    </div>
  )
}
