import React, { FC, ReactNode } from 'react'

export const PageHeader: FC<{ title: ReactNode; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <span
      className={`
        font-recoleta-bold
        leading-[140%]
        text-3xl
        block
        mb-10
        ${className}
        `}
    >
      {title}
    </span>
  )
}
