import React, { useState } from 'react'

import ProductsTable from './ProductsTable'

import { useGetAllProductsQuery } from '../../redux-toolkits/products/products.slice'
import { PageHeader } from '../../components/typography/PageHeader'

import { Button } from '../../components/button'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from '../../components/inputs/searchInput'
import { useModalControl } from '../../hooks/useModalControl'
import { BulkUploadProductsModal } from '../../components/modal/app_modals/bulkUploadProductsModal'

const ProductsPage: React.FC = () => {
  const [searchParams, setSearchParams] = useState<string>()
  const [currentPage, setCurrentPage] = useState<number>(1)

  const navigate = useNavigate()

  const { handleModalClose, handleModalOpen, modal, modalOpen, setModalOpen } =
    useModalControl()

  const {
    data: products,
    isFetching: loadingProducts,
    refetch,
  } = useGetAllProductsQuery({
    skip: (currentPage - 1) * NUMBER_OF_ITEMS_PER_PAGE,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: searchParams,
  })

  return (
    <div>
      <PageHeader title="Products" />

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total products count"
          count={products?.data.totalItems ?? 0}
        />
      </CountCardContainer>

      <div className="flex flex-wrap gap-4 items-center justify-between mb-10">
        <div className="!w-full md:!max-w-[400px]">
          <SearchInput
            searchFunction={(search) => {
              setSearchParams(search === '' ? undefined : search)
              setCurrentPage(1)
            }}
            placeholder="Search products or creators"
          />
        </div>

        <div className="flex items-center gap-2">
          <Button
            icon="file-upload"
            size="medium"
            label="Bulk upload"
            primary={false}
            onClick={() => {
              handleModalOpen('bulkUploadProductsModal')
            }}
          />

          <Button
            icon="plus"
            size="medium"
            label="Add new product"
            primary
            onClick={() => {
              navigate('/products/new')
            }}
          />
        </div>
      </div>

      <ProductsTable
        productList={products?.data.data || []}
        currentPage={Number(currentPage)}
        totalDataCount={products?.data.totalItems ?? 0}
        pageLimit={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={(page) => {
          setCurrentPage(page)
        }}
        loading={loadingProducts}
        refetch={() => {
          refetch()
        }}
      />

      <BulkUploadProductsModal
        open={modalOpen && modal === 'bulkUploadProductsModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
      />
    </div>
  )
}

export default ProductsPage
