import React from 'react'

interface Props {
  name?: string | undefined
  image?: string
  useRandomBgColor?: boolean
}

const colors = [
  '#23BD86',
  '#F2761B',
  '#AACC45',
  '#EA8D00',
  '#4F122A',
  '#0D99F2',
  '#B3434D',
  '#B85A15',
  '#1B9066',
  '#F45D2C',
]

function getRandomColor(colors: string[]) {
  const randomIndex = Math.floor(Math.random() * colors.length) // Random index
  return colors[randomIndex] // Return color at random index
}

export const Avatar: React.FC<Props> = ({
  image,
  name,
  useRandomBgColor = false,
}): JSX.Element => {
  let text = ''

  if (name !== undefined) {
    const payload = name?.split(' ')
    text = payload[0].charAt(0) + payload[1].charAt(0)
  }

  return (
    <div
      className={`
      h-10 w-10
      rounded-full 
      flex items-center justify-center  
      text-white
      text-sm
      `}
      style={{
        backgroundColor: useRandomBgColor ? getRandomColor(colors) : '#050301',
      }}
    >
      {image !== undefined ? (
        <img src={image} alt={name} className="h-full w-full" />
      ) : (
        text.toLocaleUpperCase()
      )}
    </div>
  )
}
