import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  CreateDiscount,
  DiscountType,
  SingleDiscount,
  StatusType,
  ValueType,
} from '../../../redux-toolkits/products/products.type'
import {
  AddDiscountSchema,
  addLocationManualSchema,
  CreateCategorySchema,
} from '../../../features/users/validate'
import {
  useCreateDiscountMutation,
  useCreateProductCategoryMutation,
  useEditDiscountMutation,
  useEditProductCategoryMutation,
  useLazyGetAllProductsQuery,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import AddressInput from '../../inputs/addressInput'
import { useAddAvailableLocationsMutation } from '../../../redux-toolkits/user/user.slice'
import { AddAvailableLocationsPayload } from '../../../redux-toolkits/user/user.type'
import { TabNav } from '../../nav/tabNav'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { SelectProductModal } from './selectProductModal'
import { EmptyState } from '../../emptyState/emptyState'
import { formatCurrency } from '../../../helpers'
import { PRIMARY_COLOR } from '../../../constants'
import { LoadingOval } from '../../spinner/Spinner'

const types: DiscountType[] = ['items', 'delivery', 'payment_method', 'cart']
const valueType: ValueType[] = ['percent', 'constant']
const statusType: StatusType[] = ['inactive', 'active']

export const AddDiscountModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  discount?: SingleDiscount
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  discount,
}) => {
  const [selectedDiscount, setSelectedDiscount] = useState<
    SingleDiscount | undefined
  >(discount)

  const [getAllProducts, { data, isFetching }] = useLazyGetAllProductsQuery()
  const [createDiscount, { isLoading: creating }] = useCreateDiscountMutation()
  const [editDiscount, { isLoading: editing }] = useEditDiscountMutation()

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      minimumAmount: selectedDiscount
        ? String(selectedDiscount.minimumAmount)
        : '',
      maximumAmount: selectedDiscount
        ? String(selectedDiscount.maximumAmount)
        : '',
      walletDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.walletDiscountAmount)
        : '',
      bankTransferDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.bankTransferDiscountAmount)
        : '',
      cardDiscountAmount: selectedDiscount
        ? String(selectedDiscount.discountAmount.cardDiscountAmount)
        : '',
      type: selectedDiscount ? selectedDiscount.type : '',
      items: selectedDiscount ? selectedDiscount.items : [],
      valueType: selectedDiscount ? selectedDiscount.valueType : '',
      code: selectedDiscount ? selectedDiscount.code : '',
      value: selectedDiscount ? selectedDiscount.value : '',
      usageLimit: selectedDiscount ? String(selectedDiscount.usageLimit) : '',
      userUsageLimit: selectedDiscount
        ? String(selectedDiscount.userUsageLimit)
        : '',
      status: selectedDiscount ? selectedDiscount.status : '',
    },
    enableReinitialize: true,
    validationSchema: AddDiscountSchema,
    onSubmit: (vals) => {
      const type: DiscountType = vals.type as DiscountType
      const valueType: ValueType = vals.valueType as ValueType
      const status: StatusType = vals.status as StatusType
      let payload: Partial<CreateDiscount> = {
        minimumAmount: Number(vals.minimumAmount),
        maximumAmount: Number(vals.maximumAmount),
        discountAmount: {
          walletDiscountAmount: Number(vals.walletDiscountAmount),
          bankTransferDiscountAmount: Number(vals.bankTransferDiscountAmount),
          cardDiscountAmount: Number(vals.cardDiscountAmount),
        },
        type,
        status,
      }

      if (type !== 'payment_method') {
        payload = {
          ...payload,
          value: vals.value,
          code: vals.code,
          items: Array.isArray(vals.items) ? vals.items : [],
          valueType,
          usageLimit: Number(vals.usageLimit),
          userUsageLimit: Number(vals.userUsageLimit),
        }
      }



      selectedDiscount?._id
        ? editDiscount({
            id: selectedDiscount._id,
            data: payload as CreateDiscount,
          })?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
        : createDiscount(payload as CreateDiscount)?.then((resp) => {
            if (resp.data?.success) {
              resetForm()
              onSuccessCallback()
            }
          })
    },
  })

  useEffect(() => {
    setSelectedDiscount(discount)
  }, [discount])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={`Add new discount`}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <Dropdown
            menuButton={
              <TextInput
                type="text"
                name="type"
                placeholder="Select type"
                value={values.type}
                onBlur={handleBlur}
                onChange={handleChange}
                hasIcon={false}
                readOnly
                extra={
                  <Icon
                    id={'left-caret'}
                    width={16}
                    height={16}
                    className="-rotate-90 text-sec-black"
                  />
                }
              />
            }
            onClickMenuItem={(selection) => {
              setFieldValue('type', selection.value)
            }}
            menuItems={types.map((type) => {
              return {
                name: type,
                value: type,
              }
            })}
          />

          <Dropdown
            menuButton={
              <TextInput
                type="text"
                name="status"
                placeholder="Select status"
                value={values.status}
                onBlur={handleBlur}
                onChange={handleChange}
                hasIcon={false}
                readOnly
                extra={
                  <Icon
                    id={'left-caret'}
                    width={16}
                    height={16}
                    className="-rotate-90 text-sec-black"
                  />
                }
              />
            }
            onClickMenuItem={(selection) => {
              setFieldValue('status', selection.value)
            }}
            menuItems={statusType.map((status) => {
              return {
                name: status,
                value: status,
              }
            })}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <TextInput
            title="Minimum amount"
            type="text"
            name="minimumAmount"
            placeholder="Minimum amount"
            value={values.minimumAmount}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.minimumAmount && touched.minimumAmount
                ? errors.minimumAmount
                : ''
            }
            hasIcon={false}
            className="!border-divider-gray"
          />

          <TextInput
            title="Maximum amount"
            type="text"
            name="maximumAmount"
            placeholder="Maximum amount"
            value={values.maximumAmount}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.maximumAmount && touched.maximumAmount
                ? errors.maximumAmount
                : ''
            }
            hasIcon={false}
            className="!border-divider-gray"
          />
        </div>

        {values.type === 'items' && (
          <div className="flex-1 overflow-y-scroll">
            {isFetching ? (
              <div className="w-full flex justify-center pt-10">
                <LoadingOval
                  loaderWidth="25"
                  loaderHeight="25"
                  color={PRIMARY_COLOR}
                />
              </div>
            ) : (
              <div>
                {data?.data?.data?.length && data?.data?.data?.length > 0 ? (
                  <div className="flex flex-col">
                    {data?.data?.data?.map((product, idx) => {
                      const key = `procuct-selection-option-${idx}-${product._id}`
                      return (
                        <div
                          key={key}
                          className="
                        flex gap-2 items-center
                        border-b border-divider-gray
                        py-2
                        first:pt-0
                      "
                          // onClick={() => {
                          //   onSuccessCallback && onSuccessCallback(product)
                          // }}
                        >
                          <img
                            alt={product.name}
                            src={product.images[0]}
                            className="w-[50px] h-[50px] object-cover rounded-md overflow-hidden border"
                          />
                          <div
                            className="
                          flex-1 
                        "
                          >
                            <span
                              className="
                            group
                            cursor-pointer
                            relative
                            text-sm text-black
                            font-normal
                            "
                            >
                              {product.name}

                              <hr
                                className="
                                absolute
                                w-0 opacity-0
                                group-hover:w-full group-hover:opacity-100
                                transition-all duration-300
                                border-[1.5px] border-black
                                rounded-full
                            "
                              />
                            </span>
                          </div>
                          <div className="text-sm text-sec-black">
                            {formatCurrency(product.price)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <EmptyState text="No products found" />
                )}
              </div>
            )}
          </div>
        )}

        {values.type !== 'payment_method' && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <TextInput
                title="Value"
                type="text"
                name="value"
                placeholder="Input value"
                value={values.value}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.value && touched.value ? errors.value : ''}
                hasIcon={false}
                className="!border-divider-gray"
              />

              <Dropdown
                menuButton={
                  <TextInput
                    type="text"
                    name="type"
                    placeholder="Select value type"
                    value={values.valueType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasIcon={false}
                    readOnly
                    extra={
                      <Icon
                        id={'left-caret'}
                        width={16}
                        height={16}
                        className="-rotate-90 text-sec-black"
                      />
                    }
                  />
                }
                onClickMenuItem={(selection) => {   
                  setFieldValue('valueType', selection.value)
                }}
                menuItems={valueType.map((value) => {
                  return {
                    name: value,
                    value: value,
                  }
                })}
              />
            </div>

            <TextInput
              title="Code"
              type="text"
              name="code"
              placeholder="Input Code"
              value={values.code}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.code && touched.code ? errors.code : ''}
              hasIcon={false}
              className="!border-divider-gray"
            />

            <div className="grid grid-cols-2 gap-4">
              <TextInput
                title="Usage Limit"
                type="text"
                name="usageLimit"
                placeholder="Usage limit"
                value={values.usageLimit}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.usageLimit && touched.usageLimit
                    ? errors.usageLimit
                    : ''
                }
                hasIcon={false}
                className="!border-divider-gray"
              />

              <TextInput
                title="User usage limit"
                type="text"
                name="userUsageLimit"
                placeholder="Input user limit"
                value={values.userUsageLimit}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.userUsageLimit && touched.userUsageLimit
                    ? errors.userUsageLimit
                    : ''
                }
                hasIcon={false}
                className="!border-divider-gray"
              />
            </div>
          </>
        )}

        {values.type === 'payment_method' && (
          <>
            <TextInput
              title="Wallet discount amount"
              type="text"
              name="walletDiscountAmount"
              placeholder="Wallet discount amount"
              value={values.walletDiscountAmount}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.walletDiscountAmount && touched.walletDiscountAmount
                  ? errors.walletDiscountAmount
                  : ''
              }
              hasIcon={false}
              className="!border-divider-gray"
            />

            <TextInput
              title="Bank transfer discount amount"
              type="text"
              name="bankTransferDiscountAmount"
              placeholder="Bank transfer discount amount"
              value={values.bankTransferDiscountAmount}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.bankTransferDiscountAmount &&
                touched.bankTransferDiscountAmount
                  ? errors.bankTransferDiscountAmount
                  : ''
              }
              hasIcon={false}
              className="!border-divider-gray"
            />

            <TextInput
              title="Card discount amount"
              type="text"
              name="cardDiscountAmount"
              placeholder="Card discount amount"
              value={values.cardDiscountAmount}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={
                errors.cardDiscountAmount && touched.cardDiscountAmount
                  ? errors.cardDiscountAmount
                  : ''
              }
              hasIcon={false}
              className="!border-divider-gray"
            />
          </>
        )}

        <Button
          label="Proceed"
          type="submit"
          className="mt-10 w-full"
          disabled={creating || editing || !isValid
            // ||
            // (values.type === 'payment_method' && (
            // !values.walletDiscountAmount ||
            // !values.bankTransferDiscountAmount ||
            // !values.cardDiscountAmount
            // ))
          }
          loading={creating || editing}
          primary
        />
      </form>
    </ModalComponent>
  )
}
