import React, { ChangeEvent, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { FormikErrors, useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddProductVariantSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import {
  ComboItem,
  CreatedProductVariant,
  ProductVariant,
  SingleVariant,
} from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { removeElementAtIndex } from '../../../helpers'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { ImageBanner } from '../../images/imageBanner'
import { useModalControl } from '../../../hooks/useModalControl'
import { ImageUploadPreviewModal } from './ImagePreviewModal'

export const AddProductVariantModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: CreatedProductVariant[]) => void
  mode: 'edit' | 'create'
  selectedVariant?: SingleVariant
  productVariants?: CreatedProductVariant[]
  loading?: boolean
  variants: SingleVariant[]
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  mode,
  selectedVariant,
  productVariants,
  loading,
  variants,
}) => {
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [uploadValueIndex, setUploadValueIndex] = useState<number>()

  const { handleModalOpen, modal, modalOpen, setModalOpen } = useModalControl()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      variant: selectedVariant,
      values:
        productVariants?.filter(
          (item) => item.variant._id === selectedVariant?._id,
        ) ?? [],
    },
    enableReinitialize: true,
    validationSchema: AddProductVariantSchema,
    onSubmit: (values, { resetForm }) => {
      const itemsToAdd = values.values.map((val) => {
        return { ...val, variant: values.variant as SingleVariant }
      })

      onSuccessCallback(itemsToAdd)
      resetForm()
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add Option' : 'Edit Option'}
    >
      <div className="flex flex-col gap-4">
        <Dropdown
          loading={loading}
          className="w-full"
          menuClassName="max-h-[200px]"
          widthClass="!w-full"
          menuButton={
            <TextInput
              type="text"
              name="variant"
              placeholder="Choose variant"
              value={values.variant?.name ?? ''}
              onBlur={handleBlur}
              onChange={handleChange}
              errors={errors.variant && touched.variant ? errors.variant : ''}
              hasIcon={false}
              readOnly
              extra={
                <Icon
                  id={'left-caret'}
                  width={16}
                  height={16}
                  className="-rotate-90 text-sec-black"
                />
              }
              className="!border-divider-gray !w-full"
            />
          }
          onClickMenuItem={(selection) => {
            setFieldValue('variant', selection.value)
          }}
          menuItems={variants.map((variant) => {
            return {
              name: variant.name,
              value: variant,
            }
          })}
        />

        <div className="flex flex-col gap-4">
          {values.values.map((value, index) => {
            const key = `${index}`

            return (
              <div
                key={key}
                className="flex flex-col gap-4 border p-2 rounded-lg"
              >
                <div className="flex items-center justify-between gap-4">
                  <p className="text-black font-medium text-sm">
                    Option {index + 1}
                  </p>

                  <button
                    type="button"
                    onClick={() => {
                      const valuesCopy = JSON.parse(
                        JSON.stringify(values.values),
                      )

                      setFieldValue(
                        'values',
                        removeElementAtIndex(valuesCopy, index),
                      )
                    }}
                  >
                    <Icon
                      id="close-icon"
                      width={14}
                      height={14}
                      className="text-danger"
                    />
                  </button>
                </div>

                <div className="flex-1 grid grid-cols-2 gap-4">
                  <TextInput
                    type="text"
                    name={`values[${index}].value`}
                    placeholder="Value"
                    value={String(value?.value ?? '')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors?.values && touched?.values
                        ? (
                            errors?.values as FormikErrors<CreatedProductVariant>[]
                          )[index]?.value
                        : ''
                    }
                    hasIcon={false}
                    className=""
                  />

                  <TextInput
                    type="text"
                    name={`values[${index}].price`}
                    placeholder="Price"
                    value={String(value?.price ?? '')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors?.values && touched?.values
                        ? (
                            errors?.values as FormikErrors<CreatedProductVariant>[]
                          )[index]?.price
                        : ''
                    }
                    hasIcon={false}
                    className=""
                  />

                  {(value.images ?? []).length > 0 && (
                    <div className="relative w-full col-span-full grid grid-cols-2 gap-2">
                      {value.images?.map((url, idx) => {
                        const key = `${idx}`

                        return (
                          <ImageBanner
                            imageUrl={url}
                            loading={false}
                            className="
                            !w-full !max-h-[150px]
                            "
                            errors={''}
                            key={key}
                            onDelete={() => {
                              const imagesCopy = JSON.parse(
                                JSON.stringify(value.images),
                              )

                              setFieldValue(
                                `values[${index}].images`,
                                removeElementAtIndex(imagesCopy, idx),
                              )
                            }}
                          />
                        )
                      })}
                    </div>
                  )}

                  <ImageUploadButton
                    handleChange={(e) => {
                      setUploadValueIndex(index)
                      handleFileChange(e)
                    }}
                    showText
                    icon={'pic-icon'}
                    iconDim={52}
                    text={`Upload ${values.values[index]?.value ?? ''} Images`}
                    className="
                    !relative !w-full
                    !m-0 !top-0 !right-0
                    !h-fit
                    !rounded-lg
                    !col-span-full
                    "
                  />
                </div>
              </div>
            )
          })}

          <button
            type="button"
            className="flex items-center gap-2 mt-2 font-medium"
            onClick={() => {
              setFieldValue('values', [...values.values, {}])
            }}
          >
            Add Option
            <Icon
              id="plus"
              width={20}
              height={20}
              className="border rounded-full p-0.5 border-black"
            />
          </button>
        </div>

        <Button
          label="Proceed"
          type="button"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
          onClick={() => {
            handleSubmit()
          }}
        />

        {uploadedImage && uploadValueIndex !== undefined && (
          <ImageUploadPreviewModal
            image={uploadedImage}
            open={modal === 'imageUploadPreviewModal' && modalOpen}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              setUploadedImage(undefined)
              setUploadValueIndex(undefined)
            }}
            onSuccessCallback={(url) => {
              setFieldValue(`values[${uploadValueIndex}].images`, [
                ...(values.values[uploadValueIndex].images ?? []),
                url,
              ])
            }}
          />
        )}
      </div>
    </ModalComponent>
  )
}
