import React, { useEffect, useState } from 'react'
import { formatTime, parseTimeToDate } from '../../helpers'
import {
  AvailableHours,
  BusinessAvailableHours,
  DaysOfTheWeek,
} from '../../redux-toolkits/vendors/vendors.types'
import Icon from '../icon'
import CustSwitch from '../switch'
import Dropdown from '../inputs/dropdown'
import { TextInput } from '../inputs/textInput'
import { Button } from '../button'

const times = [
  { name: '12 AM', value: '12:00 AM' },
  { name: '1 AM', value: '01:00 AM' },
  { name: '2 AM', value: '02:00 AM' },
  { name: '3 AM', value: '03:00 AM' },
  { name: '4 AM', value: '04:00 AM' },
  { name: '5 AM', value: '05:00 AM' },
  { name: '6 AM', value: '06:00 AM' },
  { name: '7 AM', value: '07:00 AM' },
  { name: '8 AM', value: '08:00 AM' },
  { name: '9 AM', value: '09:00 AM' },
  { name: '10 AM', value: '10:00 AM' },
  { name: '11 AM', value: '11:00 AM' },
  { name: '12 PM', value: '12:00 PM' },
  { name: '1 PM', value: '01:00 PM' },
  { name: '2 PM', value: '02:00 PM' },
  { name: '3 PM', value: '03:00 PM' },
  { name: '4 PM', value: '04:00 PM' },
  { name: '5 PM', value: '05:00 PM' },
  { name: '6 PM', value: '06:00 PM' },
  { name: '7 PM', value: '07:00 PM' },
  { name: '8 PM', value: '08:00 PM' },
  { name: '9 PM', value: '09:00 PM' },
  { name: '10 PM', value: '10:00 PM' },
  { name: '11 PM', value: '11:00 PM' },
]

export const AvailableHoursView = ({
  availableHours,
  className,
  loading = false,
  onModify = () => {},
}: {
  availableHours?: BusinessAvailableHours
  className?: string
  loading?: boolean
  onModify?: (data: BusinessAvailableHours) => void
}) => {
  const [isEdit, setIsEdit] = useState(false)

  const [payload, setPayload] = useState<BusinessAvailableHours | undefined>(
    availableHours
      ? (Object.entries(availableHours).reduce(
          (acc: Partial<BusinessAvailableHours>, [day, details]) => {
            const { _id, ...rest } = details
            acc[day as DaysOfTheWeek] = rest
            return acc
          },
          {} as Partial<BusinessAvailableHours>,
        ) as BusinessAvailableHours)
      : undefined,
  )

  const resetPayload = () => {
    setPayload(availableHours)
  }

  return payload ? (
    <div className={`${className}`}>
      <div className="flex items-center justify-between">
        <span className="font-medium text-base">Business Opening Hours</span>
        <button
          type="button"
          onClick={() => {
            if (isEdit) {
              setIsEdit(false)
              resetPayload()
            } else {
              setIsEdit(true)
            }
          }}
          className="flex items-center gap-2"
        >
          {isEdit ? (
            'Cancel'
          ) : (
            <>
              <span>Edit</span> <Icon id="edit" width={20} height={20} />
            </>
          )}
        </button>
      </div>

      <div className="flex flex-col gap-4 w-fit mt-4">
        {Object.keys(payload).map((day, idx) => {
          const details = payload[day as DaysOfTheWeek]
          return (
            <div
              key={`${idx}`}
              className="flex flex-row items-center gap-4 w-fit"
            >
              <p className={`w-fit min-w-[88px] capitalize`}>{day}</p>

              {!isEdit && <p>•</p>}

              {isEdit ? (
                <div
                  className="flex items-center justify-center gap-4 w-fit"
                  key={`${idx}-controls`}
                >
                  <CustSwitch
                    checked={details.open}
                    onChange={() => {
                      setPayload({
                        ...payload,
                        [day]: {
                          ...details,
                          open: !details.open,
                        },
                      })
                    }}
                  />

                  <span>{details.open ? 'Open' : 'Closed'}</span>
                </div>
              ) : (
                <p className={`w-fit min-w-[53px]`}>
                  {details.open ? 'Open' : 'Closed'}
                </p>
              )}

              {!isEdit && <p>•</p>}

              {details.open && !isEdit ? (
                <p>{`${formatTime(
                  parseTimeToDate(details.openingTime ?? ''),
                )} to ${formatTime(
                  parseTimeToDate(details.closingTime ?? ''),
                )}`}</p>
              ) : undefined}

              {isEdit && (
                <div className="flex items-center gap-2">
                  <Dropdown
                    menuClassName="!max-h-[300px]"
                    menuButton={
                      <TextInput
                        hasIcon={false}
                        value={formatTime(
                          parseTimeToDate(details.openingTime ?? ''),
                        )}
                        readOnly
                        placeholder="Opening Time"
                      />
                    }
                    menuItems={times}
                    onClickMenuItem={(selected) => {
                      setPayload({
                        ...payload,
                        [day]: {
                          ...details,
                          openingTime: formatTime(
                            parseTimeToDate(selected.value as string),
                            'HH:mm',
                          ),
                        },
                      })
                    }}
                  />

                  <p>to</p>

                  <Dropdown
                    menuClassName="!max-h-[300px]"
                    menuButton={
                      <TextInput
                        hasIcon={false}
                        value={formatTime(
                          parseTimeToDate(details.closingTime ?? ''),
                        )}
                        readOnly
                        placeholder="Closing Time"
                      />
                    }
                    menuItems={times}
                    onClickMenuItem={(selected) => {
                      setPayload({
                        ...payload,
                        [day]: {
                          ...details,
                          closingTime: formatTime(
                            parseTimeToDate(selected.value as string),
                            'HH:mm',
                          ),
                        },
                      })
                    }}
                  />
                </div>
              )}
            </div>
          )
        })}
        <p></p>
      </div>

      {isEdit && (
        <Button
          type="button"
          primary
          loading={loading}
          disabled={loading}
          label="Save"
          className="!w-full !max-w-[160px]"
          onClick={async () => {
            onModify(payload)
          }}
        />
      )}
    </div>
  ) : undefined
}
