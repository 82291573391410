import React, { ReactNode, useEffect, useRef, useState } from 'react'
import Icon from '../icon'

export const Checkbox = ({
  checkStatus,
  handleChange = () => {},
  text = '',
  textPosition = 'right',
  className = 'right',
}: {
  checkStatus: boolean
  handleChange: (status: boolean) => void
  text?: ReactNode
  textPosition?: 'left' | 'right'
  className?: string
}) => {
  const [checked, setChecked] = useState(checkStatus)

  const checkBoxRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setChecked(checkStatus)
  }, [checkStatus])

  return (
    <div
      className={`
      w-fit cursor-pointer flex items-center gap-4
      ${className}
      `}
      onClick={() => {
        checkBoxRef?.current?.click()
      }}
    >
      {textPosition === 'left' && <span className="block">{text}</span>}

      <Icon
        id={checked ? 'checkbox-active' : 'checkbox-inactive'}
        width={16}
        height={16}
      />

      {textPosition === 'right' && <span className="block">{text}</span>}
      <input
        type="checkbox"
        className="hidden"
        ref={checkBoxRef}
        checked={checked}
        onChange={(e) => {
          const initialCheckStatus = e.target.checked
          handleChange(initialCheckStatus)
          setChecked(initialCheckStatus)
        }}
      />
    </div>
  )
}
