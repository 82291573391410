import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import { useParams } from 'react-router-dom'
import {
  useGetSingleSettlementQuery,
  useRetrySettlementMutation,
} from '../../redux-toolkits/orders/orders.slice'
import { formatCurrency, getVendorName } from '../../helpers'
import { partyMappings, SETTLEMENTSTATUS } from '../../constants'
import { Status } from '../../components/cards/statusTag'
import { StatusType } from '../../redux-toolkits/products/products.type'
import { StatusTypes } from '../../types/types'
import { format } from 'date-fns'
import { Button } from '../../components/button'
import Spinner from '../../components/spinner/Spinner'
import { useModalControl } from '../../hooks/useModalControl'
import { ConfirmRetrySettlementModal } from '../../components/modal/app_modals/confirmRetrySettlementModal'
import { retry } from '@reduxjs/toolkit/query'
import { Avatar } from '../../components/avatar/Avatar'

export const SingleSettlementPage = () => {
  const { id } = useParams()

  const [details, setDetails] = useState<
    { title: string; value: ReactNode | string }[]
  >([])

  const { data, isFetching, isSuccess, refetch } = useGetSingleSettlementQuery({
    id: id ?? '',
  })

  const { modal, modalOpen, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  useEffect(() => {
    if ((data?.data, isSuccess)) {
      const item = data.data

      setDetails([
        {
          title: 'Party',
          value:
            item.party === 'VENDOR' ? (
              <div
                key={`${item._id}-vendor-party`}
                className="flex gap-2 items-center"
              >
                {item.vendor.logo ? (
                <img
                src={item.vendor.logo ?? ''}
                className="object-cover w-[40px] h-[40px] rounded-full overflow-hidden"
              />
                ): (
                    <Avatar name={`${item.vendor.firstName}`} />
                )}

                <span>{getVendorName(item.vendor)}</span>
              </div>
            ) : (
              (partyMappings[
                item.party as keyof typeof partyMappings
              ] as string)
            ),
        },
        {
          title: 'Transaction ID:',
          value: item._id,
        },
        {
          title: 'Order ID:',
          value: `#${item.order.orderNumber}`,
        },
        {
          title: 'Payment reference:',
          value: '',
        },
        {
          title: 'Account number:',
          value: item.accountNumber,
        },
        {
          title: 'Bank name:',
          value: item.bankName,
        },
        {
          title: 'Account name:',
          value: item.accountName,
        },
        {
          title: 'Date:',
          value: `${format(item.createdAt, 'dd/MM/yyyy h:mma')}`,
        },
        {
          title: 'Transaction status:',
          value: (
            <Status
              text={item.status.toLowerCase()}
              type={
                (SETTLEMENTSTATUS.find(
                  (status) =>
                    status.name.toLowerCase() === item.status.toLowerCase(),
                )?.type ?? 'warn') as StatusTypes
              }
              key={`${item._id}-status`}
            />
          ),
        },
        {
          title: 'Order subtotal:',
          value: formatCurrency(item?.order?.amount >> 0),
        },
        {
          title: 'Percentage:',
          value: `${item?.percentage}%`,
        },
        {
          title: 'Processing fee',
          value: '',
        },
        {
          title: 'Amount settled:',
          value: formatCurrency(item?.amount ?? 0),
        },
        {
          title: 'Provider:',
          value: item?.provider,
        },
      ])
    }
  }, [data])

  return (
    <Layout>
      <div className="flex items-center justify-between gap-4 mb-10">
        <PageHeader title="Settlement Transaction Details" className="!mb-0" />

        {data?.data.status === 'FAILED' && (
          <Button
            type="button"
            label="Retry payment"
            primary
            className="!w-full max-w-[190px]"
            onClick={() => {
              handleModalOpen('confirmRetryModal')
            }}
          />
        )}
      </div>

      {isFetching ? (
        <Spinner fullScreen={false} />
      ) : (
        <div className="flex flex-col w-full max-w-[800px]">
          {details.map((detail, idx) => {
            const key = `${detail.title}-${idx}`

            return (
              <div
                className="
              py-4
              first:pt-0 last:pb-0
              border-b last:border-b-0
              flex gap-4
              items-center justify-between
            "
                key={key}
              >
                <span className="text-sec-black">{detail.title}</span>
                <span className="text-black font-medium">{detail.value}</span>
              </div>
            )
          })}
        </div>
      )}

      <ConfirmRetrySettlementModal
        open={modal === 'confirmRetryModal' && modalOpen}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          refetch()
        }}
        settlementId={id ?? ''}
      />
    </Layout>
  )
}
