import React, { useState } from 'react'
import { Button } from '../../components/button'
import { PageHeader } from '../../components/typography/PageHeader'
import { useModalControl } from '../../hooks/useModalControl'
import { useGetDiscountsQuery } from '../../redux-toolkits/products/products.slice'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { TableComponent } from '../../components/table'
import { formatCurrency } from '../../helpers'
import { format } from 'date-fns'
import { Status } from '../../components/cards/statusTag'
import { SingleDiscount } from '../../redux-toolkits/products/products.type'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { AddDiscountModal } from '../../components/modal/app_modals/addDiscountModal'
import { QueryProps } from '../../types/types'
import { SendVouchersModal } from '../../components/modal/app_modals/sendVouchersModal'

export const Discounts = () => {
  const [query, setQuery] = useState<QueryProps>({
    skip: 0,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: undefined,
  })
  const [selected, setSelected] = useState<SingleDiscount>()

  const { modalOpen, modal, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const { data, isFetching, refetch } = useGetDiscountsQuery(query)

  return (
    <div>
      <div className="flex items-center gap-4 justify-between">
        <PageHeader title="Discounts & Vouchers" />

        <div className="flex flex-wrap gap-4 items-center justify-end mb-10">
          <Button
            size="medium"
            label="Send vouchers"
            primary={false}
            onClick={() => {
              handleModalOpen('sendVouchersModal')
            }}
          />

          <Button
            icon="plus"
            size="medium"
            label="Add new discount"
            primary
            onClick={() => {
              handleModalOpen('addDiscountModal')
            }}
          />
        </div>
      </div>

      <TableComponent
        headers={[
          'Id',
          'Type',
          'Minumum amount',
          'Maximum amount',
          'Status',
          'creation date',
          'Actions',
        ]}
        rows={
          data?.data.discounts.map((discount) => {
            return {
              id: discount._id,
              content: [
                <button
                  key={`${discount._id}-button`}
                  className="
                "
                  type="button"
                  onClick={() => {
                    setSelected(discount)
                    handleModalOpen('addDiscountModal')
                  }}
                >
                  {discount._id}
                </button>,
                discount.type ?? '',
                formatCurrency(discount.minimumAmount),
                formatCurrency(discount.maximumAmount),
                <Status
                  key={`${discount._id}-status`}
                  text={discount.status}
                  type={discount.status === 'active' ? 'success' : 'fail'}
                />,
                format(new Date(discount.createdAt), 'dd/MM/yyyy h:mma'),
                <Dropdown
                  key={`${discount._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(): any => {
                            setSelected(discount)
                            handleModalOpen('addDiscountModal')
                          }}
                        >
                          Edit
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        loading={isFetching}
        currentPage={Number(query.skip) / Number(query.limit) + 1}
        totalDataCount={data?.data.count}
        pageLimit={Number(query.limit)}
        onPageChange={(page) => {
          setQuery({ ...query, skip: (page - 1) * Number(query.limit) })
        }}
      />

      <AddDiscountModal
        open={modalOpen && modal === 'addDiscountModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelected(undefined)
        }}
        onSuccessCallback={() => {
          handleModalClose()
          refetch()
        }}
        edit={selected !== undefined}
        discountId={selected?._id}
      />

      <SendVouchersModal
        open={modalOpen && modal === 'sendVouchersModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={() => {
          handleModalClose()
        }}
      />
    </div>
  )
}
