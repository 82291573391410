import * as Yup from 'yup'

export const UserFormSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const EditUserFormSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
})

export const CreateCategorySchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  comboTagLine: Yup.string().required('Required'),
  recommendedTagLine: Yup.string().required('Required'),
})

export const addLocationManualSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  long: Yup.number().required('Required'),
  lat: Yup.number().required('Required'),
})

export const addPickUpLocationManualSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
})

export const CreateTagSchema = Yup.object().shape({
  name: Yup.string().required('Tag name is required'),
  description: Yup.string().required('Tag description is required'),
})

export const AddComboItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.number().min(1).required('Price is required'),
  minimumQuantity: Yup.number().min(0).required('Minimum quantity is required'),
  maximumQuantity: Yup.number().min(1).required('Maximum quantity is required'),
  required: Yup.boolean().required('Required'),
})

export const AddDeliveryLocationSchema = Yup.object().shape({
  locationId: Yup.string().required('Required'),
  minimumDeliveryTime: Yup.number().min(0).required('Required'),
  maximumDeliveryTime: Yup.number().min(0).required('Required'),
  type: Yup.string().required('Required'),
  deliveryTime: Yup.string().required('Required'),
})

export const AddProductVariantSchema = Yup.object().shape({
  variant: Yup.string().required('Variant is required'),
  value: Yup.string().required('Value is required'),
  price: Yup.number().min(1).required('Price is required'),
})

export const UpdateProductSchema = Yup.object().shape({
  reason: Yup.object({
    value: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Reason is required'),
  quantity: Yup.string().required('Quantity is required'),
})

export const UpdateProductPriceSchema = Yup.object().shape({
  reason: Yup.object({
    value: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Reason is required'),
  price: Yup.number().min(1).required('Price is required'),
})

export const CreateVariantSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
})

export const AddSubVariantLabelSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

export const CreateProductSchema = Yup.object().shape({
  name: Yup.string().required('Product name is required'),
  category: Yup.object({
    _id: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Category is required'),
  description: Yup.string().required('Product description is required'),
  images: Yup.array()
    .of(Yup.string())
    .required('Upload at least one image for this product'),
  price: Yup.string().required('Product price is required'),
  quantityInStock: Yup.string().required('Product stock quantity is required'),
  vendor: Yup.object({
    _id: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Vendor is required'),
  tags: Yup.array().of(
    Yup.object({
      _id: Yup.string().required(),
      name: Yup.string().required(),
    }),
  ),
  isCombo: Yup.boolean(),
  comboItems: Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
      price: Yup.number().required(),
      minimumQuantity: Yup.number().required(),
      maximumQuantity: Yup.number().required(),
    }),
  ),
  isVariant: Yup.boolean(),
  variants: Yup.array().of(
    Yup.object({
      price: Yup.number().required(),
      value: Yup.string().required(),
      variant: Yup.object({
        name: Yup.string().required(),
        _id: Yup.string().required(),
      }),
    }),
  ),
  lowStockAlert: Yup.boolean().required(),
  lowStockQuantity: Yup.number()
    .min(0)
    .when('lowStockAlert', (lowStockAlert, schema) => {
      return lowStockAlert[0] ? schema.required() : schema.optional()
    }),
  weight: Yup.number().min(0).optional(),
  instantDeliveryEnabled: Yup.boolean().required(),
  isMarkupEnabled: Yup.boolean().required(),
  markupValue: Yup.number()
    .min(0)
    .when('isMarkupEnabled', (isMarkupEnabled, schema) => {
      return isMarkupEnabled[0] ? schema.required() : schema.optional()
    }),
})

export const CreateSubVariantSchema = Yup.object().shape({
  value: Yup.string().required('Required'),
  vendor: Yup.object({
    _id: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
  }).required('Required'),
})

export const AddDiscountSchema = Yup.object().shape({
  minimumAmount: Yup.number().min(1).required('minimumAmount is required'),
  maximumAmount: Yup.number().min(1).required('maximumAmount is required'),
  walletDiscountAmount: Yup.number()
    .min(0)
    .when('type', {
      is: 'payment_method',
      then: (schema) => schema.required('Wallet discount amount is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  bankTransferDiscountAmount: Yup.number()
    .min(0)
    .when('type', {
      is: 'payment_method',
      then: (schema) =>
        schema.required('Bank transfer discount amount is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  cardDiscountAmount: Yup.number()
    .min(0)
    .when('type', {
      is: 'payment_method',
      then: (schema) => schema.required('Card discount amount is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  type: Yup.string()
    .oneOf(
      ['items', 'delivery', 'payment_method', 'cart'],
      'Invalid discount type',
    )
    .required('Discount type is required'),
  status: Yup.string()
    .oneOf(['inactive', 'active'], 'Invalid status')
    .required('Select a status'),
  valueType: Yup.string()
    .when('type', {
      is: (type: string) => type !== 'payment_method',
      then: (schema) => schema.required('Value type is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .oneOf(['percent', 'constant']),
  value: Yup.string()
    .when('type', {
      is: (type: string) => type !== 'payment_method',
      then: (schema) => schema.required('Card discount amount is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .required('Value is required'),
  code: Yup.string().when('type', {
    is: (type: string) => type !== 'payment_method',
    then: (schema) => schema.required('Input code'),
    otherwise: (schema) => schema.notRequired(),
  }),
  usageLimit: Yup.number()
    .when('type', {
      is: (type: string) => type !== 'payment_method',
      then: (schema) => schema.required('Usage Limit is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .min(1),
  userUsageLimit: Yup.number()
    .when('type', {
      is: (type: string) => type !== 'payment_method',
      then: (schema) => schema.required('User usage is required'),
      otherwise: (schema) => schema.notRequired(),
    })
    .min(1),
  items: Yup.array()
    .of(Yup.string().required('Each item must have an ID'))
    .when('type', {
      is: 'items',
      then: (schema) => schema.min(1).required('Items are required'),
      otherwise: (schema) => schema.notRequired(),
    }),
})

export const AddWalletAmountSchema = Yup.object().shape({
  amount: Yup.number().typeError('Enter a valid amount').required('Required'),
})

export const RetrySettlementSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
})
