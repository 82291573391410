import React, { useState, type ReactNode } from 'react'
import TopNavigation from '../components/top-nav/TopNavigation'
import { SideBar } from '../components/sidebar'
import { PageHeader } from '../components/typography/PageHeader'
import OrderSSEComponent from '../components/sse/orderSSEComponent'

interface Props {
  children: ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <div
      className="
      h-screen w-full 
      bg-frost
      overflow-hidden
      flex flex-col
      "
    >
      <TopNavigation navOpen={navOpen} setNavOpen={setNavOpen} />

      <div className="flex-1 flex overflow-hidden relative">
        {/* Overlay */}
        {navOpen && (
          <div className="absolute inset-0 bg-black/60 !z-[997] xl:hidden" />
        )}
        <SideBar navOpen={navOpen} setNavOpen={setNavOpen} />
        <div
          className="
          flex-1 
          overflow-y-auto 
          px-4 md:px-10 py-4 md:py-10 pb-20 md:pb-40
          "
        >
          {children}
        </div>
      </div>

      <OrderSSEComponent />
    </div>
  )
}

export default Layout
