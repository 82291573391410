import React, { useState } from 'react'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import {
  useCreateFulfilmentCenterMutation,
  useGetFulfilmentCentersQuery,
} from '../../redux-toolkits/vendors/vendors.slice'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { QueryProps } from '../../types/types'
import { TableComponent } from '../../components/table'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { AddLocationModal } from '../../components/modal/app_modals/addLocationModal'
import { useModalControl } from '../../hooks/useModalControl'
import { addFulfilmentCenterSchema } from '../../validation/validate'

export const FulfilmentCenterPage = () => {
  const [query, setQuery] = useState<QueryProps>({
    page: 1,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
    search: undefined,
  })

  const {
    data: centers,
    isFetching: fetchingCenters,
    refetch: refetchCenters,
  } = useGetFulfilmentCentersQuery({})

  const { modalOpen, modal, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const [addCenter, { isLoading: addingCenter }] =
    useCreateFulfilmentCenterMutation()

  return (
    <Layout>
      <div className="flex w-full items-center gap-4 flex-wrap justify-between">
        <PageHeader title="Fulfillment Center " className="!mb-0" />

        <Button
          type="button"
          label="Add New Fulfilment Center"
          onClick={() => {
            handleModalOpen('addNewFulfilmentCenterModal')
          }}
          primary
          className="!w-full !max-w-[282px]"
        />
      </div>

      <CountCardContainer>
        <CountCard
          count={centers?.data?.total ?? 0}
          text="Total Fulfillment Centers"
          icon=""
          isCurrency={false}
        />
      </CountCardContainer>

      <TableComponent
        loading={fetchingCenters}
        headers={[
          'Fulfilment Center Name',
          'Location',
          'Number of Creators',
          'Actions',
        ]}
        pageLimit={Number(query.limit)}
        isEmpty={[undefined, 0].includes(centers?.data?.docs?.length)}
        onPageChange={(p) => {
          setQuery({
            ...query,
            page: p,
          })
        }}
        rows={
          centers?.data?.docs?.map((fulfilmentCenter) => {
            return {
              id: fulfilmentCenter._id,
              content: [
                fulfilmentCenter.name,
                fulfilmentCenter.address,
                'N/A',
                <Dropdown
                  key={`${fulfilmentCenter._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(): any => {}}
                        >
                          Manage creators
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
      />

      <AddLocationModal
        open={modalOpen && modal === 'addNewFulfilmentCenterModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={async (locationData, resetForm) => {
          const payload = {
            name: locationData.name,
            coordinates: [
              Number(locationData.long),
              Number(locationData.lat),
            ] as [number, number],
            address: locationData.address,
          }

          const resp = await addCenter(payload)

          if (resp.data?.success) {
            resetForm()
            handleModalClose()
            refetchCenters()
          }
        }}
        title="Add Fulfilment Center"
        fields={['name', 'address', 'coordinates']}
        schema={addFulfilmentCenterSchema}
        loading={addingCenter}
      />
    </Layout>
  )
}
