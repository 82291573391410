import React, { useState } from 'react'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import { useFetchWalletAmountsQuery } from '../../redux-toolkits/user/user.slice'
import Spinner from '../../components/spinner/Spinner'
import { Button } from '../../components/button'
import { useModalControl } from '../../hooks/useModalControl'
import { formatCurrency } from '../../helpers'
import { AddWalletAmountModal } from '../../components/modal/app_modals/addWalletAmountModal'
import { ConfirmDeleteModal } from '../../components/modal/app_modals/confirmDeleteModal'
import { WalletAmount } from '../../redux-toolkits/user/user.type'
import Icon from '../../components/icon'

export const WalletSettings = () => {
  const [selectedAmount, setSelectedAMount] = useState<WalletAmount>()

  const { modalOpen, modal, handleModalClose, handleModalOpen, setModalOpen } =
    useModalControl()

  const { data, isFetching, refetch } = useFetchWalletAmountsQuery()

  if (isFetching) {
    return (
      <div
        className="
        flex items-center justify-center
      "
      >
        <Spinner fullScreen={false} />
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-10">
      <div className="flex items-center justify-between gap-4">
        <HeaderSubheader
          title="Wallet"
          text="Configure quick fund amounts, etc."
          className="max-w-fit"
        />

        <Button
          label={'Add quick fund amount'}
          primary
          onClick={() => {
            handleModalOpen('addWalletAmountModal')
          }}
          size="medium"
          icon="plus"
          className="min-w-fit"
          type="button"
        />
      </div>

      <div className="flex flex-wrap gap-3 items-center">
        {data?.data.docs.map((item, idx) => {
          const key = `Amount-${idx}`

          return (
            <div
              key={key}
              className="
              py-5 px-4
              bg-bg-gray
              border border-divider-gray
              rounded-xl
              text-lg
              font-medium
              relative group
              flex items-center gap-4
              "
            >
              {formatCurrency(item.amount)}
              <button
                type="button"
                className="
                bg-danger rounded-full
                text-white
                overflow-hidden
                p-0 group-hover:p-1
                w-0 group-hover:w-max
                transition-all duration-300
                "
                onClick={() => {
                  setSelectedAMount(item)
                  handleModalOpen('confirmAmountDeletionModal')
                }}
              >
                <Icon width={14} height={14} id="bin" />
              </button>
            </div>
          )
        })}
      </div>

      <AddWalletAmountModal
        open={modalOpen && modal === 'addWalletAmountModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
        }}
        onSuccessCallback={() => {
          refetch()
        }}
      />

      <ConfirmDeleteModal
        open={modalOpen && modal === 'confirmAmountDeletionModal'}
        setOpen={setModalOpen}
        message="Are you sure you want to proceed? This action cannot be reversed."
        onCloseCallback={() => {
          handleModalClose()
          setSelectedAMount(undefined)
        }}
        onConfirm={() => {}}
        loading={false}
      />
    </div>
  )
}
