import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { PageNotFound } from './features/404'
import ForgotPasswordPage from './features/auth/forgot-password'
import LoginPage from './features/auth/login'
import ResetPasswordPage from './features/auth/reset-password'
import { DashboardPage } from './features/dashboard'
import { OrdersPage } from './features/orders'
import NoneProtectedRoute from './route/NoneProtectedRoute'
import ProtectedRoute from './route/ProtectedRoute'

import { SingleCuratedListPage } from './features/lists/curated/SingleCuratedList'
import Settings from './features/settings'
import Vendors from './features/vendors'
import Transactions from './features/transactions/TransactionsPage'
import OrderDetailsPage from './features/orders/OrderDetailsPage'
import WaitListPage from './features/waitlist'

import { CuratedListsPage } from './features/lists/curated/CuratedListsPage'
import { SingleListArticle } from './features/lists/lists-articles/SingleListArticle'
import CreateList from './features/lists/curated/CreateList'
import { ListArticles } from './features/lists/lists-articles/ListArticles'
import ProfilerWrapper from './components/profiler/ProfilerWrapper'
import { DiscoverPage } from './features/discover'
import { Products } from './features/products'
import { ConfigureLists } from './features/lists/curated/ConfigureList'
import { CreateProductPage } from './features/products/CreateProduct'
import { DiscountPage } from './features/discounts'
import { SettlementPage } from './features/settlement/settlementPage'
import { SingleSettlementPage } from './features/settlement/singleSettlementPage'
import { CustomersPage } from './features/customers/customersPage'
import { SingleCustomerPage } from './features/customers/singleCustomerPage'
import ErrorBoundary from './components/errorBoundary/errorBoundary'
import { SingleVendorPage } from './features/vendors/singleVendorPage'
import { FulfilmentCenterPage } from './features/fulfilmentCenter'

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route
              path="/orders/:harmonizedReference"
              element={<OrderDetailsPage />}
            />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/vendors/:id" element={<SingleVendorPage />} />
            <Route
              path="/fulfilment-center"
              element={<FulfilmentCenterPage />}
            />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/waitlist" element={<WaitListPage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/new" element={<CreateProductPage />} />
            <Route path="/products/view/:id" element={<CreateProductPage />} />

            <Route path="/lists/articles" element={<ListArticles />} />
            <Route
              path="/lists/articles/view/:id"
              element={<SingleListArticle mode="edit" />}
            />
            <Route
              path="/lists/articles/create"
              element={<SingleListArticle mode="create" />}
            />
            <Route path="/lists/curated" element={<CuratedListsPage />} />
            <Route path="/lists/curated/new" element={<ConfigureLists />} />
            <Route
              path="/lists/curated/edit/:id"
              element={<ConfigureLists />}
            />
            <Route
              path="/lists/curated/view/:id"
              element={<SingleCuratedListPage />}
            />
            <Route path="/discover" element={<DiscoverPage />} />
            <Route path="/discounts" element={<DiscountPage />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settlement" element={<SettlementPage />} />
            <Route path="/settlement/:id" element={<SingleSettlementPage />} />
            <Route path="/customers" element={<CustomersPage />} />
            <Route path="/customers/:id" element={<SingleCustomerPage />} />
          </Route>

          <Route element={<NoneProtectedRoute />}>
            <Route path="/auth/login" element={<LoginPage />} />
            <Route
              path="/auth/forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="/auth/reset-password"
              element={<ResetPasswordPage />}
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  )
}

export default App
