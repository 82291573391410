import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import { CreateCategoryPayload } from '../../../redux-toolkits/products/products.type'
import {
  AddSubVariantLabelSchema,
  CreateCategorySchema,
  CreateVariantSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateVariantMutation,
  useEditProductCategoryMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { Product, SingleVariant } from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'

export const AddSubVariantLabelModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (label: string) => void
  product?: Product
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (label) => {},
  product,
}) => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    validationSchema: AddSubVariantLabelSchema,
    onSubmit: (values, { resetForm }) => {
      onSuccessCallback(values.name)
      resetForm()
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Add label'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          name="name"
          placeholder="Label"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.name && touched.name ? errors.name : ''}
          hasIcon={false}
          className="!border-divider-gray"
        />

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
        />
      </form>
    </ModalComponent>
  )
}
