import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../../layout'
import {
  useGetOrderQuery,
  useGetOrderSettlementsQuery,
  useRequestOrderDeliveryMutation,
  useUpdateOrderMutation,
} from '../../redux-toolkits/orders/orders.slice'
import { Link, Navigate, useParams } from 'react-router-dom'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import { formatCurrency, getVendorName } from '../../helpers'
import { format, formatDate } from 'date-fns'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import {
  DISABLE__DELIVERY_REQUEST__FROM,
  ORDERSTATUS,
  partyMappings,
  SETTLEMENTSTATUS,
} from '../../constants'
import Icon from '../../components/icon'
import Dropdown from '../../components/inputs/dropdown'
import { StatusTypes } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import { TableComponent } from '../../components/table'
import { Button } from '../../components/button'

// interface OrderDetailsPageProps {}

const OrderDetailsPage: React.FC = () => {
  const { id } = useParams()

  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation()

  const handleChangeStatus = async (
    id: string,
    status: string,
  ): Promise<void> => {
    try {
      await updateOrder({ id: id, body: { status: status } })
    } catch (error) {
      console.log(error)
    }
  }

  const [requestDelivery, { isLoading: requestingDelivery }] =
    useRequestOrderDeliveryMutation()

  if (!id) return <Navigate to="/dashboard" replace />

  const {
    data: payload,
    isLoading,
    isError,
    isSuccess,
  } = useGetOrderQuery({ id })

  const { data: settlements, isLoading: fetchingSettlements } =
    useGetOrderSettlementsQuery({ id })

  return (
    <Layout>
      {isLoading && <Spinner fullScreen={false} />}

      {isSuccess && !isLoading && (
        <div className="flex flex-col mb-20">
          <div className="flex items-center justify-between gap-4">
            <PageHeader
              title={`Order #${payload?.data?.order.orderNumber ?? ''}`}
              className="!mb-0"
            />

            <div className="flex items-center gap-4">
              <Button
                type="button"
                label="Request delivery"
                onClick={async () => {
                  await requestDelivery({ id })
                }}
                loading={requestingDelivery}
                disabled={
                  requestingDelivery ||
                  (ORDERSTATUS.find(
                    (stat) =>
                      stat.name.toLowerCase() ===
                      payload.data.order.status.toLowerCase(),
                  )?.id ?? Infinity) >= DISABLE__DELIVERY_REQUEST__FROM
                }
                primary
                size="medium"
                iconSize={20}
              />

              <Dropdown
                menuButton={
                  <div className="p-3 rounded-[8px] border border-black">
                    {updatingOrder ? (
                      <LoadingOval
                        color="black"
                        loaderHeight="18"
                        loaderWidth="18"
                      />
                    ) : (
                      <Icon id="ellipses" height={18} width={18} className="" />
                    )}
                  </div>
                }
                onClickMenuItem={() => {}}
                menuItems={[
                  ...ORDERSTATUS.map((status) => {
                    return {
                      name: (
                        <button
                          type="button"
                          onClick={() => {
                            handleChangeStatus(id, status.name)
                          }}
                          className="text-left"
                        >
                          Set as {status.name}
                        </button>
                      ),
                      value: '',
                    }
                  }),
                ]}
                menuItemClassName="border-b-0"
                menuClassName="!w-[300px]"
              />
            </div>
          </div>

          <div className="mt-2 flex items-center gap-2 text-sec-black">
            <span className="text-xl font-medium">
              {formatCurrency(payload?.data?.order.amount ?? 0)}
            </span>
            <Icon id="dot-icon" width={6} height={6} />
            <Status
              text={payload?.data?.order.paymentStatus.toLowerCase() ?? ''}
              type={
                (payload?.data?.order.paymentStatus.toLowerCase() === 'paid'
                  ? 'success'
                  : 'warn') as StatusTypes
              }
              className="!text-sm"
            />
          </div>

          <div className="mt-4 text-sec-black">
            Ordered by{' '}
            {`${payload?.data?.order?.personalInformation?.firstName ?? ''} ${
              payload?.data?.order?.personalInformation?.lastName ?? ''
            }`}
          </div>

          <div className="mt-1 flex items-center gap-2 text-sec-black">
            <span className="">
              {formatCurrency(payload?.data?.order.amount ?? 0)}
            </span>
            <Icon id="dot-icon" width={6} height={6} />
            <Status
              text={payload?.data?.order.status.toLowerCase() ?? ''}
              type={
                (ORDERSTATUS.find(
                  (status) =>
                    status.name.toLowerCase() ===
                    (payload?.data?.order.status ?? '').toLowerCase(),
                )?.type ?? 'warn') as StatusTypes
              }
              className="!text-sm"
            />
          </div>

          <div className="my-10">
            <p className="text-xl mb-6 font-medium">Order Details</p>

            {payload.data.orderItems.map((item, idx) => {
              const key = `item-${item._id}`

              return (
                <div
                  className="
                  flex flex-wrap
                  gap-6
                  w-full max-w-[800px]
                  py-6 first:pt-0 last:pb-0
                  border-b last:border-b-0
                  "
                  key={key}
                >
                  <div className="min-w-fit">
                    <img
                      src={item.product.images[0] ?? ''}
                      className="w-[140px] h-[140px] object-cover rounded-md"
                    />
                  </div>

                  <div className="flex-1 text-base text-sec-black">
                    <div className="flex items-center gap-4 justify-between">
                      <span className="text-black font-medium">
                        {item.product.name}
                      </span>
                      <span>Quantity: {item.quantity}</span>
                    </div>

                    <p className="mt-2">{formatCurrency(item.product.price)}</p>

                    {item.variants.length > 0 &&
                      item.variants.map((variant, idx) => {
                        const key = `${idx}-variant`
                        return (
                          <div className="text-sm" key={key}>
                            <div className="text-black mt-4 mb-1">
                              {variant.variantId.name}
                            </div>
                            <div className="flex flex-col gap-1">
                              <div
                                key={key}
                                className="flex items-center gap-4 justify-between"
                              >
                                <span>{variant.value}</span>
                                <span>
                                  {formatCurrency(variant.price ?? 0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}

                    {item.comboItems.length > 0 && (
                      <div className="text-sm">
                        <div className="text-black mt-4 mb-1">
                          Extra options:
                        </div>
                        <div className="flex flex-col gap-1">
                          {item.comboItems.map((comboItem, idx) => {
                            const key = `${idx}-combo-item`

                            return (
                              <div
                                key={key}
                                className="flex items-center gap-4 justify-between"
                              >
                                <span>
                                  {comboItem.name} (Qty: {comboItem.quantity})
                                </span>
                                <span>
                                  {formatCurrency(comboItem.price ?? 0)}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          <TableComponent
            name="Settlements"
            showName
            headers={[
              'Transaction Id',
              'Order Id',
              'Party',
              'Amount Settled',
              'Status',
              'Date',
            ]}
            rows={
              settlements
                ? settlements.data.map((item) => {
                    return {
                      id: item._id,
                      content: [
                        item._id,
                        <Link
                          to={`/orders/${item.order._id}`}
                          className="
                          hover:text-black hover:underline
                          transition-all duration-500
                          "
                          key={`${item.order._id}-product-id`}
                        >
                          #{item.order.orderNumber}
                        </Link>,
                        item.party === 'VENDOR' ? (
                          <div
                            key={`${item._id}-vendor-party`}
                            className="flex gap-2 items-center"
                          >
                            <img
                              src={item.vendor.logo ?? ''}
                              className="object-cover w-[40px] h-[40px] rounded-full overflow-hidden"
                            />
                            <span>{getVendorName(item.vendor)}</span>
                          </div>
                        ) : (
                          (partyMappings[
                            item.party as keyof typeof partyMappings
                          ] as string)
                        ),
                        formatCurrency(item.amount),
                        <Status
                          text={item.status.toLowerCase()}
                          type={
                            (SETTLEMENTSTATUS.find(
                              (status) =>
                                status.name.toLowerCase() ===
                                item.status.toLowerCase(),
                            )?.type ?? 'warn') as StatusTypes
                          }
                          key={`${item._id}-status`}
                        />,
                        `${format(item.createdAt, 'yyyy-MM-dd')}`,
                      ],
                    }
                  })
                : []
            }
            loading={fetchingSettlements}
          />
        </div>
      )}
    </Layout>
  )
}

export default OrderDetailsPage
