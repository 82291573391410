import React, { ChangeEvent, useState } from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddProductVariantSchema,
  CreateCategorySchema,
  CreateTagSchema,
  EditVariationCombinationSchema,
} from '../../../validation/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import {
  ComboItem,
  CreatedProductVariant,
  ProductVariant,
  SingleVariant,
  VariantCombination,
} from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'
import { removeElementAtIndex } from '../../../helpers'
import { ImageBanner } from '../../images/imageBanner'
import { ImageUploadButton } from '../../button/imageUploadButton'
import { useModalControl } from '../../../hooks/useModalControl'
import { ImageUploadPreviewModal } from './ImagePreviewModal'

export const EditVariantionCombinationModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: VariantCombination) => void
  variantCombination?: VariantCombination
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  variantCombination,
}) => {
  const [uploadedImage, setUploadedImage] = useState<File>()

  const { handleModalOpen, modal, modalOpen, setModalOpen } = useModalControl()

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    bannerOrIcon = 'banner',
  ) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      price: variantCombination?.price,
      inventoryCount: variantCombination?.inventoryCount,
      images: variantCombination?.images,
      isActive: variantCombination?.isActive,
    },
    enableReinitialize: true,
    validationSchema: EditVariationCombinationSchema,
    onSubmit: (values, { resetForm }) => {
      if (variantCombination) {
        const itemToAdd = {
          ...variantCombination,
          ...values,
        } as VariantCombination

        onSuccessCallback(itemToAdd)
        resetForm()
      }
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Modify Variant Combination'}
    >
      <div className="flex flex-col gap-4">
        <TextInput
          type="text"
          name={`price`}
          placeholder="Price"
          title="Price"
          value={String(values.price)}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={errors.price && touched.price ? errors.price : ''}
          hasIcon={false}
          className=""
        />

        <TextInput
          type="text"
          name={`inventoryCount`}
          placeholder="Quantity in stock"
          title="Quantity in stock"
          value={String(values.inventoryCount)}
          onBlur={handleBlur}
          onChange={handleChange}
          errors={
            errors.inventoryCount && touched.inventoryCount
              ? errors.inventoryCount
              : ''
          }
          hasIcon={false}
          className=""
        />

        <div className="relative max-w-fit flex items-center flex-wrap gap-4">
          {values.images?.map((url, idx) => {
            const key = `${idx}`

            return (
              <ImageBanner
                imageUrl={url}
                loading={false}
                className="
                !w-[150px] !max-h-[150px]
                "
                errors={errors.images}
                key={key}
                onDelete={() => {
                  const imagesCopy = JSON.parse(JSON.stringify(values.images))

                  setFieldValue('images', removeElementAtIndex(imagesCopy, idx))
                }}
              />
            )
          })}
        </div>

        <ImageUploadButton
          handleChange={(e) => {
            handleFileChange(e, 'icon')
          }}
          showText
          text="Upload item image"
          iconDim={64}
          icon={'pic-icon'}
          className="
            !relative !w-full
            !m-0 !top-0 !right-0
            !h-[126px]
            !rounded-lg
            "
        />

        <Button
          label="Proceed"
          type="button"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
          onClick={() => {
            handleSubmit()
          }}
        />

        {uploadedImage && (
          <ImageUploadPreviewModal
            image={uploadedImage}
            open={modal === 'imageUploadPreviewModal' && modalOpen}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              setUploadedImage(undefined)
            }}
            onSuccessCallback={(url) => {
              setFieldValue('images', [...(values.images ?? []), url])
            }}
          />
        )}
      </div>
    </ModalComponent>
  )
}
